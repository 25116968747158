import { gql } from '@apollo/client'

import { CUSTOMER_BOOK_ENTRY_FRAGMENT } from './'

export const CUSTOMER_BOOK_ENTRIES = gql`
  query customerBookEntries($customer: ID){
     customerBookEntries(customer: $customer) {
       ...CUSTOMER_BOOK_ENTRY_FRAGMENT
     }
  }
  ${CUSTOMER_BOOK_ENTRY_FRAGMENT}
`

import moment from 'moment'

import { useIntl } from '../../../hooks'
import { formatPrice } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function PurchaseRefunds({purchase: {refunds}}) {

  const { getTranslation } = useIntl()

  return(
    <div className="purchase-refunds">
      <table className="table">
        <thead>
          <tr>
            <th><IntlText group="purchase-refunds" id="th-date"/></th>
            <th><IntlText group="purchase-refunds" id="th-type"/></th>
            <th className="price-field"><IntlText group="purchase-refunds" id="th-amount"/></th>
            <th><IntlText group="purchase-refunds" id="th-status"/></th>
          </tr>
        </thead>
        <tbody>
          {refunds?.map((item, key) =>
            <tr className="item" key={key}>
              <td data-label={getTranslation({group: "purchase-refunds", id: "th-date"})}>{moment(item?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
              <td data-label={getTranslation({group: "purchase-refunds", id: "th-type"})} className="description-field">
                <div>
                  <span>{item?.note}</span>
                </div>
              </td>
              <td data-label={getTranslation({group: "purchase-refunds", id: "th-amount"})} className="price-field">
                <span>{formatPrice(item?.value)}</span>
              </td>
              <td><span style={{color: item?.status === "REFUNDED" ? "var(--valid-color)" : "var(--invalid-color)"}}>{getTranslation({group: "purchase-refunds", id: item?.status})}</span></td>
            </tr>
          )}
        </tbody>
      </table>      
    </div>
  )
}

import { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { SETTING_QUERY } from '../../graphql'
import { SlideInContext } from '../../contexts'
import { useAuth } from '../../hooks'
import { AuthKit } from '../../helpers'
import { SlideIn, Navigation, NavItem, NavButton, IntlText, Cart } from '../../components'

import './style.css'

export function RightMenu() {

  const { user } = useAuth()
  const { closeMenu } = useContext(SlideInContext)
  const { loading, error, data } = useQuery(SETTING_QUERY, {variables: {key: "TOMATO_SAVE_N_SHARE"}})

  if (loading || error) return null

  function logout(){
    AuthKit.logout()
    closeMenu("right")
  }

  return(
    <>
      <SlideIn id="cart" side="right">
        <Cart />
      </SlideIn>    
      <SlideIn id="right" side="right">
        {!!user &&
          <Navigation id="right-menu">
            <NavItem target="/account/orders" icon="shopping-cart" info={<IntlText group="right-menu" id="orders-desc" />}><IntlText group="right-menu" id="orders" /></NavItem>
            {data?.setting?.value === "true" &&
              <NavItem target="/account/referrals" icon="star" info={<IntlText group="right-menu" id="referrals-desc" />}><IntlText group="right-menu" id="referrals" /></NavItem>
            }
            <NavItem target="/account" icon="user" info={<IntlText group="right-menu" id="profile-desc" />}><IntlText group="right-menu" id="profile" /></NavItem>
            <NavItem target="/account/balance" icon="money-bill" info={<IntlText group="right-menu" id="balance-desc" />}><IntlText group="right-menu" id="balance" /></NavItem>
            <NavItem target="/account/addresses" icon="map-marker" info={<IntlText group="right-menu" id="addresses-desc" />}><IntlText group="right-menu" id="addresses" /></NavItem>
            <NavItem target="/account/payment-methods" icon="credit-card" info={<IntlText group="right-menu" id="payment-methods-desc" />}><IntlText group="right-menu" id="payment-methods" /></NavItem>
            <NavItem target="/account/change-password" icon="key"><IntlText group="right-menu" id="change-password" /></NavItem>
            <NavButton icon="sign-out-alt" onClick={logout}><IntlText group="right-menu" id="logout" /></NavButton>
          </Navigation>
        }
      </SlideIn>
    </>
  )
}

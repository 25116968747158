import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './vendor/$vendor'
import { PAGE_FRAGMENT } from './fragments'

export const PAGE = gql`
  query page($slug:String){
    vendor(slug: $slug){
      ...VENDOR_FRAGMENT
    }

    page(slug: $slug){
      ...PAGE_FRAGMENT
    }    
  }
  ${VENDOR_FRAGMENT}
  ${PAGE_FRAGMENT}
`

import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from '../fragments'

const SETTING_QUERY = gql`
  query setting($key: String!){
    setting(key: $key){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`

export default SETTING_QUERY

import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Subscriptions } from './subscriptions'
import history from './history'
import Routes from './routes'


import { useAuth } from './hooks'
import { ScrollToTop, AlertBar, Wrapper } from './components'
import { Header, LeftMenu, RightMenu, Footer, InfoModal, AppDownload } from './views'

export default function AppRouter() {

  const { user } = useAuth()

  return (
    <BrowserRouter history={history}>
      <Subscriptions />
      <div id="modal-portal" />
      <AlertBar />
      <AppDownload />
      <ScrollToTop>
        <Wrapper>
          <div id="app">
            <LeftMenu />
            <RightMenu /> 
            <div id="overlay" />
            <Header />
            <div id="main" className={`${!!user ? "authenticated" : ""}`}>
              <Routes />
              <Footer />
            </div>
            <InfoModal />
            <ToastContainer position="bottom-right" />
          </div>
        </Wrapper>
      </ScrollToTop>
    </BrowserRouter>
  )
}


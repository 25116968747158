import { toast } from 'react-toastify'

import history from '../history'
import client from '../client'
import config from '../config'
import { isFunction } from '../helpers'
import { AuthKit } from './'
import { FACEBOOK_LOGIN_MUTATION, GET_FACEBOOK_DATA, IS_LOGGED_IN } from '../graphql'

export default class FBKit {

  static loadSDK(){
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'))
  }

  static loadAPP(){
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : config.facebookAPPID,
        cookie     : true,
        xfbml      : true,
        version: 'v9.0'
      })

      window.FB.AppEvents.logPageView()
      // window.FB.getLoginStatus((response) => FBKit.status(response))
    }
  }

  static register(){
    window.FB.login((response) => {
      if (response.status === 'connected') {
        // Logged into your app and Facebook.
        FBKit.registerAPI()
      } else if (response.status === 'not_authorized') {
        // The person is logged into Facebook, but not your app.
        console.log("facebook-error", response.status)
      } else {
        // The person is not logged into Facebook, so we're not sure if
        // they are logged into this app or not.
        console.log("facebook-error", response.status)
      }
    }, {scope: 'email', return_scopes: true})
  }

  static login(cb){
    window.FB.login((response) => FBKit.status(response, cb), {scope: 'email', return_scopes: true})
  }

  static status(response, cb){
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      FBKit.loginAPI(cb)
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      console.log("facebook-error", response.status)
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      console.log("facebook-error", response.status)
    }
  }

  static loginAPI(cb) {
    window.FB.api('/me', function(response) {
      client
      .mutate({
        mutation: FACEBOOK_LOGIN_MUTATION,
        variables: {facebookID: response.id, email: response.email},
        update: FBKit.updateStore
      })
      .catch(e => {
        e.graphQLErrors.map(x => toast.error(x.message))
        client.writeQuery({ 
          query: GET_FACEBOOK_DATA,
          data: { email: response.email, name: response.name, facebookID: response.id } 
        })
        history.push("/register")
      })
      
      if (isFunction(cb)) cb()
      
    }, {fields: "name, email"})
  }
  
  static registerAPI() {
    window.FB.api('/me', function(response) {
      client
        .mutate({
          mutation: FACEBOOK_LOGIN_MUTATION,
          variables: {facebookID: response.id, email: response.email},
          update: FBKit.updateStore
        })
        .catch(e => {
          e.graphQLErrors.map(x => toast.error(x.message))
          client.writeQuery({ 
            query: GET_FACEBOOK_DATA,
            data: { email: response.email, name: response.name, facebookID: response.id } 
          })
        })
    }, {fields: "name, email"})
  }

  static updateStore(cache, { data: { facebookLogin: { token, user } } }){
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: { isLoggedIn: !!user?.id},
    })

    AuthKit.login(user, token)

  }

}

import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { SlideInProvider, CartProvider, ModalProvider, IntlProvider, AuthContextProvider } from './contexts'
import { Theme } from './components'
import { FBKit } from './helpers'
import config from './config'

import Router from './router'

const stripePromise = loadStripe(config.stripeKey)

export default function App() {

  useEffect(() => {
    FBKit.loadSDK()
    FBKit.loadAPP()
  }, [])

  return (
    <AuthContextProvider>
      <IntlProvider>
        <SlideInProvider>
          <CartProvider>
            <ModalProvider>
              <Elements stripe={stripePromise}>
                <Theme>
                  <Router />
                </Theme>
              </Elements>
            </ModalProvider>
          </CartProvider>
        </SlideInProvider>
      </IntlProvider>
    </AuthContextProvider>
  )
}

import { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { AuthContext } from '../contexts'
import { EDIT_CUSTOMER_MUTATION } from '../graphql'
import { toast } from '../helpers'
import { useIntl } from './useIntl'

export function useAuth(){
    
    const { getTranslation } = useIntl()
    const [editUser] = useMutation(EDIT_CUSTOMER_MUTATION)
    const auth = useContext(AuthContext)

    async function _editUser({data, message}){
        const alert = toast.loading()
        try {
            await editUser({variables: {id: auth?.user?.id, data}})
            alert.success(message || getTranslation({id: "edit-success"}))
        } catch (error) {
            alert.apolloError(error)
        }
    }
    
    return {...auth, editUser: _editUser}
}
import React from 'react'

import './LabeledTextarea.css'

export default function LabeledTextarea({value = undefined, name, placeholder, className, onChange, disabled}) {

    return(
      <div className={`labeled-textarea ${value !== "" && value !== undefined ? "populated" : ""}`}>
        <label>
          <textarea
            className={className}
            value={value || ""}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
          <span>{placeholder}</span>
        </label>
      </div>
    )
}

import React from 'react'
import moment from 'moment'

import { useModal } from '../../../hooks'
import { Modalv3, Title } from '../../../components'
import { PurchaseProducts, PurchaseTotals } from '../../../views'

import './style.css'

export function PurchaseRevisions({purchase}) {

  const revisionModal = useModal()

  return(
    <div id="purchase-revisions">
        <div className="purchase-revisions">
            {purchase.revisions.length > 0 &&
                <div className='list'>
                    {purchase.revisions.map((revision, key) => 
                        <div key={key} onClick={() => revisionModal.show({key, revision})}>
                            #{key} {moment(revision.createdAt).format("YYYY-MM-DD HH:mm")}
                        </div>
                    )}
                </div>
            }     
        </div>
        <Modalv3 id="purchase-revision" modal={revisionModal} header={<Title tag="h3">#{revisionModal.state?.key} {moment(revisionModal.state?.revision.createdAt).format("YYYY-MM-DD HH:mm")}</Title>}>
            <div>
                <PurchaseProducts purchase={revisionModal.state?.revision} vendor={purchase.vendor} />
                <PurchaseTotals purchase={revisionModal.state?.revision} />        
            </div>
        </Modalv3>              
    </div>
  )
}
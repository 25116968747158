import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlValue, IntlText } from '../../components'

import './CategoryNavigation.css'

export default function CategoryNavigation({navigationItems}) {

  const navigationOuter = useRef(null)
  const navigation = useRef(null)
  const moreNav = useRef(null)
  const [opened, toggleOpened] = useState(false)
  const [priorityItems, setPriorityItems] = useState(navigationItems)
  const [moreItems, setMoreItems] = useState([])

  useEffect(() => {
    const widthsArray = Array.from(navigation.current.children).map(item => item.getBoundingClientRect().width)

    function howManyItemsInMenuArray(array, outerWidth, minimumNumberInNav){
      let total = 0
      for(let i = 0; i < array.length; i++) {
        if(total + array[i] > (outerWidth - 120)) {
          return i < minimumNumberInNav ? minimumNumberInNav : i
        } else {
          total += array[i]
        }
      }
    }

    function handleClick(e){
      if (navigationOuter.current?.contains(e.target)) return false

      toggleOpened(false)

    }

    function updateNavigation(){
      if (!navigationOuter.current) return false

      let outerWidth = navigationOuter.current.getBoundingClientRect().width
      let arrayAmount = howManyItemsInMenuArray(widthsArray, outerWidth, 1)
      const navItemsCopy = navigationItems

      if (navItemsCopy.length - arrayAmount === 0) arrayAmount = navItemsCopy.length

      let _priorityItems = navItemsCopy.slice(0, arrayAmount)
      let _moreItems = _priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : []

      setPriorityItems(_priorityItems)
      setMoreItems(_moreItems)
   }

    window.addEventListener('resize', updateNavigation, 100)
    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    updateNavigation()

    return () => {
      window.removeEventListener('resize', updateNavigation)
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [navigationItems])

  function onClickItem(slug) {
    toggleOpened(false)
    window.scrollTo({ top: document.getElementById("category-" + slug).offsetTop - 160, left: 0, behavior: 'smooth'})
  }

  return(
    <div id="category-menu">
      <nav ref={navigationOuter} className="navigation" role="navigation">
        <ul ref={navigation} className="navigation-list">
          {priorityItems.map((category, i) =>
            <li key={`navItem-${i}`} className="navigation-item" onClick={() => onClickItem(category.category.slug)}>
              <span className="navigation-link"><IntlValue value={category.category.name} /></span>
            </li>)
          }
          {moreItems.length > 0 &&
            <li className="navigation-item more-item" onMouseOver={(e) => toggleOpened(true)} onMouseOut={(e) => toggleOpened(false)}>
              <span className="navigation-link" onClick={(e) => toggleOpened(true)} ><IntlText id="more">More</IntlText> <FontAwesome name="chevron-down" /></span>
              <ul ref={moreNav} className={`more-navigation ${opened ? "opened" : ""}`}>
                {moreItems.map((category, i) =>
                  <li key={`moreNavItem-${i}`} className="navigation-item" onClick={() => onClickItem(category.category.slug)}>
                    <span className="navigation-link" ><IntlValue value={category.category.name} /></span>
                  </li>
                )}
              </ul>
            </li>
          }
        </ul>
      </nav>
    </div>
  )
}

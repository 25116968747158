import { gql } from '@apollo/client'

export const PRODUCTS_FRAGMENT = gql`
  fragment PRODUCTS_FRAGMENT on Product {
    id
    rank
    slug
    media{
      cover
      thumbnail
    }
    tags
    name{
      en
      es
    }
    description{
      en
      es
    }
    price
    discount
    discountedPrice
    freeDeliveryAvailable
    freeDeliveryFixRate
    disabled
    hidden
  }
`

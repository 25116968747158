import React, { useContext } from 'react'

import { IntlContext } from '../../contexts'
import { usePlatformDetect } from '../../hooks'
import { getAsset } from '../../helpers'
import { Title, IntlText, IconBox, IntlValue, Text, Button, IntlBlock } from '../../components'
import { MoreInfo } from '../../views'

export function BecomeRider({page}) {

  const platform = usePlatformDetect()
  const { getTranslatedValue, getTranslation } = useContext(IntlContext)
  const text = getTranslatedValue(page?.content) || ""
  const icons = getAsset("icons.json")

  return(
    <div id="ride-with-us" className={`page ${page.template}`}>
      <div className='row-headline'>
        <div className="row wrapped">
          <div className="row-header">
            <div className='text'>
              <Title><IntlValue value={page.title} /></Title>
              <div className="info"><IntlValue value={page.description} /></div>
            </div>
            <div className='icon'>
              <img src={icons.becomeARider} alt={getTranslatedValue(page.description)} />
            </div>
          </div>             
        </div>      
      </div>
      <div className="row wrapped xl">
        <div className="row-header">
          <Title tag="h2"><IntlText group="ride-with-us" id="what-we-offer" /></Title>
        </div>          
        <IconBox.Box>
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "flexibility"})} image={icons.flexibility} description={getTranslation({group: "ride-with-us", id: "flexibility-desc"})} />
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "salary"})} image={icons.earnings} description={getTranslation({group: "ride-with-us", id: "salary-desc"})} />
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "team"})} image={icons.bePartOfOurTeam} description={getTranslation({group: "ride-with-us", id: "team-desc"})} />
        </IconBox.Box>
      </div>
      <div className="row wrapped xl">
        <IntlBlock language="es">
          <div className='text'>
            <h3>{getTranslation({group: "ride-with-us", id: "what-do-you-need"})}</h3>
            <ol>
              <li>Dispositivo Android o Apple</li>
              <li><div style={{display: "flex", alignItems: "center", gap: 15}}>Descargar la aplicación SoyTomato - Sé un repartidor <Button theme="main" onClick={() => window.open(platform === "ios" ? `https://apps.apple.com/us/app/soytomato/id${process.env.REACT_APP_RIDER_APPSTORE_ID}` : `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_RIDER_PLAYSTORE_ID}&hl=es`)}>Descargar ahora</Button></div></li>
              <li>Motor o auto</li>
              <li>Identificación oficial (INE o IFE)</li>
              <li>Cuenta bancaria al nombre del socio repartidor</li>
              <li>RFC</li>
              <li>Tener más de 21 años</li>
            </ol>
          </div>
        </IntlBlock>
        <IntlBlock language="en">
          <div className='text'>
            <h3>{getTranslation({group: "ride-with-us", id: "what-do-you-need"})}</h3>
            <ol>
              <li>Android or Apple mobil device</li>
              <li><div style={{display: "flex", alignItems: "center", gap: 15, flexWrap: 'wrap'}}>Download the app SoyTomato - Sé un repartidor <Button theme="main" onClick={() => window.open(platform === "ios" ? `https://apps.apple.com/us/app/soytomato/id${process.env.REACT_APP_RIDER_APPSTORE_ID}` : `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_RIDER_PLAYSTORE_ID}&hl=es`)}>Download now</Button></div></li>
              <li>Motor or auto</li>
              <li>Official identification (INE o IFE)</li>
              <li>Bank account</li>
              <li>RFC</li>
              <li>Be at least 21 years old</li>
            </ol>
          </div>      
        </IntlBlock>
        <Text>{text}</Text>     
      </div>
      <div className="row wrapped xl">
        <div className="row-header">
          <Title tag="h2"><IntlText group="ride-with-us" id="what-we-expect" /></Title>
        </div>        
        <IconBox.Box>
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "communication-skills"})} image={icons.communication} description={getTranslation({group: "ride-with-us", id: "communication-skills-desc"})} />
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "accuracy-speed"})} image={icons.accuracy} description={getTranslation({group: "ride-with-us", id: "accuracy-speed-desc"})} />
          <IconBox.Item title={getTranslation({group: "ride-with-us", id: "reliability"})} image={icons.reliability} description={getTranslation({group: "ride-with-us", id: "reliability-desc"})} />
        </IconBox.Box>
      </div>
      <MoreInfo />
    </div>
  )
}

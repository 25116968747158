import React from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlText } from '../../../components'

import './style.css'

export function HowItWorks() {
  return(
    <div id="how-it-works" className="row wrapped">
      <div><h2 className="title"><IntlText group="how-it-works" id="title" /></h2></div>
      <div id="timeline">
  			<div className="timeline-item">
  				<div className="timeline-icon">
            <FontAwesome name="users" />
  				</div>
  				<div className="timeline-content">
  					<h2><IntlText group="how-it-works" id="login-register" /></h2>
            <div className="info">
              <p><IntlText group="how-it-works" id="login-register-desc" /></p>
            </div>
  				</div>
  			</div>

  			<div className="timeline-item">
  				<div className="timeline-icon">
            <FontAwesome name="cart-plus" />
  				</div>
  				<div className="timeline-content">
  					<h2><IntlText group="how-it-works" id="add-products" /></h2>
            <div className="info">
              <p><IntlText group="how-it-works" id="add-products-desc" /></p>
            </div>
  				</div>
  			</div>

  			<div className="timeline-item">
  				<div className="timeline-icon">
            <FontAwesome name="map-marker-alt" />
  				</div>
  				<div className="timeline-content">
  					<h2><IntlText group="how-it-works" id="add-location" /></h2>
            <div className="info">
              <p><IntlText group="how-it-works" id="add-location-desc" /></p>
            </div>
  				</div>
  			</div>

        <div className="timeline-item">
  				<div className="timeline-icon">
            <FontAwesome name="money-bill-alt" />
  				</div>
  				<div className="timeline-content">
  					<h2><IntlText group="how-it-works" id="place-order" /></h2>
            <div className="info">
              <p><IntlText group="how-it-works" id="place-order-desc" /></p>
            </div>
  				</div>
  			</div>

        <div className="timeline-item">
  				<div className="timeline-icon">
            <FontAwesome name="motorcycle" />
  				</div>
  				<div className="timeline-content">
  					<h2><IntlText group="how-it-works" id="receive-order" /></h2>
            <div className="info">
              <p><IntlText group="how-it-works" id="receive-order-desc" /></p>
            </div>
  				</div>
  			</div>

  		</div>
    </div>
  )
}

import React, { useState, useRef } from 'react'
import { node } from 'prop-types'

const ModalContext = React.createContext()
const ModalConsumer = ModalContext.Consumer

function ModalProvider({children}) {

  const [modal, toggleModal] = useState(null)
  const [attributes, setAttributes] = useState({})
  const modals = {
    orderHelp: useRef(null),
    reportProblem: useRef(null),
  }


  function isOpened(name){
    return modal === name
  }

  function openModal(name, attributes = {}){
    document.body.classList.add(`modal-opened`)
    document.body.classList.add(`modal-opened-${name}`)
    toggleModal(name)
    setAttributes(attributes)
  }

  function closeModal(){
    document.body.classList.remove(`modal-opened`)
    document.body.classList.remove(`modal-opened-${modal}`)
    toggleModal(null)
    setAttributes({})
  }

  return (
    <ModalContext.Provider
      value={{
        isOpened,
        openModal,
        closeModal,
        attributes,
        modals
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: node.isRequired
}

export { ModalContext, ModalProvider, ModalConsumer }

import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { formatPrice, getImageInSize } from '../../helpers'
import { Title, IntlText, IntlValue, Ribbon } from '../../components'
import { ProductTags } from '../../views'
import { ModalContext } from '../../contexts'

export default function MenuItem({vendor, item: {id, name, price, discount, discountedPrice, freeDeliveryAvailable, description, tags, media = {}, disabled}, categoryDisabled, showVendor, category, showCategory}) {

  const { openModal } = useContext(ModalContext)

  let { cover, thumbnail } = media || {}
  let _disabled = disabled || categoryDisabled

  return(
    <div className={`menu-item ${_disabled ? "disabled" : ""} ${discount ? "on-sale" : ""} ${freeDeliveryAvailable || vendor.contract === "FREEDELIVERY" ? "free-delivery" : ""}`} onClick={!_disabled ? () => openModal("productModal", {id, vendor, showVendor, category, showCategory}) : null}>
      {(freeDeliveryAvailable || vendor.contract === "FREEDELIVERY") && <Ribbon><IntlText group="menu-item" id="free-delivery" /></Ribbon>}
      {thumbnail && !cover && <div className="product-thumb" style={{backgroundImage: `url(${getImageInSize(thumbnail, "150", "webp")})`}}></div>}
      <div className="menu-item-info">
        {cover && <div className="product-cover" style={{backgroundImage: `url(${cover})`}}><ProductTags tags={tags} /></div>}
        <div className='menu-item-content'>
          <div className="menu-item-title">
            <Title tag="h3"><IntlValue value={name} /></Title>
            <div className="price-box">
              {discount && discountedPrice && <div className="discounted-price"><span>{formatPrice(discountedPrice)}</span></div>}
              <div className="price"><span>{formatPrice(price)}</span></div>
            </div>
          </div>
          <div style={{display: "flex", gap: 10}}>
            {showVendor && <div className="vendor-name"><FontAwesome name="hat-chef" />{vendor.name}</div>}
            {showCategory && <div className="vendor-name"><FontAwesome name="tag" /><IntlValue value={category.name} /></div>}
          </div>
          <div className="desc"><IntlValue value={description} /></div>
          <div className="tags">
            {!(cover && !thumbnail) && <ProductTags tags={tags} discount={discount}/>}
          </div>
          {_disabled &&
            <div className="availability">
              <FontAwesome name="exclamation-triangle" /><IntlText group="menu-item" id="not-available" />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Loader } from '../'

import './style.css'

export function Button({className, theme = "", loaderTheme, disabled, icon, onClick, loading, children, fluid, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <button disabled={disabled || loading ? "disabled" : ""} className={`button ${className || ""} ${theme || ""} ${fluid ? "full" : ""}`} onClick={!disabled ? onClick : null} type="button" {...rest}>
      {loading && <Loader theme={loaderTheme} />}
      {icon && showIcon()}
      {children && <span>{children}</span>}
    </button>
  )

}

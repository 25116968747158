import React from 'react'
import { Link } from 'react-router-dom'

import { getImageInSize } from '../../../helpers'
import { Title, IntlValue } from '../../../components'
import { VendorTags, DeliveryFee, VendorRating, OpeningHours } from '../../../views'

import './style.css'

export function FeaturedCard({vendor}) {

  return(
    <div>
      <Link to={`/${vendor.slug}`}>
        <div className="featured-card">
          <div className="card-header">
            <div className="thumb" style={{backgroundImage: `url(${getImageInSize(vendor.cover, "900", "webp")})`}}></div>
            <div className="partner-logo-wrapper">
              <div className="partner-logo">
                <picture>
                  <source srcSet={getImageInSize(vendor.logo, "150", "webp")} type="image/webp" />
                  <source srcSet={getImageInSize(vendor.logo, "150", "png")} type="image/png" />
                  <img src={vendor.logo} alt={vendor.name} title={vendor.name} />
                </picture>     
              </div>
            </div>
          </div>
          <div className="card-content">
            <Title tag="h3">
              {vendor.name}
              <VendorTags style={{marginLeft: "auto"}} vendor={vendor} />
            </Title>
            <div className="desc">{vendor.intro && <IntlValue>{vendor.intro}</IntlValue>}</div>
          </div>
          <div className="card-footer">
            <DeliveryFee vendor={vendor}/>
            <VendorRating vendor={vendor}/>
            <OpeningHours vendor={vendor}/>
          </div>
        </div>
      </Link>
    </div>
  )
}

import { MenuCategory } from './'
import { IntlText, Link } from '../../components'
import { ProductModal, ProductSearch } from '../../views'

import './style.css'

export default function Menu({vendor, categories, query, query: {isQuery}, children = null}) {
  return(
    <div id="partner-menu">
      {children}
      {isQuery() ? 
        <ProductSearch {...query} defaults={{vendor: vendor?.id}} withoutModal showVendor={false} />
      : categories && categories.length > 0 ? categories.map((category, key) =>
        <MenuCategory key={key} category={category} vendor={vendor}/>
      ) :
        <div className="empty-menu">
          <IntlText group="menu" id="empty-text"  />
          <Link to="/delivery-service" className="main" button><IntlText group="menu" id="empty-button" /></Link>
        </div>
      }
      <div id="attention">
        <p><IntlText group="menu" id="attention" /></p>
      </div>
      <ProductModal />
    </div>
  )
}

import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { CartContext } from '../../contexts'
import { useModal, useAuth } from '../../hooks'
import { getImageInSize } from '../../helpers'
import { Button, IntlText, Link, Title, IntlValue } from '../'
import { CartItem, CartTotals } from './'
import { SelectLocationModal } from '../../views'

import './style.css'

export default function Cart() {

  const { user } = useAuth()
  const { state: { vendor, items, deliveryLocation }, emptyCart, removeItem, increaseQty, decreaseQty } = useContext(CartContext)
  const modal = useModal()

  return(
    <div id="cart-wrapper">
      <div id="cart">
        {items?.length > 0 ?
          <div id="cart-inner">
            <Link to={vendor.slug} plain>
              <div className="selected-partner">
                <div className="partner-logo">
                  <div>
                    <picture>
                      <source srcSet={getImageInSize(vendor.logo, "150", "webp")} type="image/webp" />
                      <source srcSet={getImageInSize(vendor.logo, "150", "png")} type="image/png" />
                      <img src={vendor.logo} alt={vendor.name} title={vendor.name} />
                    </picture>                       
                  </div>
                </div>
                <div className="info">
                  <Title tag="h2">{vendor.name}</Title>
                  <span className="desc"><IntlValue value={vendor.intro} /></span>
                </div>
              </div>            
            </Link>
            {!!user && deliveryLocation ?
              <Link to="/checkout" button className="main"><IntlText group="cart" id="go-to-checkout" /></Link>
              : !!user ? 
              <Button theme="main" fluid onClick={() => modal.show()}><IntlText group="cart" id="select-location" /></Button>
              :
              <Link to="login" button className="main"><IntlText group="cart" id="login-to-continue" /></Link>
            }
            <CartTotals />
            <div id="cart-items">
              {items.map((item, key) =>
                <CartItem key={key} item={item} remove={() => removeItem(key)} increase={() => increaseQty(key)} decrease={() => decreaseQty(key)} />
              )}
            </div>
            <Button fluid id="clear-cart" className="small" onClick={() => emptyCart()}><IntlText group="cart" id="clear-cart" /></Button>
          </div>
        :
          <div id="cart-inner">
              <div className='empty-cart'>
                <FontAwesome name='cart-plus' />
                <span><IntlText group="cart" id="no-products" /></span>
                <Link to="/delivery-service" button className="main"><IntlText group="cart" id="start-buying" /></Link>
              </div>
          </div>
        }
      </div>
      <SelectLocationModal modal={modal} />    
    </div>
  )
}

import React from 'react'
import { useQuery } from '@apollo/client'

import { Loader } from '../../../components'
import { FaqItem } from '../../../views'
import { FAQS } from '../../../graphql'

export function GetStartedCustomer() {

  const { loading, data, error } = useQuery(FAQS, {variables: {topic: "CUSTOMER_GET_STARTED"}})


  if (loading) return <Loader theme="main" />
  if (error) return `Error! ${error.message}`

  const { faqItems } = data

  return(
    <div id="get-started-customer">
      {faqItems.map((faq, key) =>
        <FaqItem key={key} faq={faq} />
      )}
    </div>
  )

}

import React from 'react'

import { useAuth } from '../../hooks'
import { Title, IntlText, Loader } from '../../components'
import { ProfileForm } from '../../views'

export default function Profile() {

  const { loading, user, error } = useAuth()

  return(
    <div id="profile" className="container">
      <div className="row wrapped">
        <div className="row-header">
          <div className="heading centered">
            <Title tag="h2"><IntlText group="profile" id="title" /></Title>
            <div className="desc"><IntlText group="profile" id="desc" /></div>
          </div>
        </div>
        <div id="profile-form-wrapper" className="form-wrapper">
          {loading || error ? 
            <Loader theme="main" />
          :
            <ProfileForm customer={user} />
          }
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import './Tooltip.css'

export default function Tooltip({text, noWrap, children, className, right}) {

  return(
    <div className={`tooltip-wrapper ${noWrap ? "no-wrap" : ""} ${right ? "right" : ""} ${className || ""}`}>
      {children}
      {text && <div className="tooltip">{text}</div>}
    </div>
  )
}

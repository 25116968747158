import { useState } from 'react'

export function useValidatedForm(initialData, validations = undefined) {
  const [form, setValues] = useState(initialData)
  const [edited, toggleEdited] = useState(false)
  const [errors, setErrors] = useState({})

  function updateField({key, value}){
    setValues({
      ...form,
      [key]: value
    })

    let _error = validateField(key, value)

    setErrors({...errors, [key]: _error})


    toggleEdited(true)
  }

  function validateField(key, value){

    if (validations && validations[key] && validations[key].length > 0){
      let _errors = []

      validations[key].forEach(({id, validation}) => {
        if (!validation(value, form)){
          _errors = _errors.concat(id)
        }
      })

      return _errors.length > 0 ? _errors : false
    }

    return false
  }

  function resetForm(){
    setValues({
      ...initialData
    })
    setErrors({})
    toggleEdited(false)
  }

  function validate(){
    let _errors = {}

    Object.keys(validations).forEach((key) => {
      let _error = validateField(key, form[key] || "")
      if (_error) _errors[key] = _error
    })

    setErrors(_errors)

    return {valid: !Object.keys(_errors).length > 0, errors: _errors}

  }

  return {
    form,
    updateField,
    resetForm,
    setValues,
    edited,
    errors,
    validate
  }
}

import { gql } from '@apollo/client'

import { PURCHASE_FEEDBACK_FRAGMENT } from './'

export const CREATE_PURCHASE_FEEDBACK_MUTATION = gql`
  mutation createPurchaseFeedback($data:PurchaseFeedbackInput!){
    createPurchaseFeedback(data:$data){
      ...PURCHASE_FEEDBACK_FRAGMENT
    }
  }
  ${PURCHASE_FEEDBACK_FRAGMENT}
`

import FontAwesome from 'react-fontawesome'

import { useIntl } from '../../../hooks'
import { Map, MapPin, IntlText } from '../../../components'

import './style.css'

export function PurchaseAddress({purchase: {type, address, vendor}}) {

  const { getTranslatedValue } = useIntl()

  if (type === "CUSTOMER_PICKUP") return(
    <div className="order-address">
      <div className="infos">
        <div className="item">
          <div className="label"><FontAwesome name="info" /></div>
          <div className="info"><IntlText group='order-address' id='pickup-information' /></div>
        </div>
        {vendor.pickupPurchaseInstructions ?
          <div className="item">
            <div className="label"><FontAwesome name="comment-exclamation" /></div>
            <div className="info">{getTranslatedValue(vendor.pickupPurchaseInstructions)}</div>
          </div>
        :null}
      </div>
      <div className="map">
        <Map center={{lng: vendor.location.coordinates[0], lat: vendor.location.coordinates[1]}}>
          <MapPin lng={vendor.location.coordinates[0]} lat={vendor.location.coordinates[1]}><FontAwesome name="map-marker" /></MapPin>
        </Map>
      </div>
    </div>
  )

  return(
    <div className="order-address">
      <div className="info-table">
        <div className="info-table-item">
          <div className="label"><FontAwesome name="address-card" /><IntlText group="order-address" id="name" /></div>
          <div className="info">{address.name}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="building" /><IntlText group="order-address" id="building" /></div>
          <div className="info">{address.building}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="map-marker" /><IntlText group="order-address" id="unit" /></div>
          <div className="info">{address.unit}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="sticky-note" /><IntlText group="order-address" id="instructions" /></div>
          <div className="info">{address.instructions}</div>
        </div>
      </div>
      <div className="map">
        <Map center={{lng: address.location.coordinates[0], lat: address.location.coordinates[1]}}>
          <MapPin lng={address.location.coordinates[0]} lat={address.location.coordinates[1]}><FontAwesome name="map-marker" /></MapPin>
        </Map>
      </div>
    </div>
  )
}

import { useQuery, useSubscription } from '@apollo/client'

import { notify } from './helpers'
import { IS_LOGGED_IN, MY_PURCHASES_UPDATE_SUBSCRIPTION, SETTING_UPDATE_SUBSCRIPTION } from './graphql'

export function Subscriptions() {

  const { data } = useQuery(IS_LOGGED_IN)

  useSubscription(MY_PURCHASES_UPDATE_SUBSCRIPTION, {
    skip: !data?.isLoggedIn,
    onSubscriptionData: ({subscriptionData: {data: {myPurchasesUpdated: {status, preparationTime, customer}} }}) => {
      if (status === "INPREPARATION" && preparationTime)
        notify({title: `Dear ${customer.name}`, message: `Your order is processed with ${preparationTime} minutes preparation time.`})

      if (status === "ONTHEWAY")
        notify({title: `Dear ${customer.name}`, message: `The delivery guy is already on the way with your order.`})

      if (status === "ATLOCATION")
        notify({title: `Dear ${customer.name}`, message: `The delivery guy arrived to your location, please go out.`})

      if (status === "REJECTED")
        notify({title: `Dear ${customer.name}`, message: `Your order is rejected, please contact us for further info.`})

      if (status === "CANCELLED")
        notify({title: `Dear ${customer.name}`, message: `Your order is cancelled, please contact us for further info.`})
    }
  })

  useSubscription(SETTING_UPDATE_SUBSCRIPTION, {variables: {settings: ["infomodal", "alertbar", "alertbarColor", "alertbarHeading", "alertbarText", "largeInflow", "rainbreak", "internalBreak", "QUEUEISCLOSED", "alertbartitle_es", "alertbartext_es", "holidaybreak"]}})


  return null
}


import React from 'react'

import './Loader.css'

export default function Loader({theme = "", overlay = false, darkOverlay, loading = true, size = "", ...rest}) {

  if (!loading) return null

  if (overlay) return(
    <div className={`loader-overlay ${darkOverlay ? "dark" : ""}`} {...rest}>
      <div className={`loader ${theme}`}>
        <span></span>
      </div>
    </div>
  )

  return(
    <div className={`loader ${theme} ${theme} ${size}`} {...rest} >
      <span></span>
    </div>
  )
}

import { gql } from '@apollo/client'

const PAGE_FRAGMENT = gql`
  fragment PAGE_FRAGMENT on Page {
    id
    slug
    template
    title{
      en
      es
    }
    description{
      en
      es
    }
    content{
      en
      es
    }
    createdAt
  }
`

export default PAGE_FRAGMENT

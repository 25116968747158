import { gql } from '@apollo/client'

import { ADDRESS_FRAGMENT } from './'

export const CREATE_ADDRESS_MUTATION = gql`
  mutation createAddress($data:AddressInput){
    createAddress(data:$data){
      ...ADDRESS_FRAGMENT
    }
  }
  ${ADDRESS_FRAGMENT}
`

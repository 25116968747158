import { useState, useLayoutEffect, useRef, useEffect } from 'react'
import { AsYouType } from 'libphonenumber-js'
import FontAwesome from 'react-fontawesome'

import { useModal, useIntl } from '../../hooks'
import { toast } from '../../helpers'
import { Button, Form, Loader, Modalv3, PhoneNumber, SelectModal } from '../'

import './style.css'

function Text({placeholder, type = "textarea", children, save, actions, icon, disabled, validation}) {

  const [edit, toggleEdit] = useState(false)
  const [value, setValue] = useState(children)
  const element = useRef(null)

  function _save(){
    try {
      if (validation?.validate && !validation.validate(value)) return toast.error(validation.error)
      save(value)
      toggleEdit(false)
    } catch (error) {
      console.log("Error", error);
    }
  }

  function autogrow(){
    if (!element?.current) return null
    element.current.style.height = "0"
    element.current.style.height = element.current.scrollHeight - 2 +"px"
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  function reset(){
    setValue(children)
    toggleEdit(false)
  }

  useLayoutEffect(() => {
    if (type === "textarea") autogrow()
  })

  return(
    <div className={`edit-box ${value ? "populated" : ""} ${edit ? "edit" : ""}`}>
      {icon && showIcon()}
      <span className="value-text">
        <span className="label">{placeholder}</span>
        {type === "textarea" ? 
          <textarea ref={element} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
          :
          <input type={type} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
        }
      </span>
      {!disabled && save &&
        <div className="actions-wrapper">
            {edit ?
              <div className="actions">
                <Button icon="save" theme="main" onClick={_save}/>
                <Button icon="times" theme="alt" onClick={reset}/>
                {actions}
              </div>
            :
              <div className="actions">
                <Button icon="edit" theme="alt" onClick={() => toggleEdit(true)}/>
                {actions}
              </div>
            }
        </div>
      }
    </div>
  )

}

function Textv2({id, icon, placeholder, value, desc, note, children, validation, loading = false, save}) {

  const modal = useModal()
  const { getTranslation } = useIntl()
  const [_value, setValue] = useState(value)
  const opened = modal.opened

  useEffect(() => {
    if (opened) setValue(value)
  }, [value, opened])

  function _save(){
    try {
      if (validation?.validate && !validation.validate(_value)) throw new Error(validation.error)
      save(_value)
      modal.hide()
    } catch (error) {
      toast.error(error.message)
    }
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div id={id} className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'>{icon && showIcon()}</span>
      <div className="info">
        <div className="texts">
          <div className="desc">{desc}</div>
          <div className='label'>{children}</div>
        </div>
        <div className='checkout-option-value' onClick={() => modal.show()}>
          <div className='placeholder'>{placeholder}</div>            
          {value ?
            <div className='value'>
              <span>{value}</span>
              {save ? 
                <FontAwesome name="edit" />
              : null}
            </div>
          :
            <div className='value'>
              {save ? 
                <FontAwesome name="edit" />
              : null}
            </div>            
          }
        </div>
        <Modalv3 id={id} className="edit-box-textv2" modal={modal} headerTitle={children}>
          <Form.Form>
            <Form.Field>
              <input className='input' value={_value} onChange={({target}) => setValue(target.value)}/>
            </Form.Field>
            <Form.Field className='note'>
              {note}
            </Form.Field>
            <Form.Field>
              <div className='actions'>
                <Button onClick={modal.hide}>{getTranslation({id: "cancel"})}</Button>
                <Button theme='main' onClick={_save}>{getTranslation({id: "save"})}</Button>
              </div>
            </Form.Field>
          </Form.Form>
        </Modalv3>
      </div>
    </div>   
  )

}

function TextArea({id, icon, placeholder, value, desc, note, children, validation, loading = false, save}) {

  const modal = useModal()
  const { getTranslation } = useIntl()
  const [_value, setValue] = useState(value)
  const opened = modal.opened

  useEffect(() => {
    if (opened) setValue(value)
  }, [value, opened])

  function _save(){
    try {
      if (validation?.validate && !validation.validate(value)) return toast.error(validation.error)
      save(_value)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div id={id} className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'>{icon && showIcon()}</span>
      <div className="info">
        <div className="texts">
          <div className="desc">{desc}</div>
          <div className='label'>{children}</div>
        </div>
        <div className='checkout-option-value' onClick={() => modal.show()}>
          <div className='placeholder'>{placeholder}</div>            
          {value ?
            <div className='value'>
              <span>{value}</span>
              <FontAwesome name="edit" />
            </div>
          :
            <div className='value'>
              <FontAwesome name="edit" />
            </div>            
          }
        </div>
        <Modalv3 id={id} className="edit-box-textv2" modal={modal} headerTitle={children}>
          <Form.Form>
            <Form.Field>
              <textarea value={_value} onChange={({target}) => setValue(target.value)}/>
            </Form.Field>
            <Form.Field className='note'>
              {note}
            </Form.Field>
            <Form.Field>
              <div className='actions'>
                <Button onClick={modal.hide}>{getTranslation({id: "cancel"})}</Button>
                <Button theme='main' onClick={_save}>{getTranslation({id: "save"})}</Button>
              </div>
            </Form.Field>
          </Form.Form>
        </Modalv3>
      </div>
    </div>   
  )

}

function Phone({id, icon, placeholder, value, desc, note, children, validation, loading = false, save}) {

  const modal = useModal()
  const { getTranslation } = useIntl()
  const [_value, setValue] = useState(value)
  const opened = modal.opened

  useEffect(() => {
    if (opened) setValue(value)
  }, [value, opened])

  function _save(){
    try {
      if (validation?.validate && !validation.validate(value)) return toast.error(validation.error)
      save(_value)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div id={id} className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'>{icon && showIcon()}</span>
      <div className="info">
        <div className="texts">
          <div className="desc">{desc}</div>
          <div className='label'>{children}</div>
        </div>
        <div className='checkout-option-value' onClick={() => modal.show()}>
          <div className='placeholder'>{placeholder}</div>            
          {value ?
            <div className='value'>
              <span>{value && `+${value.phoneCode} ${new AsYouType(value?.countryCode).input(value?.phoneNumber)}`}</span>
              <FontAwesome name="edit" />
            </div>
          :
            <div className='value'>
              <FontAwesome name="edit" />
            </div>            
          }
        </div>
        <Modalv3 id={id} className="edit-box-textv2" modal={modal} headerTitle={children}>
          <Form.Form>
            <Form.Field>
              <PhoneNumber placeholder={placeholder} value={_value} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_value) => setValue(_value)} disablePreferences={true} />
            </Form.Field>
            <Form.Field className='note'>
              {note}
            </Form.Field>
            <Form.Field>
              <div className='actions'>
                <Button onClick={modal.hide}>{getTranslation({id: "cancel"})}</Button>
                <Button theme='main' onClick={_save}>{getTranslation({id: "save"})}</Button>
              </div>
            </Form.Field>
          </Form.Form>
        </Modalv3>
      </div>
    </div>   
  )

}

function Select({id, icon, placeholder, value, desc, children, options, validation, loading = false, save, }) {

  const modal = useModal()
  const { getTranslation } = useIntl()

  function _save(_value){
    try {
      if (validation?.validate && !validation.validate(value)) return toast.error(validation.error)
      save(_value)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div id={id} className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'>{icon && showIcon()}</span>
      <div className="info">
        <div className="texts">
          <div className="desc">{desc}</div>
          <div className='label'>{children}</div>
        </div>
        <div className='checkout-option-value' onClick={() => modal.show()}>
          <div className='placeholder'>{placeholder}</div>            
          {value ?
            <div className='value'>
              <span>{value.label}</span>
              <FontAwesome name="edit" />
            </div>
          :
            <div className='value'>
              <FontAwesome name="edit" />
            </div>            
          }
        </div>
        <SelectModal modal={modal} selected={value} defaultValue={getTranslation({id: "nothing-selected"})} placeholder={children} options={options} onChange={_save} />
      </div>
    </div>   
  )

}

const editbox = { Text, Textv2, TextArea, Select, Phone }

export default editbox
import { gql } from '@apollo/client'

export const GET_ONE_TIME_PASSWORD_OF_CUSTOMER = gql`
  mutation getOneTimePasswordOfCustomer($uid: String!, $method: CEL_VERIFICATION_METHOD!){
     getOneTimePasswordOfCustomer(uid: $uid, method: $method){
      linkToEmail
      oneTimePasswordToCel
      nextOneTimePasswordToCelSMS
      nextOneTimePasswordToCelWhatsapp
     }
  }
`

import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'

import { useForm } from '../../../hooks'
import { parsePhone, toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { VERIFY_CUSTOMER_CEL, REQUEST_CUSTOMER_VERIFICATION_CODE, CUSTOMER_CEL_VERIFICATIONS_STATUS } from '../../../graphql'
import { Form, IntlText, OTPInput, CountDown, Button, Title } from '../../../components'
import { HelpBox } from '../../../views'

export function ActiveVerification({status, changeMethod, success}){

    const [isError, toggleError] = useState(false)
    const cel = parsePhone(status?.cel)
    const { getTranslation } = useContext(IntlContext)
    const lastTryMethod = status?.lastSMSIntent > status?.lastWhatsappIntent ? "SMS" : "WHATSAPP"
    const [verifyCode, {loading}] = useMutation(VERIFY_CUSTOMER_CEL, {update})
    const [requestVerification, {loading: verificationLoading}] = useMutation(REQUEST_CUSTOMER_VERIFICATION_CODE, {update: updateRetry})
    const { form, updateField } = useForm({})
    const { otp } = form

    async function verify(_data){
        try {
            const code = _data || otp

            if (!code) throw new Error(getTranslation({group: "verify-number", id: "missing-code"}))
            const { data } = await verifyCode({variables:{code}})

            if (data?.verifyCustomerCel?.status === "UNVERIFIED") {
                toast.error(getTranslation({group: "verify-number", id: "wrong-code"}))
                toggleError(true)
            }
        } catch (error) {
            if (error?.graphQLErrors?.length > 0) {
                error.graphQLErrors.map(x => toast.error(x.message))
            } else if (error?.message){
                toast.error(error.message)
            }
            toggleError(true)
        }
    }

    async function resend(){
        try {
            await requestVerification({variables:{method: lastTryMethod }})
        } catch (error) {
            error.graphQLErrors.map(x => toast.error(x.message))
        }
    }

    async function update(cache, {data: {verifyCustomerCel}}){
        try {
            cache.writeQuery({
                query: CUSTOMER_CEL_VERIFICATIONS_STATUS,
                data: {customerCelVerificationStatus: verifyCustomerCel},
            })          

            if(success) await success()       

        } catch (error) {
            console.log("Cache update error", error);
        }
    }    

    async function updateRetry(cache, {data: {requestCustomerVerificationCode}}){
        try {
            cache.writeQuery({
                query: CUSTOMER_CEL_VERIFICATIONS_STATUS,
                data: {customerCelVerificationStatus: requestCustomerVerificationCode},
            })  
        } catch (error) {
            console.log("Cache update error");
        }
    }

    return(
        <div className='active-verification'>
            <Form.Form>
                <Title><IntlText group="verify-number" id={`active-verification-title-${lastTryMethod}`} /></Title>
                <Form.Field className='phone-number'>
                    <span><IntlText group="verify-number" id="enter-the-code" /></span>
                    <div className='cel'>{`+${cel.phoneCode} ${cel.phoneNumber}`}</div>
                </Form.Field>
                <Form.Field>
                    <OTPInput value={otp} onChange={(_values) => updateField({key: "otp", value: _values})} onFilled={verify} />
                </Form.Field>
                <Form.Field>
                    <Button loading={loading} theme="main" fluid onClick={() => verify()}><IntlText group="verify-number" id="verify" /></Button>
                </Form.Field>
                <Form.Field>
                    <div style={{display: "flex", width: "100%"}}>
                        <CountDown label={<IntlText group="verify-number" id="retry-in" />} end={lastTryMethod === "SMS" ? status?.nextSMSIntent : status?.nextWhatsappIntent}>
                            <Button loaderTheme="main" style={{flex: 1, color: "var(--ui-placeholder)"}} theme="blank" loading={verificationLoading} fluid onClick={resend}><IntlText group="verify-number" id="resend" /></Button>
                        </CountDown>                
                        <Button loaderTheme="main" style={{flex: 1, color: "var(--ui-placeholder)"}} theme="blank" fluid onClick={() => changeMethod(true)}><IntlText group="verify-number" id="change-method" /></Button>
                    </div>
                </Form.Field>
            </Form.Form>
            {isError &&
                <HelpBox />
            }
        </div>
    )
}

import { useQuery } from '@apollo/client'

import { SETTING_QUERY } from '../../graphql'
import { Button, Loader } from '../'

import './style.css'

export function WhatsAppHelp({style, children}) {

  const { loading, data, error } = useQuery(SETTING_QUERY, {variables: {key: "supportContact"}})
  
  if (loading) return <Loader theme="main" />
  if (error) return `Error! ${error.message}`

  const { setting } = data

  if (setting) return(
    <Button style={style} id="whatsapp-help" onClick={() => window.open(`https://api.whatsapp.com/send?phone=${setting.value}`)}><i className="fab fa-whatsapp"></i>{children ? children : "WhatsApp"}</Button>
  )
  
  return null

}

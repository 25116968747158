import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from '../vendor/$vendor'

const VENDORS_QUERY = gql`
  query vendors($query: String, $contract: CONTRACT_TYPE){
    vendors(query: $query, contract: $contract){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`

export default VENDORS_QUERY
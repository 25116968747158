import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import validator from 'validator'

import { MY_PURCHASES_QUERY, CREATE_INVOICE_MUTATION } from '../../graphql'
import { formatPrice, validateRFC, validateName, getCommonAsset } from '../../helpers'
import { useValidatedForm } from '../../hooks'
import { IntlContext } from '../../contexts'
import { Title, IntlText, Loader, Validation, LabeledInput, Button, Checkbox, Select } from '../../components'

import './Invoice.css'

export default function Invoice({history}) {

  const validations = {
    state: [{id: "required", validation: (val) => !!val}],
    taxSystem: [{id: "required", validation: (val) => !!val}],
    zip: [{id: "required", validation: (val) => !!val}],
    rfc: [{id: "required", validation: (val) => !!val}, {id: "rfc", validation: validateRFC}],
    name: [{id: "required", validation: (val) => !!val}, {id: "validName", validation: validateName}],
    email: [{id: "required", validation: (val) => !!val}, {id: "email", validation: (val) => validator.isEmail(val)}],
    selectedPurchase: [{id: "required", validation: (val) => !!(val && val.id)}]
  }
  const states = getCommonAsset('states.json')
  const taxSystems = getCommonAsset('tax-systems.json')
  const { getTranslation } = useContext(IntlContext)
  const [offset, setOffset] = useState(0)
  const { loading, data, fetchMore, error } = useQuery(MY_PURCHASES_QUERY, {variables: {status: ["COMPLETED"], limit: 5, offset: 0}})
  const [createInvoiceRequest, { loading: createLoading }] = useMutation(CREATE_INVOICE_MUTATION)
  const { form: {selectedPurchase, rfc, name, email, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem }, updateField, errors, validate, resetForm } = useValidatedForm({}, validations)

  function fetchMoreUpdate(prev, {fetchMoreResult}){
    setOffset(offset+5)
    if (!fetchMoreResult) return prev
    return Object.assign({}, prev, {
      myPurchases: [...prev.myPurchases, ...fetchMoreResult.myPurchases]
    })
  }

  async function submit(){
    let { valid, errors: _errors} = validate()
    if (!valid){
      if (_errors?.selectedPurchase?.find((key) => key === "required")) return toast.error(getTranslation({group: "request-invoice", id: "no-purchase-selected"}))
      return toast.error(getTranslation({id: "form-error"}))
    }
    
    try {
      await createInvoiceRequest({variables: {data: {
        rfc,
        name,
        email,
        street,
        exterior,
        interior,
        neighborhood,
        city,
        zip,
        municipality,
        state,
        taxSystem,
        purchase: selectedPurchase.id,
        type: "CUSTOMER_INVOICE",
        subject: "PURCHASE"
      }}})

      toast.success("Invoice requested successfully")
      resetForm()
      history.push("/invoices")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  if (loading) return <Loader theme="main" />
  if (error) return `Error: ${error}`

  const { myPurchases } = data

  return(
    <div id="invoice" className="container">
      <div className="row wrapped">
        <div className="row-header">
          <div className="heading">
            <Title tag="h2"><IntlText group="request-invoice" id="title" /></Title>
            <div className="desc"><IntlText group="request-invoice" id="desc" /></div>
          </div>
        </div>
        <div id="request-invoice">
          <div className="purchases">
            <div className="help"><IntlText group="request-invoice" id="purchases-help" /></div>
            <div id="purchases-list">
              {myPurchases.map((purchase, key) =>
                <div className="purchase" key={key} onClick={() => updateField({key: "selectedPurchase", value: purchase})}>
                  <div className="vendor-logo"><img src={purchase.vendor.logo} alt={purchase.vendor.name} title={purchase.vendor.name}/></div>
                  <div className="details">
                    <div className="title-wrapper">
                      <Title tag="h3">{purchase.vendor.name}</Title>
                      <div className="date">{moment(purchase.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</div>
                    </div>
                    <div className="price-wrapper">
                      <div className="price">
                        <span className="warn"><IntlText group="request-invoice" id="total-warn" /></span>
                        <span className="amount">{formatPrice(purchase.price.total)}</span>
                      </div>
                      {purchase.payment === "CARD_STRIPE" ?
                        <div className="invoiceable-price">
                          <span className="amount">{formatPrice(purchase.price.total)}</span>
                        </div>
                      :
                        <div className="invoiceable-price">
                          <span className="warn"><IntlText group="request-invoice" id="invoiceable-warn" /></span>
                          <span className="amount">{formatPrice(purchase.price.deliveryFeeTotal + purchase.price.tax)}</span>
                        </div>
                      }
                    </div>
                  </div>
                  <Checkbox checked={(selectedPurchase && selectedPurchase.id === purchase.id) || false} readOnly/>
                </div>
              )}
            </div>
            <div style={{marginTop: 20}} className="actions">
              <Button theme="alt" onClick={() => fetchMore({variables: {limit: 5, offset: myPurchases.length}, updateQuery: fetchMoreUpdate})}><IntlText id="load-more" /></Button>
            </div>
          </div>
          <div id="request-invoice-form">
            <div className="help"><IntlText group="request-invoice" id="form-help" /></div>
            <div className="form">
              <Validation errors={errors.rfc}>
                <LabeledInput name="rfc" placeholder={getTranslation({group: "request-invoice", id: "rfc"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{rfc}</LabeledInput>
              </Validation>
              <Validation errors={errors.name}>
                <LabeledInput name="name" placeholder={getTranslation({group: "request-invoice", id: "name"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{name}</LabeledInput>
              </Validation>
              <Validation errors={errors.email}>
                <LabeledInput name="email" placeholder={getTranslation({group: "request-invoice", id: "email"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{email}</LabeledInput>
              </Validation>
              <Validation errors={errors.street}>
                <LabeledInput name="street" placeholder={getTranslation({group: "request-invoice", id: "street"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{street}</LabeledInput>
              </Validation>
              <Validation errors={errors.exterior}>
                <LabeledInput name="exterior" placeholder={getTranslation({group: "request-invoice", id: "exterior"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{exterior}</LabeledInput>
              </Validation>
              <Validation errors={errors.interior}>
                <LabeledInput name="interior" placeholder={getTranslation({group: "request-invoice", id: "interior"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{interior}</LabeledInput>
              </Validation>
              <Validation errors={errors.neighborhood}>
                <LabeledInput name="neighborhood" placeholder={getTranslation({group: "request-invoice", id: "neighborhood"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{neighborhood}</LabeledInput>
              </Validation>
              <Validation errors={errors.city}>
                <LabeledInput name="city" placeholder={getTranslation({group: "request-invoice", id: "city"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{city}</LabeledInput>
              </Validation>
              <Validation errors={errors.zip}>
                <LabeledInput name="zip" placeholder={getTranslation({group: "request-invoice", id: "zip"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{zip}</LabeledInput>
              </Validation>
              <Validation errors={errors.municipality}>
                <LabeledInput name="municipality" placeholder={getTranslation({group: "request-invoice", id: "municipality"})} onChange={({target}) => updateField({key: target.name, value: target.value})}>{municipality}</LabeledInput>
              </Validation>
              <Validation errors={errors.state}>
                <Select placeholder={getTranslation({group: "request-invoice", id: "state"})} selected={states.find(s => s.abbreviation === state)} defaultValue={getTranslation({id: "please-select"})} options={states} labelId="name" onChange={(_data) => updateField({key: "state", value:  _data.abbreviation})} />
              </Validation>
              <Validation errors={errors.taxSystem}>
                <Select placeholder={getTranslation({group: "request-invoice", id: "taxSystem"})} selected={taxSystems.find(_ts => _ts.id === taxSystem)} defaultValue={getTranslation({id: "please-select"})} options={taxSystems} labelId="name" onChange={(_data) => updateField({key: "taxSystem", value:  _data.id})} />
              </Validation>              
              <Button theme="main" loading={createLoading} onClick={submit}><IntlText group="request-invoice" id="submit" /></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

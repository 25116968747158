import React, { useContext } from 'react';

import { SlideInContext } from '../../contexts'

export default function SlideInButton({id, className, children}) {

  const { toggleMenu } = useContext(SlideInContext)

  return(
    <div className={`slide-in-button ${className}`} onClick={() => toggleMenu(id)}>
      {children}
    </div>
  )
}

import { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { CartContext } from '../contexts'

export function Reset() {
  const { emptyCart } = useContext(CartContext)
  const navigate = useNavigate()

  useEffect(() => {
    emptyCart()
    navigate("/delivery-service")
    // eslint-disable-next-line
  }, [])

  return null
}

const defaultCenter = () => {
  switch (process.env.REACT_APP_APP_NAME) {
    case "adomi":
      return({
        lat: parseFloat(25.4271),
        lng: parseFloat(-100.1530)        
      })
    case "besmart":
      return({
        lat: parseFloat(20.501279),
        lng: parseFloat(-87.228575)        
      })      
    default:
      return({
        lat: parseFloat(20.2114185),
        lng: parseFloat(-87.465350)        
      })
  }
}

const config = {
  siteName: process.env.REACT_APP_SITE_NAME || "Tomato.mx",
  home: process.env.REACT_APP_HOME || "https://tomato.mx",
  authToken: `${process.env.REACT_APP_APP_NAME || "tomatomx"}-user-token`,
  userID: `${process.env.REACT_APP_APP_NAME || "tomatomx"}-user-id`,
  remoteUrl: process.env.REACT_APP_REMOTE_URL,
  subscriptionUrl: process.env.REACT_APP_SOCKET_URL,
  facebookAPPID: process.env.REACT_APP_FACEBOOK_APP_ID,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  defaultCenter: defaultCenter()
}

export default config

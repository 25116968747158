import { enFlag, esFlag} from '../../assets'

const defaultLanguanges = {
  en: require(`../../assets/languages/en.json`),
  es: require(`../../assets/languages/es.json`),
}
const languages = {
  en: require(`../../assets/${process.env.REACT_APP_APP_NAME || "tomato"}/en.json`),
  es: require(`../../assets/${process.env.REACT_APP_APP_NAME || "tomato"}/es.json`),
}
const config = {
  default: "en",
  languages: {
    es: {
      name: "Español",
      flag: esFlag,
      shortcode: "es",
      currency: "MXN",
      exchange: 1
    },
    en: {
      name: "English",
      flag: enFlag,
      shortcode: "en",
      currency: "$",
      exchange: 1/17
    },
  }
}

const strings = {
  en: {...defaultLanguanges.en, ...languages.en},
  es: {...defaultLanguanges.es, ...languages.es},
}

export {config, strings}

import React from 'react'

export default function MenuSkeleton() {
  return(
    <div id="skeleton-menu">
      <div className="menu-items">
        {[...Array(48)].map((data, key) =>
          <div key={key} className="menu-item-skeleton">
            <div><div className="line" /></div>
            <div><div className="line" /></div>
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import { useQuery } from '@apollo/client'

import { CUSTOMER_BALANCE } from '../../graphql'
import { Title, IntlText, Loader } from '../../components'
import { CustomerBalance, CustomerBalanceHistory } from '../../views'

export function Balance() {

  const { data, loading, error } = useQuery(CUSTOMER_BALANCE)

  if (loading) return <Loader theme="main" />
  if (error) return <Loader theme="main" />

  const { customerBook, customerBookEntries } = data

  return(
    <div id="balance" className="container">
      <div className="row wrapped">
        <div id="addresses-wrapper">
          <div className="heading">
            <Title tag="h2"><IntlText group="balance" id="title" /></Title>
            <div className="desc"><IntlText group="balance" id="desc" /></div>
          </div>
          <div className="content">
            <CustomerBalance customerBook={customerBook}/>
            <CustomerBalanceHistory customerBookEntries={customerBookEntries}/>
          </div>
        </div>
      </div>
    </div>
  )
}

import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../fragments'

const INVOICE_REQUEST_QUERY = gql`
  query invoiceRequest($purchase: ID){
    invoiceRequest(purchase: $purchase){
      ...INVOICE_FRAGMENT
    }
  }
  ${INVOICE_FRAGMENT}
`

export default INVOICE_REQUEST_QUERY

import { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useForm } from '../../hooks'
import { validateEmail, validatePhonenumber, parsePhone } from '../../helpers'
import { IntlContext } from '../../contexts'
import { EDIT_CUSTOMER_MUTATION } from '../../graphql'
import { Button, LabeledInput, PhoneNumberInput, Validation, IntlText, Actions, Checkbox, Link } from '../../components'
import { HelpBox } from '../../views'

import './style.css'

const validations = {
  name: [{id: "required", validation: (val) => !!val}],
  email: [{id: "required", validation: (val) => !!val}, {id: "email", validation: (val) => validateEmail(val)}],
  phone: [{id: "required", validation: (val) => !!(val?.phoneNumber && val?.phoneCode)}, {id: "incorrectNumber", validation: (val => validatePhonenumber(val))}],
}

export function ProfileForm({customer}) {

  const [editProfile, {loading: editLoading}] = useMutation(EDIT_CUSTOMER_MUTATION)
  const { getTranslation } = useContext(IntlContext)
  const { form, updateField, errors, validate, status, getEditedData } = useForm({
    name: customer?.name,
    email: customer?.email,
    phone: customer?.phone,
    subscribedToNewsletter: customer?.subscribedToNewsletter
  }, validations)
  const { name, phone, email, subscribedToNewsletter } = form

  async function submit(){

    const { valid } = validate()
    const _data = getEditedData()
    
    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await editProfile({variables:{id: customer?.id, data: _data}})
      toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="profile-form">
      <div className="form">
        <Validation errors={errors.name}>
          <LabeledInput name="name" placeholder={getTranslation({id: "input-name"})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Validation>
        <Validation errors={errors.email}>
          <LabeledInput name="email" placeholder={getTranslation({id: "input-email"})} value={email} onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Validation>
        <Validation errors={errors.phone}>
          <PhoneNumberInput disabled={customer?.banned} name="phone" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(phone)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "phone", value: {countryCode: _data?.countryCode, phoneNumber: _data?.phoneNumber, phoneCode: _data?.phoneCode}})} />
        </Validation>
        <Checkbox id="newsletter" checked={!!subscribedToNewsletter} onChange={() => updateField({key: "subscribedToNewsletter", value: !subscribedToNewsletter})}>
          <IntlText group="newsletter" id="sign-up" />
        </Checkbox>      
        <Actions>
          <Link to={-1} button><IntlText id="back" /></Link>
          <Button theme="main" loading={editLoading} onClick={submit}><IntlText id="save" /></Button>
        </Actions>
        {status?.id === "failed" &&
          <HelpBox />
        }
      </div>
    </div>
  )
}

import { getAsset } from '../helpers'
import { notification } from '../assets'

var audio = new Audio(notification)
const icon = getAsset("icon.png")

export function notify(options) {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification")
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    var notification = new Notification(options.title, {
      icon: icon,
      body: options.message,
     })

     audio.play().catch(e => console.log("Error: Can't play sound!", e))

     if (options.url){
       notification.onclick = function() {
        window.open(options.url)
       }
     }
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        var notification = new Notification(options.title, {
          icon: icon,
          body: options.message,
         })

         audio.play().catch(e => console.log("Error: Can't play sound!", e))

         if (options.url){
           notification.onclick = function() {
            window.open(options.url)
           }
         }
      }
    })
  }

}

import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const MY_PURCHASE_QUOTES = gql`
  query myPurchaseQuotes($status: PURCHASE_QUOTE_STATUS!){
    myPurchaseQuotes(status: $status){
      ...PURCHASE_QUOTE_FRAGMENT
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`

import { gql } from '@apollo/client'

export const GET_CUSTOMER_RECOVERY_OPTIONS = gql`
  mutation getCustomerAccountRecoveryOptions($uid: String!){
     getCustomerAccountRecoveryOptions(uid: $uid) {
        linkToEmail
        oneTimePasswordToCel
        nextOneTimePasswordToCelSMS
        nextOneTimePasswordToCelWhatsapp
     }
  }
`

import React from 'react'

import { Loader } from '../'

const TomatoTheme = React.lazy(() => import('./TomatoTheme'))
const AdomiTheme = React.lazy(() => import('./AdomiTheme'))
const BesmartTheme = React.lazy(() => import('./BesmartTheme'))

export function Theme({ children }){

    function getTheme(){
        switch (process.env.REACT_APP_APP_NAME) {
            case "adomi":
                return <AdomiTheme />
            case "besmart":
                return <BesmartTheme />                
            default:
                return <TomatoTheme />
        }
    }

    return(
        <>
            <React.Suspense fallback={<Loader />}>
                {getTheme()}
            </React.Suspense>
            {children}
        </>
    )
}
import config from '../config'
import client, { wsLink } from '../client'

import { IS_LOGGED_IN } from '../graphql'

export default class AuthKit {

  static async login(user, token, onLogin){
    try {
      localStorage.setItem(config.userID, user.id)
      localStorage.setItem(config.authToken, token)
      await wsLink.subscriptionClient.connect()
      onLogin && onLogin()
    } catch (error) {
      console.log("Login error", error) 
    }
  }

  static logout(){
    try {
      localStorage.removeItem(config.userID)
      localStorage.removeItem(config.authToken)
      client.cache.reset()
      client.writeQuery({
        query: IS_LOGGED_IN,
        data: {
          isLoggedIn: false,
          email: "",
          name: "",
          facebookID: null    
        }
      })      
    } catch (error) {
      console.log("Logout error", error)       
    }
  }
}

import React from 'react'

import { IntlText, Button } from '../../../components'

import './style.css'

export function PurchaseButton(props){

  return(
    <Button id="purchase-button" fluid theme="main" {...props}>
      <IntlText group="place-purchase" id="place-order" />
    </Button>    
  )
}

import React from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css'

export function Badge({theme = "ui", icon, children, style}) {

  function showIcon(){
    if (!icon) return null 

    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div className={`badge ${theme}`} style={style}>
      {showIcon()}
      <span className="amount">{children}</span>
    </div>
  )

}

import React from 'react'

import { Loader } from '../../components'

import './style.css'

export function Switch({id, className = "", checked, name, placeholder, children, onChange, loading, readOnly}) {

  return(
    <div className={`switch ${className}`}>
      {(placeholder || children) && <span className="toggle-string">{placeholder || children}</span>}
      <input id={id} name={name} className="toggle-status" type="checkbox" checked={checked ? "checked" : ""} value={checked ? 1 : 0} onChange={onChange} readOnly={readOnly}/>
      <label htmlFor={id}>
          <span className="aural"></span> 
      </label>
      {loading ?
        <Loader theme="main"/>
      :      
        <div className="onoffswitch pull-right" aria-hidden="true">
            <div className="onoffswitch-label">
                <div className="onoffswitch-inner"></div>
                <div className="onoffswitch-switch"></div>
            </div>
        </div>
      }
    </div>
  )
}

import { Modalv3, Title, IntlText } from '../../components'
import { SelectLocation } from '../../views'

export function SelectLocationModal({modal}){

    return(
        <Modalv3 id="select-location" modal={modal} header={<Title tag="h3"><IntlText group="select-location" id="select-location" /></Title>}>
            <SelectLocation callback={modal.hide} showMap={false} />
        </Modalv3>              
    )
}
import React from 'react'

import './style.css'

export function Actions({children, full, inline}){

  return(
    <div className={`actions ${full ? "full" : null} ${inline ? "inline" : null}`}>
      {children}
    </div>
  )

}

import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { Button } from '../'

import './style.css'

export function ActionsDropdown({placeholder, children, size}){

  const [opened, toggle] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    function handleClick(e){
      if (!element.current || element.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  return(
    <div className={`actions-dropdown ${size}`} ref={element}>
      <Button className="alt" onClick={() => toggle(!opened)}><FontAwesome name="bars" />{placeholder}</Button>
      <ul className={`actions-dropdown-dropdown ${opened ? "opened" : ""}`}>
        {children}
      </ul>
    </div>
  )

}

import { gql } from '@apollo/client'

export const PURCHASE_ADDRESS_FRAGMENT = gql`
  fragment PURCHASE_ADDRESS_FRAGMENT on PurchaseAddress {
    originalId
    name
    slug
    zone
    unit
    building
    instructions
    location{
      coordinates
    }
  }
`

import React, { useContext } from 'react'

import { DropdownV2 } from '../'
import { IntlContext } from '../../contexts'

import './style.css'

export function LanguageSwitcher() {
  const { selectedLanguage, languages, setLanguage } = useContext(IntlContext)

  return(
    <div className="language-switcher">
      <DropdownV2.Dropdown icon={<img src={selectedLanguage.flag} alt={selectedLanguage.name} title={selectedLanguage.name}/>} button={<span>{selectedLanguage.name}</span>}>
        {Object.entries(languages).map(([key, language]) =>
          <DropdownV2.Item key={key} icon={<img src={language.flag} alt={language.name} title={language.name}/>}  onClick={() => setLanguage(language)}>{language.name}</DropdownV2.Item>
        )}
      </DropdownV2.Dropdown>
    </div>
  )
}

import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const PAGINATED_QUERIED_VENDORS_LIST = gql`
  query paginatedQueriedVendorList($query: String, $term: VENDOR_LIST_TERM, $highRating: Boolean, $onlyOpen: Boolean, $first: Int, $after: String, $sortBy: VENDOR_LIST_SORT, $mustAcceptCard: Boolean){
    paginatedQueriedVendorList(query: $query, term: $term, highRating: $highRating, onlyOpen: $onlyOpen, first: $first, after: $after, sortBy: $sortBy, mustAcceptCard: $mustAcceptCard){
      edges{
        cursor
        node{
            ...VENDOR_FRAGMENT            
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VENDOR_FRAGMENT}
`

/*
hasPickup: $hasPickup
mustHavePickup: $mustHavePickup,
 */
import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from '../fragments'

const PRODUCT_QUERY = gql`
  query product($id: ID, $slug: String){
    product(id: $id, slug: $slug){
      ...PRODUCT_FRAGMENT
    }
  }
  ${PRODUCT_FRAGMENT}
`

export default PRODUCT_QUERY

import { useNavigate } from 'react-router-dom'

import { Button, Title, IntlText, Link, Map } from '../../../components'
import { map } from '../../../assets'

export function AddressModeSelector() {

  const navigate = useNavigate()

  return(
    <div id="address" className="view">
      <div id="address-map" className="blur">
        <Map class="map" />
        <div id="map-overlay">
          <div className="map-overlay-inner">
            <div id="mode-selector">
              <Title>
                <IntlText group="select-location" id="add-new" />
              </Title>
              <div className="mode-list">
                <Link to="/address/detect" icon="location-arrow" className="main" button>
                  <IntlText group="address-seletion" id="detect-location" />
                </Link>
                <Link to="/address/new" icon="hand-pointer" button>
                  <IntlText group="address-seletion" id="select-location" />
                </Link>
                <hr />
                <Button onClick={() => navigate(-1)}>
                  <IntlText group="default" id="back" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="address-help-box">
        <div className="icon">
          <img alt="add-address" title="add-address" src={map} />
        </div>
        <div className="info">
          <Title tag="h1"><IntlText group="address" id="default-title" /></Title>
          <div className="desc"><IntlText group="address" id="default-desc" /></div>
        </div>
      </div>
    </div>    
  )
}

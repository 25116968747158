import React from 'react'
import { Link } from 'react-router-dom'

import { getImageInSize } from '../../../helpers'
import { Title, IntlValue } from '../../../components'
import { VendorTags, DeliveryFee, VendorRating, OpeningHours, ClosedLabel } from '../../../views'

import './style.css'

export function PartnerCard({vendor}) {
  
  return(
    <div>
      <Link to={`/${vendor.slug}`}>
        <div className={`partner-card ${!vendor.isOpen ? "closed" : ""}`}>
          <div className="inner">
            <div className="logo-wrapper">
              <div className="vendor-logo">
                <picture>
                  <source srcSet={getImageInSize(vendor.logo, "150", "webp")} type="image/webp" />
                  <source srcSet={getImageInSize(vendor.logo, "150", "png")} type="image/png" />
                  <img src={vendor.logo} alt={vendor.name} title={vendor.name} />
                </picture>                   
              </div>
            </div>
            <div className="info">
              <div className="top">
                <Title tag="h3">
                  <span>{vendor.name}</span>
                  <VendorTags style={{marginLeft: "auto"}} vendor={vendor} />
                </Title>
                <div className="desc">{vendor.intro && <IntlValue>{vendor.intro}</IntlValue>}</div>
                <ClosedLabel vendor={vendor} />
              </div>
            </div>
          </div>
          <div className="bottom">
            <DeliveryFee vendor={vendor}/>
            <VendorRating vendor={vendor}/>
            <OpeningHours vendor={vendor}/>
          </div>
        </div>
      </Link>
    </div>
  )
}

import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'
import parse from 'html-react-parser'

import { Loader } from '../../components'
import { IntlContext } from '../../contexts'
import { SETTINGS_QUERY } from '../../graphql'
import { extractSettings } from '../../helpers'

import './AlertBar.css'

export default function AlertBar() {

  const { selectedLanguage } = useContext(IntlContext)
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["alertbar", "alertbarHeading", "alertbarText", "alertbartext_es", "alertbartitle_es", "alertbarColor"]}})

  if (loading) return <Loader />
  if (error) return null

  const settings = extractSettings(data.settings)

  if (settings?.["alertbar"])
    return(
      <div id="alert-bar" className={`alert-bar ${settings?.["alertbarcolor"]} active`}>
        <div className="icon"><FontAwesome name="exclamation-triangle" /></div>
        <div className="message">
          <div className="title">{selectedLanguage.shortcode === "en" ? settings?.["alertbarheading"] : settings?.["alertbartitle_es"]}</div>
          {parse(selectedLanguage.shortcode === "en" ? settings?.["alertbartext"] : settings?.["alertbartext_es"])}
        </div>
      </div>
    )

  return null
}

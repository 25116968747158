import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIntl, useQueryBuilder } from '../../hooks'
import { IntlText, Filters } from '../../components'
import { Announcments, PartnersGrid, PartnersSearch, VendorFilters, IconFilter } from '../../views'

import './style.css'

export function DeliveryService() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const { getTranslation } = useIntl()
  const { params, removeParam, clearParams, isQuery, buildQuery, updateQuery } = useQueryBuilder({
    data: {
      highRating: {key: "highRating", label: <IntlText group="menu-filter" id="high-rating" />, value: (search.get("highRating") === 'true')},
      mustAcceptCard: {key: "mustAcceptCard", label: <IntlText group="menu-filter" id="online-payment" />, value: (search.get("mustAcceptCard") === 'true')},
      hasPickup: {key: "hasPickup", label: <IntlText group="menu-filter" id="online-payment" />, value: (search.get("hasPickup") === 'true')},
    }
  })

  return(
    <div id="delivery-service" className="container">
      <Helmet>
        <title>{getTranslation({group: "meta", id: "title"})}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={getTranslation({group: "meta", id: "description"})} />
        <meta property="og:title" content={getTranslation({group: "meta", id: "title"})} />
        <meta property="og:description" content={getTranslation({group: "meta", id: "description"})} />
        <meta property="og:url" content={`${window.location.protocol}//${window.location.host + location.pathname}`} />
      </Helmet>
      <IconFilter onSelect={(tag) => navigate(`/search?query=${tag}`)} selected={params?.query?.value} reset={() => removeParam("query")} />
      <div id="menu-filter">
        <div className="row">
          <Filters.Bar>
            <VendorFilters params={params} removeParam={removeParam} updateQuery={updateQuery}/>
          </Filters.Bar>
        </div>
      </div>
      {isQuery() ?
        <div className="row padding">
          <PartnersSearch isQuery={isQuery} buildQuery={buildQuery} clearParams={clearParams} />
        </div>
      :
        <>
          <Announcments />
          <PartnersGrid />
        </>
      }
      <div className="row padding">
        <div id="conditions">
          <p><IntlText group="conditions" id="about-tomato" /></p>
        </div>
      </div>
    </div>
  )
}

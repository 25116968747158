import { gql } from '@apollo/client'

export const ANNOUNCEMENT_FRAGMENT = gql`
  fragment ANNOUNCEMENT_FRAGMENT on Announcement {
    id
    slug
    title{
      en
      es
    }
    description{
      en
      es
    }
    content{
      en
      es
    }
    intlThumbnail{
      en
      es
    }
    intlCover{
      en
      es
    }
    template
    link
    tags
    active
  }
`

import React, { useState, useRef, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css'

export function Dropdown({id, placeholder, children, selected}){

  const [opened, toggle] = useState(false)

  const dropdown = useRef(null)
  const dropdownMenu = useRef(null)

  useEffect(() => {
    function handleClick(e){
      if ((!dropdown.current || dropdown.current.contains(e.target)) && !dropdownMenu.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  return(
    <div id={id} className={`dropdown ${opened ? "opened" : ""}`} ref={dropdown}>
      <div className="toggle" onClick={() => toggle(!opened)}>
        <FontAwesome name="bars" />
        {selected || placeholder}
      </div>
      <ul className="menu" ref={dropdownMenu}>
        {children}
      </ul>
    </div>
  )

}

import { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { useSaveReferralCode } from '../../hooks'
import { ModalContext, SlideInContext } from '../../contexts'

export default function Wrapper({children}) {

  const { closeMenu } = useContext(SlideInContext)
  const { closeModal } = useContext(ModalContext)
  const location = useLocation()

  useSaveReferralCode() 
  useEffect(() => {
    closeModal()
    closeMenu()
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
    }
  // eslint-disable-next-line
  }, [location])

  return children

}

import React from 'react'

import './style.css'

export function FacebookLoginButton({onClick, children}) {
  return(
    <button className="social-login-button facebook" onClick={onClick}>
      {children}
    </button>
  )
}

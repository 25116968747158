import React from 'react'

import { useAuth } from '../../hooks'
import { ReferralRules, ReferredCustomers, ReferralTransactions, ReferralStats, ReferralCode, ReferralTerms } from '../../views'

export function Referrals() {

  const { loading, user, error } = useAuth()

  if (loading || error) return null

  return(
    <div id="referrals" className="container">
      <div className="row wrapped">
        <ReferralRules />
        <div className='row-content'>
          <ReferralTerms />
          {user?.referralTermsAccepted && 
            <>
              <ReferralCode />
              <ReferralStats />
              <ReferredCustomers />
              <ReferralTransactions />
            </>
          }
        </div>
      </div>
    </div>
  )
}

import { formatPrice } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function PurchaseTotals({children, purchase}) {

  function inCurrency(_value){
    return `${_value} ${purchase.currency}`
  }

  return(
    <div id="purchase-totals">
      <div className="order-notficiations">
        {children}
      </div>
      <table className="totals-table">
        <tbody>
          <tr className="purchase-total">
            <td><IntlText group="order-totals" id="subtotal" /></td>
            <td>{formatPrice(purchase?.price?.subtotal)}</td>
          </tr>    
          {purchase?.price?.serviceFeeCustomer ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="serviceFeeCustomer" /></td>
              <td>{formatPrice(purchase?.price?.serviceFeeCustomer)}</td>
            </tr>          
          : null}      
          {purchase?.price?.discount ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="discount" /></td>
              <td>{formatPrice(purchase?.price?.discount)}</td>
            </tr>          
          : null}      
          {purchase?.price?.grossTip ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="grossTip" /></td>
              <td>{formatPrice(purchase?.price?.grossTip)}</td>
            </tr>          
          : null}      
          {purchase?.price?.transactionFee ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="transactionFee" /></td>
              <td>{formatPrice(purchase?.price?.transactionFee)}</td>
            </tr>          
          : null}      
          {purchase?.type !== "CUSTOMER_PICKUP" ? 
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="deliveryFeeTotal" /></td>
              <td>{formatPrice(purchase?.price?.deliveryFeeTotal)}</td>
            </tr>          
          : null}
          {purchase?.price?.tax ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="tax" /></td>
              <td>{formatPrice(purchase?.price?.tax)}</td>
            </tr>          
          : null}      
          {purchase?.price?.customerCashBalanceAdjustment ?
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="balance" /></td>
              <td>{formatPrice(purchase?.price?.customerCashBalanceAdjustment)}</td>
            </tr>          
          : null}      
          <tr className="purchase-total">
            <td><IntlText group="order-totals" id="total" /></td>
            <td>{formatPrice(purchase?.payment === "CASH" ? purchase?.price?.chargeInCash : purchase?.price?.chargeOnCard)}</td>
          </tr>          
          {purchase?.currency !== "MXN" ? 
            <tr className="purchase-total">
              <td><IntlText group="order-totals" id="totalInCurrency" /></td>
              <td>{inCurrency(purchase?.payment === "CASH" ? purchase?.price?.cashChargeInCurrency : purchase?.price?.totalInCurrency)}</td>
            </tr>          
          : null}
        </tbody>
      </table>
    </div>
  )
}

import React, { useContext } from 'react'
import moment from 'moment'

import { formatPrice, getAsset } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { IntlValue, IntlText } from '../../../components'

import './style.css'

export function CustomerBalanceHistory({customerBookEntries}) {

  const icon = getAsset("icon.png")
  const { getTranslation } = useContext(IntlContext)

  if (!customerBookEntries?.nodes.length > 0) return null

  return(
    <div id="balance-history">
      <table className="table">
        <thead>
          <tr>
            <th><IntlText group="balance-history" id="th-date"/></th>
            <th><IntlText group="balance-history" id="th-type"/></th>
            <th className="price-field"><IntlText group="balance-history" id="th-amount"/></th>
          </tr>
        </thead>
        <tbody>
          {customerBookEntries?.nodes?.map((historyItem, key) =>
            <tr className="item" key={key}>
              <td data-label={getTranslation({group: "balance-history", id: "th-date"})}>{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
              <td data-label={getTranslation({group: "balance-history", id: "th-type"})} className="description-field">
                <div>
                  <span><IntlValue>{historyItem?.description}</IntlValue></span>
                </div>
              </td>
              <td data-label={getTranslation({group: "balance-history", id: "th-amount"})} className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                {historyItem?.type === "TOMATO_POINTS" ?
                  <div className="tomato-point"><span>{historyItem?.value}</span><img alt="tomato points" src={icon} className="icon" /></div>
                :
                  <span>{formatPrice(historyItem?.value)}</span>
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { extractSettings } from '../../../helpers'
import { SETTINGS_QUERY } from '../../../graphql'
import { IntlText, Loader, Timer } from '../../../components'

import './style.css'

export function PurchaseAlerts({purchaseQuote, children, refetch}){
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["TAX_RATE", "QUEUEISCLOSED", "MINIMUM_ORDER", "rainbreak", "internalBreak", "holidayBreak", "PURCHASES_TEMPORARILY_PAUSED"]}, fetchPolicy: "network-only"})

  if (loading || error) return <Loader theme="main"/>

  const settings = extractSettings(data.settings)

  return(
    <div id="purchase-alerts">
      {purchaseQuote?.vendorClosesInSeconds < 300 &&
        <div className="purchase-alert warning">
          <Timer label={<><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="restaurant-will-close" /></>} time={purchaseQuote?.vendorClosesInSeconds} start={Date.now()} onReady={refetch} />
        </div>
      }
      {(settings["queueisclosed"]) &&
        <div className="purchase-alert warning"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="queue-is-closed" /></div>
      }      
      <Errors purchaseQuote={purchaseQuote} settings={settings}>{children}</Errors>
    </div>
  )
}

function Errors({settings, purchaseQuote, children}){
  
  const { isTomatoOpen, vendorClosesInSeconds, pickupAvailable, deliveryAvailable, cashPaymentAvailable, stripePaymentAvailable, deliveryUnavailableReason, networkTransactionsDisabledForVendor, customerBalance, unavailableProducts, price, payment, type } = purchaseQuote

  if (settings["holidaybreak"]) return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="holiday-break" /></div>
  )
  
  if (settings["rainbreak"]) return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="rain-break" /></div>
  )
      
  if (!isTomatoOpen || settings["internalbreak"]) {
    if (settings["purchases_temporarily_paused"]){
      return(
        <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="PURCHASES_TEMPORARILY_PAUSED" /></div>
      )
    } else {
      return(
        <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="tomato-closed" /></div>
      )
    }
  }

  if (vendorClosesInSeconds === 0) return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="restaurant-closed" /></div>
  )

  if (unavailableProducts?.length > 0) return (
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="diabled-items-in-cart" /></div>
  )

  if (settings["minimum_order"] > price?.subtotal) return (
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="minimum-amount" variables={{min: settings["minimum_order"]}} /></div>
  )

  if (type === "ONDEMAND" && !deliveryAvailable) return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id={deliveryUnavailableReason} /></div>
  )

  if (type === "CUSTOMER_PICKUP" && !pickupAvailable) return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="pichup-not-available" /></div>
  )

  if (!stripePaymentAvailable && payment === "CARD_STRIPE") return(
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="conekta-disabled" /></div>
  )
  
  if (!cashPaymentAvailable && payment === "CASH") return (networkTransactionsDisabledForVendor && customerBalance < 0 ?
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="cod-disabled-balance" /></div>
  :
    <div className="purchase-alert error"><FontAwesome name="exclamation-triangle" /><IntlText group="purchase-alerts" id="cod-disabled" /></div>
  )

  return children

}
import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from '../'

const ME_QUERY = gql`
  query {
     me {
       ...CUSTOMER_FRAGMENT
     }
  }
  ${CUSTOMER_FRAGMENT}
`

export default ME_QUERY

import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from '../fragments'

const SETTING_UPDATE_SUBSCRIPTION = gql`
  subscription settingUpdated($settings: [String]!){
    settingUpdated(settings: $settings){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`

export default SETTING_UPDATE_SUBSCRIPTION

import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../fragments'

const CREATE_INVOICE_MUTATION = gql`
  mutation createInvoiceRequest($data: InvoiceRequestInput!){
    createInvoiceRequest(data:$data){
      ...INVOICE_FRAGMENT
    }
  }
  ${INVOICE_FRAGMENT}
`

export default CREATE_INVOICE_MUTATION

import { useIntl, useModal } from '../../../hooks'
import { Title, IntlValue, Modalv3, IntlText } from '../../../components'

import './style.css'

export function VendorPromotions({vendor: {promotions}}) {

  const modal = useModal()
  const { getTranslation } = useIntl()

  if (!(promotions?.length > 0)) return null

  return(
    <div id="vendor-promotions">
      {promotions.filter(promotion => promotion.active).map((promotion, key) => 
        <div key={key} className="promotions-title" onClick={() => modal.show(promotion)}>
          <i className="fas fa-badge-percent"></i>
          <div className="info">
            <Title><IntlValue>{promotion.label}</IntlValue></Title>
          </div>
        </div>    
      )}
      <Modalv3 modal={modal} className="promotion-modal" headerTitle={getTranslation({group: "promotion", id: "modal-title"})}>
        <PromotionInfo promotion={modal.state} />
      </Modalv3>
    </div>
  )
}

function PromotionInfo({promotion}){

  if (!promotion?.active) return null
  
  const minBasketValue = promotion.restrictions?.find(p => p.key === "MINBASKETVALUE")?.value
  const discount = promotion.configuration?.find(p => p.key === "DISCOUNT")?.value  

  return(
    <div className='promotion-info'>
      {minBasketValue > 0 ? 
        <IntlText group='promotion' id={`${promotion.type}-info`} variables={{minBasketValue, discount: discount * 100}} />
        :
        <IntlText group='promotion' id={`${promotion.type}-no-limit-info`} variables={{minBasketValue}} />
      }        
    </div>    
  )
}
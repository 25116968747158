import { gql } from '@apollo/client'

import { PURCHASE_FEEDBACK_FRAGMENT } from './'

export const EDIT_PURCHASE_FEEDBACK_MUTATION = gql`
  mutation editPurchaseFeedback($id: ID, $data:EditedPurchaseFeedbackInput!){
    editPurchaseFeedback(id: $id, data:$data){
      ...PURCHASE_FEEDBACK_FRAGMENT
    }
  }
  ${PURCHASE_FEEDBACK_FRAGMENT}
`

import React from 'react'

import { useIntl } from '../../hooks'
import { Loader } from '../../components'

import './style.css'

export function Toggle({id, className = "", checked, name, onChange, loading, readOnly, yes, no}) {

  const { getTranslation } = useIntl()

  return(
    <div className={`toggle ${className}`}>
      <input id={id} type="checkbox" name={name} checked={checked} onChange={onChange} readOnly={readOnly} />
      <label htmlFor={id}>
        {loading ?
          <Loader theme="main"/>
          :      
          <div className="toggle__switch" data-checked={yes || getTranslation({id: "yes"})} data-unchecked={no || getTranslation({id: "no"})}></div>
        }
      </label>
    </div>    
  )
}

import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { MY_PURCHASES_QUERY, MY_PURCHASES_UPDATE_SUBSCRIPTION } from '../../../graphql'
import { Title, Button, IntlText } from '../../../components'
import { OrderSkeleton, PurchasesList } from '../../../views'

import './style.css'

export function PurchaseHistory() {

  const [offset, setOffset] = useState(0)
  const { loading, data, error, fetchMore, refetch, subscribeToMore } = useQuery(MY_PURCHASES_QUERY, {variables: {status: ["COMPLETED", "DELIVERYFAILED", "CANCELLED", "REJECTED", "UNPROCESSED", "PAYMENTFAILED"], limit: 5, offset: 0}})

  useEffect(() => {
    async function updatePurchases(){
      try {
        if (document.visibilityState !== 'visible') return false
        await refetch()
      } catch (error) {
        console.log("Update orders history error ", error);
      }
    }

    const unsub =  subscribeToMore({
      document: MY_PURCHASES_UPDATE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = Array.from(prev?.myPurchases || [])
        const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
        if (index >= 0){
          if (!["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
            _data.splice(index, 1)
          } else {
            _data[index] = subscriptionData.data.myPurchasesUpdated
          }
          return Object.assign({}, prev, {
            myPurchases: _data
          })
        } else if (index === -1 && ["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(subscriptionData.data.myPurchasesUpdated.status)) {
          return Object.assign({}, prev, {
            myPurchases: [subscriptionData.data.myPurchasesUpdated, ..._data]
          })          
        }
      }
    })    
        

    updatePurchases()

    document.addEventListener("visibilitychange", updatePurchases)
    return () => {
      unsub()
      document.removeEventListener("visibilitychange", updatePurchases)
    }
  // eslint-disable-next-line    
  }, [])

  function fetchMoreUpdate(prev, {fetchMoreResult}){
    setOffset(offset+5)
    if (!fetchMoreResult) return prev
    return Object.assign({}, prev, {
      myPurchases: [...prev.myPurchases, ...fetchMoreResult.myPurchases]
    })
  }

  if (loading) return(
    <div id="orders-history">
      {[...Array(8)].map((_, key) => <OrderSkeleton key={key}/>)}
    </div>
  )

  if (error) return `Error! ${error.message}`

  let { myPurchases } = data

  if (!myPurchases.length > 0) return null

  return(
    <div id="purchase-history">
      <div className="heading no-desc">
        <Title tag="h2"><IntlText group="orders" id="orders-history" /></Title>
      </div>
      <PurchasesList purchases={myPurchases} />
      <div className="load-more">
        <Button theme="main" onClick={() => fetchMore({variables: {limit: 5, offset: myPurchases.length}, updateQuery: fetchMoreUpdate})}><IntlText group="orders" id="load-more" /></Button>
      </div>
    </div>
  )
}

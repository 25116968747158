import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Loader } from '../'

import { pad } from '../../helpers'

import './style.css'

export default function Timer({style, label, time, start, children, onReady}) {

  const [timer, setTimer] = useState(null)

  useEffect(() => {
    let interval

    function updateTimer(){
      if (!time) return false

      let now = moment(start).add(time, "s")
      let future = moment()
      let diff = future - now

      if (diff >= 0){
        setTimer(true)
        if (onReady) onReady()
        return clearInterval(interval)
      }

      let _mins  = Math.floor( diff / (1000*60) )
      let _secs  = Math.floor( diff / 1000 ) - _mins  * 60

      _mins = _secs === 0 ? -_mins : -_mins - 1
      _secs = _secs === 0 ? _secs : 60 - _secs

      setTimer({mins: _mins, secs: _secs})
    }

    interval = setInterval(updateTimer, 1000)

    return () => clearInterval(interval)
  // eslint-disable-next-line    
  }, [time, start])

  if (!time) return <div style={style} className="timer">{children}</div>
  if (!timer) return <div style={style} className="timer"><Loader theme="main" /></div>
  if (timer === true) return <div style={style} className="timer">{children}</div>

  const { mins, secs } = timer
  return(
    <div style={style} className="timer">
      {label}
      <div style={{marginLeft: 5}}>
        {pad(mins)}:{pad(secs)}
      </div>
    </div>
  )
}

import { useState } from 'react'

export function useForm(initialData, validations = undefined) {
  const [form, setValues] = useState(initialData)
  const [status, setStatus] = useState({id: "init", data: {}})
  const [edited, toggleEdited] = useState(false)
  const [editedData, editData] = useState({})
  const [errors, setErrors] = useState({})

  function _editData(key){
    const _edited = {...editedData, [key]: true}
    editData(_edited)
  }

  function getEditedData(){
    let _edited = {}
    for (const [key, value] of Object.entries(editedData)) {
      if (value) _edited[key] = form[key]
    }
    return _edited
  }

  function updateField({key, value}){
    setValues({
      ...form,
      [key]: value
    })
    _editData(key)
    
    let _error = validateField(key, value)

    setErrors({...errors, [key]: _error})

    toggleEdited(true)
  }

  function updateMultilangField({key, value, language}){
    let _value = {...form[key], [language]: value}
    delete _value.__typename
    
    setValues({
      ...form,
      [key]: _value
    })
    _editData(key)
    
    let _error = validateField(key, value)

    setErrors({...errors, [key]: _error})

    toggleEdited(true)
  }

  function updateFields({fields}){
    if (!(fields && fields.length > 0)) return false

    let _fields = {}
    let _errors = {}
    fields.forEach((_field) => {
      _fields[_field.key] = _field.value
      _errors[_field.key] = validateField(_field.key, _field.value)
    })

    setValues({
      ...form,
      ..._fields
    })

    setErrors({
      ...errors,
      ..._errors
    })

  }

  function validateField(key, value){

    if (!validations) return false
    
    if (validations[key] && validations[key].length > 0){
      let _errors = []
      
      validations[key].forEach(({id, validation, skip}) => {
        if (skip && skip(value, form)) return false
        if (!validation(value, form)){
          _errors = _errors.concat(id)
        }
      })

      return _errors.length > 0 ? _errors : false
    }

    return false
  }

  function resetForm(){
    setValues({
      ...initialData
    })
    setErrors({})
    toggleEdited(false)
  }

  function validate(props){
    const {skip = []} = props || {}
    let _errors = {}

    Object.keys(validations).forEach((key) => {
      let _error = validateField(key, form[key] || "")
      if (skip?.includes(key)) return null
      if (_error) _errors[key] = _error
    })

    const valid = !Object.keys(_errors).length > 0
    setErrors(_errors)
    setStatus(valid ? {id: "validated"} : {id: "failed"})

    return {valid: valid, errors: _errors}

  }

  function success(_data){
    setStatus({id: "success", data: _data})
  }
  
  function fail(_error){
    setStatus({id: "failed", data: _error})
  }

  return {
    form,
    updateField,
    updateMultilangField,
    updateFields,
    status,
    edited,
    getEditedData,
    resetForm,
    setValues,
    setStatus,
    errors,
    validate,
    success,
    fail
  }
}

import React, { useContext } from 'react'

import { IntlContext} from '../../contexts'
import { getAsset } from '../../helpers'
import { Title, IntlText, MediaQuery, IconBox, IntlValue, Text } from '../../components'
import { CoverImage, WorkWithUsForm, MoreInfo } from '../../views'

export function BecomePartner({page}) {

  const { getTranslatedValue, getTranslation } = useContext(IntlContext)
  const text = getTranslatedValue(page?.content) || ""
  const icons = getAsset("icons.json")

  return(
    <div id="for-vendors" className="container">
      <CoverImage image={getAsset("restaurants-cover.png")}>
        <div className="row wrapped">
          <div className="call-to-action-form-wrapper">
            <div>
              <Title><IntlValue value={page.title} /></Title>
              <div className="info"><IntlValue value={page.description} /></div>
            </div>
            <MediaQuery min={800}>
              <WorkWithUsForm />
            </MediaQuery>
          </div>
        </div>
      </CoverImage>
      <MediaQuery max={800}>
        <div className="row wrapped call-to-action-form-wrapper">
          <WorkWithUsForm />
        </div>
      </MediaQuery>
      <div className="row wrapped xl">
        <div className="row-header">
          <Title tag="h2"><IntlText group="for-vendors" id="what-we-do" /></Title>
        </div>        
        <IconBox.Box>
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "online-platform"})} image={icons.onlineOrderPlatform} description={getTranslation({group: "for-vendors", id: "online-platform-desc"})} />
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "delivery-service"})} image={icons.deliveryService} description={getTranslation({group: "for-vendors", id: "delivery-service-desc"})} />
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "marketing"})} image={icons.marketing} description={getTranslation({group: "for-vendors", id: "marketing-desc"})} />
        </IconBox.Box>   
      </div>     
      <div className="row wrapped xl">
        <div className="row-header">
          <Title tag="h2"><IntlText group="for-vendors" id="how-it-helps" /></Title>
        </div>        
        <IconBox.Box>
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "flexibility"})} image={icons.growth} description={<IntlText group="for-vendors" id="flexibility-desc" />} />
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "salary"})} image={icons.moneyBag} description={<IntlText group="for-vendors" id="salary-desc" />} />
          <IconBox.Item title={getTranslation({group: "for-vendors", id: "team"})} image={icons.reach} description={<IntlText group="for-vendors" id="team-desc" />} />
        </IconBox.Box>        
      </div>
      {text && 
        <div className="row wrapped xl">
          <Text>{text}</Text>     
        </div>      
      }
      <MoreInfo />
    </div>
  )
}

import React from 'react'
import { useQuery } from '@apollo/client'

import { useIntl } from '../../../hooks'
import { PAGE_QUERY } from '../../../graphql'
import { Title, Text } from '../../../components'

export function ReferralRules(){

  const { getTranslatedValue } = useIntl()
  const { loading, data, error } = useQuery(PAGE_QUERY, {variables: {slug: 'referral-rules'}})
  const title = getTranslatedValue(data?.page?.title)
  const description = getTranslatedValue(data?.page?.description)
  const content = getTranslatedValue(data?.page?.content)

  if (loading || error) return null

  return(
    <div>
      <div className="row-header">
        <div className="heading">
          <Title tag="h2">{title}</Title>
          <div className="desc">{description}</div>
        </div>
      </div>      
      <Title tag='h2'>{}</Title>
      <Text>{content}</Text>
    </div>
  )

}

import React from 'react'

import { useTabs } from './'

export function TabButton({id, children, vertical, defaultTab, onChange}){

  const { selectedTab, changeTab } = useTabs()

  function _changeTab(id){
    changeTab(id)
    onChange && onChange()
  }

  return(
    <div className={`tab-button ${selectedTab === id || (!selectedTab && defaultTab) ? "active" : null} ${vertical ? "vertical" : null}`} onClick={() => _changeTab(id)}>{children}</div>
  )

}

import { gql } from '@apollo/client'

const SETTING_FRAGMENT = gql`
  fragment SETTING_FRAGMENT on Setting {
    id
    key
    description
    type
    value
  }
`

export default SETTING_FRAGMENT

import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { AsYouType } from 'libphonenumber-js'
import FontAwesome from 'react-fontawesome'

import { useForm, useAuth, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { REQUEST_CUSTOMER_VERIFICATION_CODE, CUSTOMER_CEL_VERIFICATIONS_STATUS } from '../../../graphql'
import { Form, Select, IntlText, Button, Loader, Modalv3, Title } from '../../../components'

import { AddPhoneNumber } from './AddPhoneNumber'
import { ActiveVerification } from './ActiveVerification'

import './style.css'

export function VerifyNumber({success}){

    const modal = useModal()
    const [requestVerification, {loading: verificationLoading}] = useMutation(REQUEST_CUSTOMER_VERIFICATION_CODE, {update})
    const [changeMethod, toggleChangeMethod] = useState(false)
    const { user } = useAuth()
    const { getTranslation } = useContext(IntlContext)
    const methods = [{id: "WHATSAPP", label: getTranslation({group: "verify-number", id: "WHATSAPP"})}, {id: "SMS", label: getTranslation({group: "verify-number", id: "SMS"})}]
    const { loading, data } = useQuery(CUSTOMER_CEL_VERIFICATIONS_STATUS) 
    const { form, updateField } = useForm({method: methods[0]})
    const { method } = form
    
    async function update(cache, {data: {requestCustomerVerificationCode}}){
        try {
            cache.writeQuery({
                query: CUSTOMER_CEL_VERIFICATIONS_STATUS,
                data: {customerCelVerificationStatus: requestCustomerVerificationCode},
            })  
        } catch (error) {
            console.log("Cache update error");
        }
    }

    async function verify(){
        const alert = toast.loading()

        try {
            toggleChangeMethod(false)
            await requestVerification({variables:{method: method.id }})
            alert.hide()
        } catch (error) {
            alert.apolloError(error)
        }
    }

    if (loading) return <Loader theme="main" />
    
    if (data?.customerCelVerificationStatus?.inSession && !changeMethod) return(
        <ActiveVerification success={success} status={data?.customerCelVerificationStatus} changeMethod={toggleChangeMethod} />
    )  

    if (!user?.cel) return(
        <AddPhoneNumber />
    )

    return(
        <>
            <Form.Form className='verify-number'>
                <Form.Field className='verify-number-field'>
                    <div>
                        <span className='placeholder'><IntlText group="verify-number" id="your-number" /></span>
                        <div className='phone-number-wrapper'>
                            <span className='phone-number'>+{user?.cel?.phoneCode} {new AsYouType(user?.cel?.countryCode).input(user?.cel?.phoneNumber)}</span>
                            <FontAwesome onClick={() => modal.show()} name="edit" />
                        </div>
                    </div>
                </Form.Field>
                <Form.Field>
                    <Select id="method" selected={method} defaultValue={<IntlText id="nothing-selected" />} placeholder={<IntlText group="verify-number" id="select-method" />} options={methods} onChange={(selected) => updateField({key: "method", value: selected})} />
                </Form.Field>            
                <Form.Field>
                    <Button theme="main" fluid loading={verificationLoading} onClick={verify}><IntlText group="verify-number" id="verify" /></Button>
                </Form.Field>
            </Form.Form>
            <Modalv3 id="edit-phone-number" modal={modal} header={<Title tag="h3"><IntlText group="verify-number" id="change-number" /></Title>}>
                <AddPhoneNumber onUpdated={modal.hide} />
            </Modalv3>
        </>
    )
}

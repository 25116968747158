import { AskChange } from './AskChange'
import { PayInCurrency } from './PayInCurrency'
import { UseBalance } from './UseBalance'

export function CashPaymentOptions({purchaseQuote}) {

  return(
    <div style={{display: 'flex', flexDirection: "column", gap: "15px"}}>
        <UseBalance purchaseQuote={purchaseQuote} />
        <AskChange purchaseQuote={purchaseQuote} />
        <PayInCurrency purchaseQuote={purchaseQuote} />
    </div>   
  )

}

import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export function useTabs() {
  const location = useLocation()
  const navigate = useNavigate()
  const search = useMemo(() => new URLSearchParams(location?.search), [location?.search])
  const [selectedTab, _changeTab] = useState(search.get("selectedTab"))

  useEffect(() => {
    _changeTab(search.get("selectedTab"))
  }, [search])

  function changeTab(selectedTab) {
    search.set("selectedTab", selectedTab)
    navigate({search: search.toString()})
  }

  return {
    changeTab,
    selectedTab
  }
}

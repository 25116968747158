const style = {
  content : {
    left                  : '50%',
    top                   : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    display               : 'inline-block',
    margin                : '0 auto',
    padding               : '0',
    border                : 'none',
    borderRadius          : '5px',
    transform             : 'translate(-50%, 100%)',
    background            : 'transparent'
  }
}

export default style

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Tooltip, IntlText } from '../../../components'

import './style.css'

export function VendorTags({style, vendor: {name, tags = [], slow, bankCardPaymentsAllowed, bankCardPaymentsAccepted}}) {

  tags = tags || []

  return(
    <div style={style} className="vendor-tags">
      {tags.includes("bio") &&
        <div className="vendor-tag">
          <Tooltip className="green" text={<IntlText group="vendor-tags" id="bio" />}><FontAwesome name="recycle" /></Tooltip>
        </div>
      }
      {tags.includes("new") &&
        <div className="vendor-tag">
          <Tooltip className="main" text={<IntlText group="vendor-tags" id="new" />}><FontAwesome name="star" /></Tooltip>
        </div>
      }
      {tags.includes("promotion") &&
        <div className="vendor-tag">
          <Tooltip className="main" text={<IntlText group="vendor-tags" id="promotion" />}><i className="fas fa-badge-percent"></i></Tooltip>
        </div>
      }
      {!!slow &&
        <div className="vendor-tag slow">
          <Tooltip className="warning" text={<IntlText group="vendor-tags" id="slow" />}><FontAwesome name="clock" /></Tooltip>
        </div>
      }
      {bankCardPaymentsAllowed && bankCardPaymentsAccepted &&
        <div className="vendor-tag">
          <Tooltip className="main" text={<IntlText group="vendor-tags" id="credit-card" />}><i className="fa fa-credit-card"></i></Tooltip>
        </div>
      }
    </div>
  )
}

import { useState, useEffect, useRef, useContext } from 'react'
import { AsYouType } from 'libphonenumber-js'
import FontAwesome from 'react-fontawesome'

import { LabeledInput, Button, IntlText  } from '../'
import { IntlContext } from '../../contexts'

import './PhoneNumberInput.css'

import _countries from './countries.json'

export default function PhoneNumberInput({onChange, value, defaultCountry, featuredCountries, name, placeholder, disablePreferences, disabled}) {

  const [showList, toggleList] = useState(false)
  const { getTranslation } = useContext(IntlContext)
  const [search, setSearch] = useState("")
  const [preference, setPreference] = useState(false)
  const [countries, setCountries] = useState(_countries)
  const element = useRef(null)

  useEffect(() => {
    function handleClick(e){
      if (element.current && element.current.contains(e.target)) return false
      toggleList(false)
    }

    document.addEventListener("click", handleClick)

    return () => document.removeEventListener("click", handleClick)
  }, [])

  function onSearch(string){
    let filtered = _countries.filter((country) => country.name.toLowerCase().search(string.toLowerCase()) !== -1 || country.phoneCode.search(string.toLowerCase()) !== -1)

    setSearch(string)
    setCountries(filtered)
  }

  function _onChange(data){
    onChange(data)
    toggleList(false)
  }

  let selectedCountry =  value || _countries.filter((data) => data.countryCode.toLowerCase() === defaultCountry)[0] || countries[0]

  let countriesList = countries.reduce((current, next) => {
    let listItem =
      <li key={`${next.countryCode}`} onClick={() => _onChange({...next, phoneNumber: value?.phoneNumber || ""})}>
        <span className="list-flag"><span className={"flag-icon flag-icon-" + next.countryCode.toLowerCase()}></span></span>
        <span className="list-name">{next.name}</span>
        <span className="list-code">({next.phoneCode})</span>
      </li>

    if (featuredCountries && (featuredCountries.includes(next.countryCode.toLowerCase()) || featuredCountries.includes(next.countryCode))){
      current.featured.push(listItem)
    } else {
      current.normal.push(listItem)
    }

    return current

  }, {featured: [], normal: []})

  if ((!value?.phoneNumber && !preference) && !disablePreferences) return(
    <div className="phone-number-input">
      <div className="user-preference">
        <Button onClick={() => setPreference("whatsapp")}><i className="fab fa-whatsapp"></i><IntlText group="phone-number-input" id="whatsapp" /> <IntlText group="phone-number-input" id="recommended" /></Button>
        <Button onClick={() => setPreference("phone")}><i className="fa fa-phone"></i><IntlText group="phone-number-input" id="phone" /></Button>
      </div>
    </div>
  )

  return(
    <div className="phone-number-input">
      {!disablePreferences &&
        <div className="change-preference">
          {preference === "whatsapp" ?
            <Button onClick={() => setPreference("phone")}><i className="fab fa-whatsapp"></i><IntlText group="phone-number-input" id="whatsapp" /></Button>
            : preference === "phone" ?
            <Button onClick={() => setPreference("whatsapp")}><i className="fa fa-phone"></i><IntlText group="phone-number-input" id="phone" /></Button>
            : null}
        </div>
      }
      <div className={`phone-picker ${disabled ? "disabled" : ""}`} ref={element}>
        <div className="country" onClick={!disabled ? () => toggleList(!showList) : null}>
          <span className={"flag-icon flag-icon-" + selectedCountry.countryCode.toLowerCase()}></span>
          <span className="country-code">{selectedCountry.phoneCode}</span>
          <FontAwesome name="caret-down" />
        </div>
        <LabeledInput disabled={disabled} type="phone" name={name} placeholder={placeholder} value={value?.phoneNumber ? new AsYouType(value?.countryCode).input(value?.phoneNumber) : ""} onChange={(e) => onChange({...selectedCountry, phoneNumber: e.target.value})} />
        <div className={["dropdown", showList && "opened"].join(" ")}>
          <ul className="country-list">
            <div className='search-country'>
              <FontAwesome name="search" />
              <input name="search" type="text" value={search} autoComplete="off" placeholder={getTranslation({group: "phone-number-input", id:"search"})} onChange={(e) => onSearch(e.target.value)}/>
            </div>
            {countriesList.featured}
            {countriesList.featured.length ? <li key="divider" className="divider"></li> : ""}
            {countriesList.normal}
          </ul>
        </div>
      </div>
    </div>
  )
}

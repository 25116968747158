import React, { useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { useQuery } from '@apollo/client'
import parse from 'html-react-parser'

import { Title, CloseButton, Button, IntlText, Loader } from '../../components'
import { IntlContext, ModalContext } from '../../contexts'
import { SETTINGS_QUERY } from '../../graphql'
import { extractSettings } from '../../helpers'

import headerChristmas from './header-christmas-min.jpg'
import headerNewYear from './header-new-year-min.png'
import style from '../../style/modal'

export default function InfoModal() {

  const { selectedLanguage } = useContext(IntlContext)
  const { loading, data } = useQuery(SETTINGS_QUERY, {variables: {keys: ["infomodal", "infomodal_id", "infomodal_image", "infomodal_title", "infomodal_title_es", "infomodal_text", "infomodal_text_es"]}})
  const { openModal, closeModal, isOpened } = useContext(ModalContext)

  useEffect(() => {
    const modalStatus = data?.settings?.find(d => d.key === "infomodal")?.value === "true" || false
    const modal = data?.settings?.find(d => d.key === "infomodal_id") || false
    const modalCacheState = modal ? JSON.parse(localStorage.getItem(modal.value)) || false : false
    if (modalStatus && !modalCacheState) openModal("infoModal")
  // eslint-disable-next-line    
  }, [data])
  
  if (loading) return <Loader theme="main"/>
  
  let settings = extractSettings(data?.settings)
  
  function getImage(_uri){
    switch (_uri) {
      case "christmas":
        return headerChristmas
      case "new-year":
        return headerNewYear
      default:
        return null
    }
  }

  function _closeModal(){
    closeModal()
    if (settings["infomodal_id"]){
      localStorage.setItem(settings["infomodal_id"], JSON.stringify(true))
    }
  }

  if (isOpened("infoModal")) return(
    <Modal isOpen={isOpened("infoModal")} style={style} onRequestClose={closeModal} appElement={document.getElementById('root')}>
      <div id="info-modal" className="modal">
        <CloseButton onClick={closeModal} />
        <div className="modal-info">
          <div className="modal-header">
            <Title tag="h2">{selectedLanguage.shortcode === "en" ? settings["infomodal_title"] : settings["infomodal_title_es"]}</Title>
          </div>
          {settings["infomodal_image"] && getImage(settings["infomodal_image"]) &&
            <div className="header-image">
              <img src={getImage(settings["infomodal_image"])} alt={selectedLanguage.shortcode === "en" ? settings["infomodal_title"] : settings["infomodal_title_es"]} title={selectedLanguage.shortcode === "en" ? settings["infomodal_title"] : settings["infomodal_title_es"]} />
            </div>
          }
          <div className="modal-content">
            <div className="message text">
              {selectedLanguage.shortcode === "en" ? parse(settings["infomodal_text"]) : parse(settings["infomodal_text_es"])}
            </div>
          </div>
          <div className="modal-footer">
            <Button theme="alt" className="full" onClick={_closeModal}><IntlText id="dont-show-again" /></Button>
            <Button theme="main" className="full" onClick={closeModal}><IntlText id="ok" /></Button>
          </div>
        </div>
      </div>
    </Modal>
  )

  return null
}

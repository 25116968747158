import { useState } from 'react'

import { useModal, useEditPurchase } from '../../../hooks'
import { formatPrice } from '../../../helpers'
import { Button, IntlText, RangeSlider, Modalv3, Title, Form, Loader } from '../../../components'

import './style.css'

export function PurchaseTip({purchaseQuote}){

  const modal = useModal()
  const { editPurchaseQuote, loading } = useEditPurchase({})

  function calculateTip(percentage){
    return (percentage/100)*purchaseQuote?.price?.subtotal        
  }    

  return(
    <div id="purchase-tip">
      <div className="tip-slider">
        <Loader loading={loading} theme='main' overlay />
        <div className="buttons">
          <Button onClick={() => editPurchaseQuote({riderTipPercentage: 0})} className={purchaseQuote?.riderTipPercentage === 0 ? "active" : ""}>
            <span>No</span>
            <span className='amount'>{formatPrice(0, true)}</span>            
          </Button>
          <Button onClick={() => editPurchaseQuote({riderTipPercentage: 0.05})} className={purchaseQuote?.riderTipPercentage === 0.05 ? "active" : ""}>
            <span>5%</span>
            <span className='amount'>{formatPrice(calculateTip(5), true)}</span>            
          </Button>
          <Button onClick={() => editPurchaseQuote({riderTipPercentage: 0.1})} className={purchaseQuote?.riderTipPercentage === 0.1 ? "active" : ""}>
            <span>10%</span>
            <span className='amount'>{formatPrice(calculateTip(10), true)}</span>            
          </Button>
          <Button onClick={() => editPurchaseQuote({riderTipPercentage: 0.15})} className={purchaseQuote?.riderTipPercentage === 0.15 ? "active" : ""}>
            <span>15%</span>
            <span className='amount'>{formatPrice(calculateTip(15), true)}</span>            
          </Button>
          <Button className={`custom ${![0,0.05,0.1,0.15].includes(purchaseQuote?.riderTipPercentage) ? "active" : ""}`} onClick={() => modal.show()} >
            <span><IntlText group="purchase-tip" id="custom" /></span>
            <span className='amount'>{formatPrice(calculateTip(purchaseQuote?.riderTipPercentage * 100), true)}</span>            
          </Button>
        </div>
      </div>
      <div className="help"><IntlText group="purchase-tip" id="help" /></div>
      <Modal modal={modal} purchaseQuote={purchaseQuote}  />
    </div>
  )
}

function Modal({modal, purchaseQuote}){

  const [_tip, setTip] = useState(purchaseQuote?.riderTipPercentage)
  const { editPurchaseQuote, loading } = useEditPurchase({})

  function calculateTip(percentage){
    return percentage*purchaseQuote?.price?.subtotal        
  }    

  async function save(){
    await editPurchaseQuote({riderTipPercentage: _tip})
    modal.hide()
  }

  return(
    <Modalv3 id="purchase-tip" modal={modal} header={<Title tag="h3"><IntlText group="purchase-tip" id="title" /></Title>}>
      <Loader loading={loading} theme='main' overlay />
      <Form.Form>
        <Form.Field>
          <span className='tip-amount'>{formatPrice(calculateTip(_tip), true)}</span>
        </Form.Field>
        <Form.Field>
          <RangeSlider value={_tip} onChange={setTip}/>
        </Form.Field>
        <Form.Field>
          <Button fluid theme="main" onClick={save}><IntlText id="save" /></Button>
        </Form.Field>
      </Form.Form>
    </Modalv3>    
  )
}
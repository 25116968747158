import React from 'react'

import { SlideIn, Navigation, NavItem, IntlText } from '../../components'

import './LeftMenu.css'

export default function LeftMenu() {
  return(
    <SlideIn id="left">
      <Navigation id="main-menu">
        <NavItem target="/" icon="home" info={<IntlText group="main-menu" id="home-desc" />}><IntlText group="main-menu" id="home" /></NavItem>
        <NavItem target="/delivery-service" icon="utensils" info={<IntlText group="main-menu" id="delivery-desc" />}><IntlText group="main-menu" id="delivery" /></NavItem>
        <NavItem target="/se-un-repartidor-con-tomato" icon="motorcycle" info={<IntlText group="main-menu" id="work-desc" />}><IntlText group="main-menu" id="work" /></NavItem>
        <NavItem target="/for-restaurants-and-shops" icon="handshake" info={<IntlText group="main-menu" id="partner-desc" />}><IntlText group="main-menu" id="partner" /></NavItem>
        <NavItem target="/faq" icon="life-ring" info={<IntlText group="main-menu" id="support-desc" />}><IntlText group="main-menu" id="support" /></NavItem>
      </Navigation>
    </SlideIn>
  )
}

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { getAsset } from '../../../helpers'

import './style.css'

export function CustomerBalance({customerBook}) {

  const icon = getAsset("icon.png")

  return(
    <div className="customer-balance">
      <div className="balance">
        <FontAwesome className="icon" name="dollar-sign" />
        <span className="value">{customerBook?.cashBalance || 0}</span>
      </div>
      {false &&
        <div className="balance">
          <img alt="tomato points" src={icon} className="icon" />
          <span className="value">{customerBook?.tomatoPoints || 0}</span>
        </div>
      }
    </div>
  )
}

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { useAuth } from '../../../hooks'
import { formatPrice } from '../../../helpers'
import { Title, IntlText } from '../../../components'

import './style.css'

export function ReferralStats(){

  const { loading, user, error } = useAuth()

  if (loading || error) return null

  return(
    <div>
      <Title tag="h3" styled><IntlText group="referred-customers" id="referral-stats"/></Title>
      <div className='referral-stats'>
        <div className='referral-stat'>
          <FontAwesome name='star' />
          <h5><IntlText group="referred-customers" id="referrerLevel"/></h5>
          <span className='value'>{user?.referrerLevel}</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='percent' />
          <h5><IntlText group="referred-customers" id="referrerPercentage"/></h5>
          <span className='value'>{user?.referrerPercentage*100}%</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='shopping-cart' />
          <h5><IntlText group="referred-customers" id="referrerPurchasesCount"/></h5>
          <span className='value'>{user?.referrerPurchasesCount}</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='sack-dollar' />
          <h5><IntlText group="referred-customers" id="totalReferrerIncome"/></h5>
          <span className='value'>{formatPrice(user?.totalReferrerIncome || 0)}</span>
        </div>
      </div>
    </div>
  )
}
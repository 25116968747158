import { gql } from '@apollo/client'

import { CATEGORY_FRAGMENT } from '../fragments'
import { PRODUCTS_FRAGMENT } from '../product'

const PRODUCT_LIST_QUERY = gql`
  query productList($vendor: String!){
    productList(vendor: $vendor){
      category{
        ...CATEGORY_FRAGMENT
      }
      products{
        ...PRODUCTS_FRAGMENT
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${PRODUCTS_FRAGMENT}
`

export default PRODUCT_LIST_QUERY

import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useQueryBuilder } from '../../hooks'
import { IntlText, Filters, SegmentedControl } from '../../components'
import { PartnersSearch, ProductSearch, VendorFilters, IconFilter, ProductFilters } from '../../views'

export function Search() {

  const location = useLocation()
  const navigate = useNavigate()
  const search = new URLSearchParams(location.search)
  const [searchType, setSearchType] = useState(search.get("searchType") || "restaurants")
  const { params, removeParam, removeParams, clearParams, isQuery, buildQuery, updateQuery } = useQueryBuilder({
    data: {
      term: {key: "term", value: search.get("term"), skip: !search.get("term")},
      highRating: {key: "highRating", value: (search.get("highRating") === 'true')},
      mustAcceptCard: {key: "mustAcceptCard", value: (search.get("mustAcceptCard") === 'true')},
      query: {key: "query",  value: search.get("query")}
    }
  })
  const activeFilters = Object.keys(params).length === 0

  useEffect(() => {
    const _value = search.get("query")
    if (activeFilters) {
      return navigate('/delivery-service')
    }
    if (!!_value) {
      updateQuery({query: {value: _value, id: "search"}}) 
    } else {
      removeParam("query")
    }
    // eslint-disable-next-line
  }, [search.get("query"), activeFilters])

  function _setSearchType(_type){
    removeParams(["sortBy"])
    search.set("searchType", _type)
    setSearchType(_type)
    navigate({search: search.toString(), replace: true})
  }

  return(
    <div id="search-results" className="container">
      <IconFilter onSelect={(tag) => updateQuery({query: {key: "query", value: tag}})} selected={params?.query?.value} reset={() => removeParam("query")} />
      <div id="menu-filter">
        <div className="row">
          <Filters.Bar>
            <SegmentedControl value={searchType} options={[{id: "restaurants", label: "Restaurants"}, {id: "products", label: "Products"}]} onClick={(_option) => _setSearchType(_option.id)}/>         
            {searchType === "products" ? 
              <ProductFilters params={params} removeParam={removeParam} updateQuery={updateQuery}/>
            :
              <VendorFilters params={params} removeParam={removeParam} updateQuery={updateQuery}/>
            }
          </Filters.Bar>
        </div>
      </div>
      <div className="row padding">
        {searchType === "products" ?
          <ProductSearch isQuery={isQuery} buildQuery={buildQuery} clearParams={clearParams} />
        :
          <PartnersSearch isQuery={isQuery} buildQuery={buildQuery} clearParams={clearParams} />
        }
      </div>
      <div className="row padding">
        <div id="conditions">
          <p><IntlText group="conditions" id="about-tomato" /></p>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import parse from 'html-react-parser'

import { ModalContext, IntlContext } from '../../../contexts'
import { FAQS } from '../../../graphql'
import { IntlValue, IntlText, Modal, Loader, Button, WhatsAppHelp } from '../../../components'

import './style.css'

export function OrderHelpModal() {

  const { modals } = useContext(ModalContext)
  const { getTranslatedValue } = useContext(IntlContext)
  const [selected, select] = useState(null)
  const { loading, data, error } = useQuery(FAQS, {variables: {topic: "CUSTOMER_PURCHASE"}})

  if (loading) return <Loader theme="main" />
  if (error) return `Error! ${error.message}`

  const { faqItems } = data

  return(
    <Modal id="order-help" ref={modals?.orderHelp} header={<ModalHeader />} >
      {({state}) => {

        if (selected) return(
          <div className="help-modal-selected">
            <div className="help-modal-faq-item">
              <div className="title">
                <FontAwesome name="question-circle" />
                <IntlValue>{selected?.title}</IntlValue>
              </div>
              <div className="content">
                {parse(getTranslatedValue(selected?.content))}
              </div>
              <div className="actions">
                <div><Button style={{width: "100%"}} theme="alt" onClick={() => select(null)}><IntlText id="back" /></Button></div>
                {selected?.whatsappButton && <div><WhatsAppHelp style={{width: "100%"}} /></div>}
              </div>
            </div>
          </div>
        )

        return(
          <div>
            {faqItems.map((faqItem, key) =>
              <div key={key} className="help-modal-faq-item" onClick={() => select(faqItem)}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <div><FontAwesome name="question-circle" /></div>
                  <span><IntlValue>{faqItem?.title}</IntlValue></span>
                </div>
              </div>
            )}
          </div>
        )
      }}
    </Modal>
  )

}

function ModalHeader(){
  return(
    <div className="modal-title">
      <h1 className="title"><IntlText group="order-help-modal" id="title" /></h1>
    </div>
  )
}

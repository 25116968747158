import React, { useContext } from 'react'
import parse from 'html-react-parser'

import { IntlValue } from '../../../components'
import { IntlContext } from '../../../contexts'

import './style.css'

export function FaqItem({faq}){
  const { getTranslatedValue } = useContext(IntlContext)

  return(
    <div className="faq-item">
      <div className="question"><i className="fas fa-question-circle"></i><IntlValue>{faq.title}</IntlValue></div>
      <div className="answer">
        <span>{parse(getTranslatedValue(faq.content))}</span>
      </div>
    </div>
  )
}

import { AsYouType } from 'libphonenumber-js'

export function parsePhone(_data){
    if (!_data) return undefined

    const { countryCode, phoneCode, phoneNumber } = _data

    const _phone = {
      countryCode: countryCode,
      phoneCode: phoneCode,
      phoneNumber: new AsYouType(countryCode).input(phoneNumber)
    }

    return _phone
  }
import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const QUERIED_VENDORS_LIST = gql`
  query queriedVendorList($query: String, $term: VENDOR_LIST_TERM, $mustAcceptCard: Boolean, $highRating: Boolean, $offset: Int, $limit: Int){
    queriedVendorList(query: $query, term: $term, mustAcceptCard: $mustAcceptCard, highRating: $highRating, offset: $offset, limit: $limit){
      nodes{
        ...VENDOR_FRAGMENT
      }
      pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage
      }
    }
  }
  ${VENDOR_FRAGMENT}
`

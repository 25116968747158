import { useState, useEffect } from 'react'

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export function useDetectOutsideClick({wrapper, menu, initialState, disableOutclick, disableInClickClose, callback}){
  const [isActive, setIsActive] = useState(initialState)

  useEffect(() => {
    function handleClick(e){
      if (!isActive || !wrapper?.current) return
      if ((wrapper?.current.contains(e.target) && (!menu?.current?.contains(e.target) || disableInClickClose)) || disableOutclick) return

      setIsActive(false)
      if (callback) callback()
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  // eslint-disable-next-line
  }, [wrapper, menu, isActive, disableOutclick, disableInClickClose])

  return [isActive, setIsActive]
}

import React, { useEffect, useContext, useRef } from 'react'

import { SlideInContext } from '../../contexts'

import './SlideIn.css'

export default function SlideInMenu({id, side = "left", children}) {

  const { closeMenu, isOpened, isAnyOpened } = useContext(SlideInContext)
  const element = useRef(null)
  const _isOpen = isOpened(id)

  useEffect(() => {
    const handleClick = (e) => {
      if (!element.current || element.current.contains(e.target)) return false
      if (document.body.classList.contains("ReactModal__Body--open")) return false
      if (isOpened(id) && !e.target.classList.contains("slide-in-button")) {
        closeMenu()
      }
    }

    document.addEventListener("mouseup", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
// eslint-disable-next-line
  }, [_isOpen])

  useEffect(() => {
    const overlay = document.getElementById("overlay")

    if (isAnyOpened()) {
      document.body.classList.add("modal-opened")
      overlay.classList.add("visible")
    } else {
      document.body.classList.remove("modal-opened")
      overlay.classList.remove("visible")
    }
    
  })

  return(
    <div id={id} className={`slide-in ${side} ${isOpened(id) ? "opened" : ""}`} ref={element}>
      {children}
    </div>
  )
}

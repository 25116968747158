import React from 'react'

import { IntlText } from '../components'

export function toTitle(string) {
  if (!string) return null
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function orderStatus(status){
  switch (status) {
    case "INQUEUE":
    case "PROCESSED":
      return <IntlText group="order-status" id="inqueue" />
    case "READYFORCOLLECTION":
    case "INPREPARATION":
      return <IntlText group="order-status" id="inpreparation" />
    case "ONTHEWAY":
      return <IntlText group="order-status" id="ontheway" />
    case "ATLOCATION":
      return <IntlText group="order-status" id="atlocation" />
    case "COMPLETED":
      return <IntlText group="order-status" id="completed" />
    case "DELIVERYFAILED":
      return <IntlText group="order-status" id="delivery-failed" />
    case "CANCELLED":
      return <IntlText group="order-status" id="cancelled" />
    case "REJECTED":
      return <IntlText group="order-status" id="cancelled" />
    case "UNPROCESSED":
      return <IntlText group="order-status" id="unprocessed" />
    case "PAYMENTFAILED":
      return <IntlText group="order-status" id="payment-failed" />
    case "REQUIRES_PAYMENT":
      return <IntlText group="order-status" id="requires-payment" />
    default:
      return <IntlText group="order-status" id="invalid-status" />
  }
}

import { gql } from '@apollo/client'

export const CUSTOMER_FRAGMENT = gql`
  fragment CUSTOMER_FRAGMENT on Customer {
    id
    name
    email
    balance
    cardPaymentBlocked
    cardPaymentBlockedReason
    lastChangelogReview
    tags
    phone{
      countryCode
      phoneCode
      phoneNumber
    }
    cel{
      countryCode
      phoneCode
      phoneNumber
    }
    facebookID
    celUid
    celVerificationStatus
    celVerifiedAt
    emailVerified
    verified
    abusive
    banned
    stripeCustomerId
    subscribedToNewsletter
    referralTermsAccepted   
    referrerCode
    referrerPurchasesCount
    referrerLevel
    referrerPercentage
    totalReferrerIncome
  }
`

export function getAsset(assetName){
    try {
        const assets = require.context('../assets', true)
        return assets(`./${process.env.REACT_APP_APP_NAME || "tomato"}/${assetName}`)
    } catch (error) {
        return false
    }
}

export function getCommonAsset(assetName){
    try {
        const assets = require.context('../assets', true)
        return assets(`./${assetName}`)
    } catch (error) {
        return false
    }
}
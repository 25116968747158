export function home(target = ""){
  return `${process.env.PUBLIC_URL}/` + target
}

export function getLocationFromGoogleMapUrl(url){
  url = url.match(/(-?\d+\.\d+),(-?\d+\.\d+),(\d+\.?\d?)+z/g);

  if (!url || !url.length > 0)
    return false;

  url = url[0].split(",")

  let location = {lat: parseFloat(url[0]), lng: parseFloat(url[1])}

  return location;
}

export function isValidUrl(urlString){
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
}
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { PAGINATED_VENDORS_LIST } from '../../graphql'
import { IntlContext } from '../../contexts'
import { Title, IntlText, Button, Slider } from '../../components'
import { PartnerCardSkeleton, FeaturedCard } from '../../views'

import './style.css'

export function FeaturedPartners(){
    
    const { getTranslation } = useContext(IntlContext)
    const { loading, data, error } = useQuery(PAGINATED_VENDORS_LIST, {variables: {type: "FEATURED", connectionArgs: {first: 50}}})

    if (loading || error) return(
        <div className="partner-carousel">
            <Slider.Slider>
                {[...Array(4)].map((data, key) => 
                    <Slider.Slide key={key}>
                        <PartnerCardSkeleton key={key}/>
                    </Slider.Slide>
                )}
            </Slider.Slider>
        </div>
    )    

    const { paginatedVendorList } = data

    if (!paginatedVendorList?.edges?.length > 0) return null

    return(
        <div id="featured-row">
            <div className="row wrapped">
                <div className="row-header">
                    <div className="heading no-desc">
                        <Title tag="h2"><IntlText group="home" id="featured-title" /></Title>
                    </div>
                </div>
                <Slider.Slider>
                    {paginatedVendorList?.edges?.map(({node: vendor}) => 
                        <Slider.Slide key={vendor.id}>
                            <picture>
                                {/* {<source srcSet={getImageInSize(item.cover, "150", "webp")} type="image/webp" />
                                <source srcSet={getImageInSize(item.cover, "150", "png")} type="image/png" />} */}
                                <FeaturedCard key={vendor.slug} vendor={vendor} />
                            </picture>   
                        </Slider.Slide>
                    )}
                </Slider.Slider>                    
                <div className='buttons'>
                    <Button theme="main"><Link to="/delivery-service" title={getTranslation({group:"home", id:"main-button-title"})}><IntlText group="home" id="main-button" /></Link></Button>
                </div>
            </div>
        </div>        
    )
}
import React from 'react'

import { IntlText, WhatsAppHelp } from '../../components'

import './HelpBox.css'

export default function HelpBox({label, style}) {
  return(
    <div className="help-box" style={style}>
      <div className="label">
        <i className="fas fa-user-headset"></i>
        {label ? label : <IntlText group="help-box" id="help" />}
      </div>
      <div className="buttons">
        <WhatsAppHelp />
        {/*
          <span><IntlText id="or">or</IntlText></span>
          <Button id="facebook" onClick={() => window.open("https://www.messenger.com/t/1555876991168163/")}><i className="fab fa-facebook-messenger"></i>Facebook</Button>

        */}
      </div>
    </div>
  )
}

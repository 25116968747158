import { gql } from '@apollo/client'

const REQUEST_CONTACT_MUTATION = gql`
  mutation requestContact($data: ContactRequestInput!){
    requestContact(data: $data){
      success
    }
  }
`

export default REQUEST_CONTACT_MUTATION

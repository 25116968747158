import { gql } from "@apollo/client"

import { CUSTOMER_FRAGMENT } from '../'

const LOGIN_MUTATION = gql`
  mutation login($uid: String!, $password:String!){
    login(uid: $uid, password:$password, role:CUSTOMER){
      token
      user{...CUSTOMER_FRAGMENT}
    }
  }
  ${CUSTOMER_FRAGMENT}
`

export default LOGIN_MUTATION

import React, { useState, useEffect, useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../../contexts'
import { IntlValue, Filters, MediaQuery } from '../../../components'

import './style.css'

export function ProductTagsFilter({params, removeParam, updateQuery, padded}){

  const [string, setString] = useState()
  const { getTranslation } = useContext(IntlContext)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (string) updateQuery({query: {value: string, id: "search"}})
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  // eslint-disable-next-line
  }, [string])  

  const _tags = {
    vegetarian: {img: <FontAwesome className="vegetarian" name="leaf" />, tooltip: {en: "vegetarian", es: "vegetariana"}, color: "valid"},
    vegan: {img: <span className="vegan">V</span>, tooltip: {en: "vegan", es: "vegana"}, color: "valid"},
    spicy: {img: <i className="fas fa-pepper-hot spicy"></i>, tooltip: {en: "spicy", es: "picante"}, color: "invalid"},
    new: {img: <FontAwesome name="star" />, tooltip: {en: "new", es: "nuevo"}, color: "invalid"},
    houseSpeciality: {img: <i className="fas fa-hat-chef houseSpeciality"></i>, tooltip: {en: "house speciality", es: "especialidad de la casa"}, color: "invalid"},
    onsale: {img: <i className="fas fa-badge-percent" />, tooltip: {en: "promotion", es: "promoción"}, color: "invalid"},
    glutenFree: {img: <i className="fas fa-wheat glutenFree"></i>, tooltip: {en: "gluten free", es: "sin gluten"}},
    longPreparation: {img: <FontAwesome name="clock" />, tooltip: {en: "long preparation", es: "larga preparación"}},
  }

  function onSearch(e){
    if (e.key !== 'Enter') return false
    updateQuery({query: {value: string, id: "search", skip: !string}})
  }  

  function _removeParam(_val){
    setString("")
    removeParam(_val)
  }

  function _onChange({target}){
    if (target.value === "") removeParam("query")
    setString(target.value)
  }

  return(
    <div id="products-filter">
      <MediaQuery max={1200}>
        <div className='row wrapped no-margin' style={{marginTop: 10}}>
          <Filters.Input placeholder={getTranslation({group: "products-filter", id: "search-input"})} value={string} onChange={_onChange} onKeyDown={onSearch} reset={() => _removeParam("query")} />
        </div>      
      </MediaQuery>
      <MediaQuery min={1200}>
        <div>
          <Filters.Input placeholder={getTranslation({group: "products-filter", id: "search-input"})} value={string} onChange={_onChange} onKeyDown={onSearch}  reset={() => _removeParam("query")}/>
        </div>
      </MediaQuery>
      <Filters.Bar className={`product-tags-filter ${padded ? "padded" : ""}`}>
        {Object.keys(_tags).map((tag, key) =>
          <Filters.Button key={key} onClick={() => updateQuery({tags: {key: "tags", value: [tag]}})} active={params?.tags?.value?.includes(tag)} reset={() => removeParam("tags")}>
            <div key={key} className={`product-tag ${_tags[tag].color || ""}`}>
              {_tags[tag].img}<IntlValue value={_tags[tag].tooltip} />
            </div>
          </Filters.Button>
        )}
      </Filters.Bar>
    </div>
  )

}

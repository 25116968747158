import React from 'react'

import { Button, IntlText } from '../../components'

import './style.css'

export function QueryResults({pageInfo, reset}){
  if (!pageInfo) return null

  return(
    <div className="query-results">
      <span className='totals'><IntlText id="total-results" variables={{total: pageInfo?.total}} /></span>
      {reset &&
        <Button style={{marginLeft: "auto"}} theme="alt" onClick={reset} ><IntlText id="reset" /></Button>
      }
    </div>
  )

}

import { useEffect, useState } from 'react'

export function usePlatformDetect(){

    const [platform, setPlatform] = useState(null)

    useEffect(() => {
        function getOS() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                return "Android";
            }
        
            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
        
            return "unknown";
        }

        switch (getOS()) {
            case "Android":
                setPlatform('android')
                break;
            case "iOS":
                setPlatform('ios')
                break;
            default:
                break;
            }
    }, [])    

    return platform
}
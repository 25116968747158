import { gql } from '@apollo/client'

export const AVAILABLE_PAYMENT_METHODS_OF_VENDOR = gql`
  query availablePaymentsMethodsOfVendor($id: ID, $slug: String){
    availablePaymentsMethodsOfVendor(id: $id, slug: $slug){
        stripePaymentAvailable
        cashPaymentAvailable
        networkTransactionsDisabledForVendor
    }
  }
`

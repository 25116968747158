import React, { useState, useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { useForm } from '../../hooks'
import { FBKit, AuthKit, validatePhonenumber, validateEmail, parsePhone } from '../../helpers'
import { LOGIN_MUTATION, REGISTER_MUTATION, FACEBOOK_LOGIN_MUTATION, GET_FACEBOOK_DATA, IS_LOGGED_IN } from '../../graphql'
import { IntlContext } from '../../contexts'
import { Button, FacebookLoginButton, LabeledInput, PhoneNumberInput, Validation, IntlText, Checkbox, Form } from '../../components'
import { HelpBox } from '../../views'

import './style.css'

const validations = {
  name: [{id: "required", validation: (val) => !!val}],
  facebookID: [{id: "required", validation: (val) => !!val}],
  email: [{id: "required", validation: (val) => !!val}, {id: "email", validation: (val) => validateEmail(val)}],
  phone: [{id: "required", validation: (val) => !!(val?.phoneNumber && val?.phoneCode)}, {id: "incorrectNumber", validation: (val => validatePhonenumber(val))}],
  password: [{id: "required", validation: (val) => !!val}],
  password2: [{id: "required", validation: (val) => !!val}, {id: "notEqual", validation: (val, {password}) => !!(val === password)}],
}

export function RegisterForm() {

  const referral = localStorage.getItem('referralCode')
  const [loading, toggleLoading] = useState(false)
  const [accepted, accept] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [register] = useMutation(REGISTER_MUTATION, {onError: (error) => {
    if (error?.message === "Invalid referral code"){
      updateFields({fields: [{key: "linkHasReferral", value: false}, {key: "referralSource", value: undefined}]})
    }
    throw error
  }})
  const [login] = useMutation(LOGIN_MUTATION, {update: (cache, { data: { login: { token, user } } }) => updateStore(cache, user, token)})
  const [facebookLogin] = useMutation(FACEBOOK_LOGIN_MUTATION, {update: (cache, { data: { facebookLogin: { token, user } } }) => updateStore(cache, user, token)})
  const { data } = useQuery(GET_FACEBOOK_DATA)
  const { getTranslation } = useContext(IntlContext)
  const { form, updateField, updateFields, errors, validate } = useForm({subscribedToNewsletter: true, referralSource: referral, linkHasReferral: !!referral}, validations)
  const { name, email, password, password2, phone, facebookID, subscribedToNewsletter, referralSource, useReferal, linkHasReferral } = form

  useEffect(() => {
    if (data?.facebookID){
      updateFields({fields: [
        {key: "name", value: data?.name},
        {key: "email", value: data?.email},
        {key: "facebookID", value: data?.facebookID}
      ]})
    }
// eslint-disable-next-line
  }, [data])

  async function submit(){

    const { valid } = validate({skip: ["facebookID"]})
    setSubmitted(true)
    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    toggleLoading(true)

    try {
      await _register({password})
      await login({variables:{uid: email, password: password}})
    } catch (e) {
      e.graphQLErrors?.map(x => toast.error(x.message))
      toggleLoading(false)
    }
  }

  async function submitFacebook(){
    const { valid } = validate({skip: ["password", "password2"]})

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    toggleLoading(true)

    try {
      await _register({facebookID})
      await facebookLogin({variables:{email, facebookID}})
    } catch (e) {
      e.graphQLErrors?.map(x => toast.error(x.message))
      toggleLoading(false)
    }
  }

  async function _register(data){
    const _data = {
      name,
      email,
      cel: phone,
      subscribedToNewsletter,
      referralSource,
      ...data
    }

    await register({variables:{data: _data, onCompleted: () => {
      localStorage.removeItem('referralCode')
    }}})
  }

  function updateStore(cache, user, token){
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: { isLoggedIn: !!user?.id},
    })

    AuthKit.login(user, token)
  }

  return(
    <div id="register-form">
      {!data?.facebookID &&
        <div id="social-logins">
          <FacebookLoginButton onClick={() => FBKit.register()}><IntlText group="register-form" id="facebook-login" /></FacebookLoginButton>
          <span className="help"><IntlText group="register-form" id="create-profile" /></span>
        </div>
      }
      <Form.Form>
        <Validation errors={errors.name}>
          <LabeledInput name="name" placeholder={getTranslation({id: "input-name"})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Validation>
        <Validation errors={errors.email}>
          <LabeledInput name="email" placeholder={getTranslation({id: "input-uid"})} value={email} onChange={({target}) => updateField({key: target.name, value: target.value.toLowerCase().replace(/\s/g, '')})} />
        </Validation>
        {!data?.facebookID &&
          <>
            <Validation errors={errors.password}>
              <LabeledInput name="password" placeholder={getTranslation({id: "input-password"})} type="password" value={password} onChange={({target}) => updateField({key: target.name, value: target.value})} />
            </Validation>
            <Validation errors={errors.password2}>
              <LabeledInput name="password2" placeholder={getTranslation({id: "input-password-confirm"})} type="password" value={password2} onChange={({target}) => updateField({key: target.name, value: target.value})} />
            </Validation>
          </>
        }
        <Validation errors={errors.phone}>
          <PhoneNumberInput name="phone" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(phone)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "phone", value: _data})}/>
        </Validation>
        {!linkHasReferral &&
          <Form.Field className="referal">
            <Checkbox id="useReferal" checked={!!useReferal} onChange={() => updateField({key: "useReferal", value: !useReferal})}>
              <IntlText group="referal" id="sign-up" />
            </Checkbox>
            {useReferal &&
              <LabeledInput name="referralSource" placeholder={getTranslation({id: "input-referral"})} value={referralSource} onChange={({target}) => updateField({key: target.name, value: target.value})} />
            }
          </Form.Field>
        }
        <div className="newsletter">
          <Checkbox id="newsletter" checked={subscribedToNewsletter} onChange={() => updateField({key: "subscribedToNewsletter", value: !subscribedToNewsletter})}>
            <IntlText group="newsletter" id="sign-up" />
          </Checkbox>
        </div>
        <div className="terms">
          <Checkbox id="terms" checked={accepted} onChange={() => accept(!accepted)}>
            <IntlText group="register-form" id="warning" />
            <Link className="link" to="/privacy-policy"><span><IntlText group="register-form" id="privacy-policy" /></span></Link>
            <IntlText group="register-form" id="and" />
            <Link className="link" to="/terms-of-service"><span><IntlText group="register-form" id="terms" /></span></Link>
            <IntlText group="register-form" id="to-continue" />
          </Checkbox>
        </div>
        <div className="actions">
          {data?.facebookID ?
            <Button disabled={!accepted} loading={loading} theme="main" onClick={() => submitFacebook(data)}><IntlText group="register-form" id="register-facebook-button" /></Button>
          :
            <Button disabled={!accepted} loading={loading} theme="main" className="full" onClick={submit}><IntlText group="register-form" id="register-button" /></Button>
          }
        </div>
        {errors && Object.keys(errors).length > 0 && submitted &&
          <HelpBox />
        }
      </Form.Form>
    </div>
  )
}

import Modal from 'react-modal'
import FontAwesome from 'react-fontawesome'
import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { PRODUCT_QUERY } from '../../../graphql'
import { scrollTo, calculateItemPrice, formatPrice } from '../../../helpers'
import { useAuth } from '../../../hooks'
import { IntlContext, CartContext, ModalContext } from '../../../contexts'
import { ProductAddon, ProductTags } from '../../../views'
import { Loader, Title, IntlValue, CloseButton, Button, QuantitySelector, Select, IntlText, LabeledTextarea } from '../../../components'

import style from '../../../style/modal'
import './style.css'

export function ProductModal() {

  const navigate = useNavigate()
  const initialOptions = {quantity: 1, addons: [], alternative: "SENDWITHOUTITEM"}
  const [options, setOptions] = useState(initialOptions)
  const { addons, alternative, instructions, quantity } = options
  const { getTranslation } = useContext(IntlContext)
  const { isOpened, closeModal, attributes } = useContext(ModalContext)
  const { addToCart } = useContext(CartContext)
  const _isOpen = isOpened("productModal")
  const { loading, data, error } = useQuery(PRODUCT_QUERY, { variables: {id: attributes.id}, skip: !attributes.id})

  function updateOption(option){
    setOptions({
      ...options,
      ...option
    })
  }

  useEffect(() => {
    setOptions(initialOptions)
    // eslint-disable-next-line
  }, [_isOpen])

  function _closeModal(){
    setOptions(initialOptions)
    closeModal("productModal")
  }

  function _addToCart(product){
    let _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity

    if (validateAddons(product)){
      addToCart({product, quantity: _quantity, addons, alternative, instructions}, attributes.vendor)
      _closeModal()
      if (attributes?.showVendor) navigate(`/${attributes?.vendor.slug}`)
      toast.success(getTranslation({group: "product-modal", id: "success"}))
    }
  }

  function increaseQty(product){
    let _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity
    let max = product.maxPurchaseQuantity || undefined
    _quantity++

    if (max && max < _quantity) return toast.error(getTranslation({group: "product-modal", id: "max-qty"}))

    updateOption({quantity: _quantity})
  }

  function decreaseQty(product){
    let _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity
    let min = product.minPurchaseQuantity || 1
    _quantity--

    if (_quantity < min) return toast.error(getTranslation({group: "product-modal", id: "min-qty"}))

    updateOption({quantity: _quantity})
  }

  function addOption(addon, option, qty = 1){
    let selectedAddon = {...(addons.find(a => a.addon.id === addon.id) || {addon, selectedOptions: []})}
    const length = selectedAddon.selectedOptions.reduce((prev, current) => {
      return prev + (current.quantity || 1)
    }, 0)
    const { maxSelect } = addon

    if (maxSelect && length >= maxSelect){
      if (maxSelect === 1){
        selectedAddon["selectedOptions"] = [{...option, quantity: qty}]
      } else{
        return toast.error(getTranslation({group: "product-modal", id: "addon-max-reached"}))
      }
    } else {
      selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id).concat({...option, quantity: qty})
    }

    const _addons = addons.filter(obj => obj.addon.id !== addon.id).concat(selectedAddon)
    var target = document.getElementById(addon.id)
    target.classList.remove("active")

    updateOption({addons: _addons})   
  }

  function removeOption(addon, option, qty = 0){

    let selectedAddon = {...(addons.find(a => a.addon.id === addon.id) || {addon, selectedOptions: []})}
    
    if (qty >= 0){
      if (qty === 0) { 
        selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id)
      } else {  
        selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id).concat({...option, quantity: qty})
      }
    }

    const _addons = addons.filter(obj => obj.addon.id !== addon.id).concat(selectedAddon)

    updateOption({addons: _addons})
  }

  function validateAddons(product){
    let valid = true

    product.addons.some((addonObj) => {
      let { addon } = addonObj
      let { maxSelect, minSelect, required, disabled } = addon

      if (disabled) return false

      let selectedAddon = addons.find(_addon => _addon.addon.id === addon.id) || {options: {}, selectedOptions: []}
      const length = selectedAddon.selectedOptions.reduce((prev, current) => {
        return prev + (current.quantity || 1)
      }, 0)

      if (required && !length > 0){
        valid = false
        return addonError(addon, getTranslation({group: "product-modal", id: "addon-required"}))
      }

      if (minSelect && length < minSelect){
        valid = false
        return addonError(addon, getTranslation({group: "product-modal", id: "addon-choose-more"}))
      }

      if (maxSelect && length > maxSelect){
        valid = false
        return addonError(addon, getTranslation({group: "product-modal", id: "addon-choose-less"}))
      }

      return false
    })

    return valid

  }

  function addonError(addon, message){
    var wrapper = document.getElementById('product-modal-scrollbox')
    var target = document.getElementById(addon.id)

    target.classList.add("active")
    toast.error(message)

    return scrollTo(wrapper, target, 10, 600)
  }


    if (loading || error) return(
      <div style={{position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999}}>
        <Loader theme="main" overlay darkOverlay />
      </div>
    )

    return(
      <Modal isOpen={isOpened("productModal")} style={style} onRequestClose={() => _closeModal()} appElement={document.getElementById('root')}>
        <div id="product-modal" className='modal'>
          <CloseButton onClick={() => _closeModal()}/>
          <Product product={data?.product} addToCart={_addToCart} quantity={quantity} increaseQty={increaseQty} decreaseQty={decreaseQty} addons={addons} alternative={alternative} attributes={attributes} addOption={addOption} removeOption={removeOption} updateOption={updateOption} instructions={instructions} options={options}/>
        </div>
      </Modal>
    )
}

function Product({product, addToCart, quantity, increaseQty, decreaseQty, addons, alternative, attributes, addOption, removeOption, updateOption, instructions, options}){  

  const { getTranslation } = useContext(IntlContext)
  const { user } = useAuth()  
  const soldOutActions = [
    {id: "MERCHANTRECOMMENDATION", label: <IntlText group="product-modal" id="sold-out-merchant-recommendation" />},
    {id: "SENDWITHOUTITEM", label: <IntlText group="product-modal" id="sold-out-refund" />},
    {id: "CONTACTCUSTOMER", label: <IntlText group="product-modal" id="sold-out-contact" />},
    {id: "CANCELPURCHASE", label: <IntlText group="product-modal" id="sold-out-cancel" />}
  ]
  
  if (!product) return <NotFound />
  
  const _quantity = product?.minPurchaseQuantity && product?.minPurchaseQuantity > quantity ? product?.minPurchaseQuantity : quantity
  const _addons = product.addons.map((addon, key) =>
    <ProductAddon key={key} addon={addon} selected={addons.find(_addon => _addon.addon.id === addon.addon.id) || []} addOption={addOption} removeOption={removeOption}/>
  )
  
  return(
    <div className="product-modal-info modal-info">
      <div id="product-modal-scrollbox" className="product-modal-content">
        {product?.media?.cover && <div className="product-modal-cover" style={{backgroundImage: `url(${product.media.cover})`}}></div>}
        <div className='inner'>
          <Title tag="h2"><IntlValue value={product.name} /></Title>
          <div style={{display: "flex", gap: 10}}>
            {attributes?.showVendor && <div className="vendor-name"><FontAwesome name="hat-chef" />{attributes?.vendor.name}</div>}
            {attributes?.showCategory && <div className="vendor-name"><FontAwesome name="tag" />{attributes?.category.name.en}</div>}
          </div>
          <ProductTags tags={product.tags} right={true}/>
          <div className="desc"><IntlValue value={product.description} /></div>
          {_addons}
          <div className="addon-selector">
            <div className="addon-title">
              <Title tag="h3"><IntlText group="product-modal" id="sold-out" /></Title>
            </div>
            <div className="desc"><IntlText group="product-modal" id="sold-out-desc" /></div>
            <Select selected={soldOutActions.filter(action => action.id === alternative)[0]} options={soldOutActions} onChange={(selected) => updateOption({alternative: selected.id})} />
            {!user?.tags?.includes("problematicChange") &&
              <>
                <LabeledTextarea name="instructions" placeholder={getTranslation({group: "product-modal", id: "item-note"})} value={instructions} onChange={(e) => updateOption({[e.target.name]: e.target.value})}></LabeledTextarea>
                <span className='warning'>{getTranslation({group: "product-modal", id: "item-note-warning"})}</span>
              </>
            }
          </div>
        </div>
      </div>
      <div className="product-modal-footer">
        <QuantitySelector qty={_quantity} increase={() => increaseQty(product)} decrease={() => decreaseQty(product)}/>
        <Button id="add-to-cart-button" theme="main" onClick={() => addToCart(product)}>Add to cart - {formatPrice(calculateItemPrice({product: product, ...options}))}</Button>
      </div>
    </div>    
  )
}

function NotFound(){
  return(
    <div>
      Product not found
    </div>
  )
}
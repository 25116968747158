import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Title, Link, IntlText } from '../../components'
import { useAuth } from '../../hooks'
import { RegisterForm } from '../../views'

export default function SignUp() {

  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.id) navigate('/delivery-service')
    // eslint-disable-next-line
  }, [user])  



  return(
    <div id="register" className="container">
      <div className="row wrapped">
        <div id="register-form-wrapper" className="form-wrapper">
          <div className="heading">
            <Title tag="h2"><IntlText group="register" id="title" /></Title>
            <div className="desc"><IntlText group="register" id="desc" /> <Link to="login"><IntlText group="register" id="go-to-login" /></Link></div>
          </div>
          <RegisterForm />
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { getAsset } from '../../helpers'
import { Title, IntlText, Tab, TabButton, Dropdown, MediaQuery } from '../../components'
import { CoverImage, GetStartedCustomer, CustomerPurchase } from '../../views'

import './style.css'

export function Faq() {

  const [selected, select] = useState("get-started-customer")

  return(
    <div id="support" className="container">
      <CoverImage image={getAsset("about-us.jpg")}>
        <div className="row wrapped">
          <div className="call-to-action-form-wrapper">
            <div>
              <Title><IntlText group="faq" id="title" /></Title>
              {/*
                <div id="search">
                  <FontAwesome name="search" />
                  <input name="search" placeholder={getTranslation({group: "faq", id: "search", defaultValue: "Start typing your question"})}/>
                </div>
              */}
            </div>
          </div>
        </div>
      </CoverImage>
      <div className="row wrapped">
        <div id="faqs">
          <MediaQuery min="800">
            <div className="faqs-menu">
              <h2 className="topic-title"><IntlText group="faq" id="for-customer" /></h2>
              <ul>
                <li><TabButton id="get-started-customer" defaultTab><IntlText group="faq" id="get-started-customer" /></TabButton></li>
                <li><TabButton id="purchases-customer"><IntlText group="faq" id="purchase-customer" /></TabButton></li>
              </ul>
            </div>
          </MediaQuery>
          <div className="faqs">
            <MediaQuery max="800">
              <Dropdown selected={<IntlText group="faq" id={selected} />}>
                <TabButton id="get-started-customer" defaultTab onChange={() => select("get-started-customer")}><li><IntlText group="faq" id="get-started-customer" /></li></TabButton>
                <TabButton id="purchases-customer" onChange={() => select("purchase-customer")}><li><IntlText group="faq" id="purchase-customer" /></li></TabButton>
              </Dropdown>
            </MediaQuery>
            <Tab id="get-started-customer" defaultTab>
              <GetStartedCustomer />
            </Tab>
            <Tab id="purchases-customer">
              <CustomerPurchase />
            </Tab>
          </div>
        </div>
      </div>
    </div>
  )
}

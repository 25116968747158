import React from 'react'
import Fontawesome from 'react-fontawesome'

import { Tooltip, IntlText } from '../../../components'

import './style.css'

export function VendorRating({vendor}) {

  if (!vendor.percentageOfPositive) return null

  let percetange = Math.round(vendor.percentageOfPositive * 100)

  return(
    <Tooltip className={`icon-info vendor-rating ${percetange >= 80 ? "good" : percetange >= 50 ? "normal" : "bad"}`} right text={<IntlText group="vendor-rating" id="rating" variables={{rating: percetange, users: vendor.numOfFeedbacks}} />}>
      <span className="amount colored"><Fontawesome className="colored" name="smile" />{percetange}%</span>
      <span className="amount" style={{marginLeft: 5}}><Fontawesome name="users" />{vendor.numOfFeedbacks}</span>
    </Tooltip>
  )
}

import { gql } from "@apollo/client"

import { CUSTOMER_FRAGMENT } from '../'

const FACEBOOK_LOGIN_MUTATION = gql`
  mutation facebookLogin($email: String!, $facebookID:String!){
    facebookLogin(email: $email, facebookID:$facebookID){
      token
      user{...CUSTOMER_FRAGMENT}
    }
  }
  ${CUSTOMER_FRAGMENT}
`

export default FACEBOOK_LOGIN_MUTATION

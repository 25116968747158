import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { MY_ADDRESSES_QUERY, DELETE_ADDRESS_MUTATION } from '../../graphql'
import { useCart } from '../../hooks'
import { RadioDot, Map, MapPin, IntlText, Loader, Button } from '../../components'

import './style.css'

export function SelectLocation({callback, showMap = true}) {

  const cart = useCart()
  const navigate = useNavigate()
  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION)
  const { loading, data, error } = useQuery(MY_ADDRESSES_QUERY)

  async function _deleteAddress(_id){
    try {
      await deleteAddress({variables: {id: _id}, refetchQueries: [{query: MY_ADDRESSES_QUERY}]})
      if (_id === cart?.state?.deliveryLocation?.id){
        cart.set("deliveryLocation", undefined)
      }
    } catch (error) {
      console.log("Error on delete ", error)
    }
  }

  function selectLocation(_location){
    cart.set("deliveryLocation", _location)
    if (callback) callback()
  }

  function editLocation(_location){
    navigate(`/address/${_location.id}`)
    if (callback) callback()
  }
  
  function addNewLocation(){
    navigate("/address")
    if (callback) callback()
  }

  if (loading || error) return <Loader theme="main"/>

  const { myAddresses } = data

  return(
    <div id="select-location">
      <div id="location-list" >
        <div className="select-list">
          <div className="list-item add-item" onClick={addNewLocation}>
            <div className="mark">
              <FontAwesome name="plus-circle" />
            </div>
            <div className="label">
              <span className="name"><IntlText group="select-location" id="add-new" /></span>
              <span className="instructions"><IntlText group="select-location" id="add-new-desc" /></span>
            </div>
          </div>
        </div>
        <div className="location-list select-list">
          {myAddresses.map((_location, key) =>
            <div key={key} className="list-item">
              <div className="list-item-content" onClick={() => selectLocation(_location)}>
                <div className="mark">
                  <RadioDot id={_location.id} checked={!!(_location.id === cart?.state?.deliveryLocation?.id)} readOnly/>
                </div>
                <div className="label">
                  <span className="name">{_location.name}</span>
                  <span className="instructions">{_location.zone}</span>
                </div>
                <div className="actions">
                  <Button onClick={() => editLocation(_location)} icon="edit" />
                  <Button icon="trash" theme='invalid' onClick={() => _deleteAddress(_location.id)}/>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showMap && 
        <div className="selected-location">
          <div className={["selected-map", !cart.state.deliveryLocation ? "blur" : null].join(" ")}>
            {cart.state.deliveryLocation && cart.state.deliveryLocation.location
              ?
                <Map center={{lng: cart.state.deliveryLocation.location.coordinates[0], lat: cart.state.deliveryLocation.location.coordinates[1]}} options={{draggable: false, gestureHandling: false}}>
                    <MapPin lng={cart.state.deliveryLocation.location.coordinates[0]} lat={cart.state.deliveryLocation.location.coordinates[1]} {...cart.state.deliveryLocation.location.coordinates}><FontAwesome name="map-marker" /></MapPin>
                </Map>
              :
                <Map />
            }
          </div>
          <div className="selected-map-overlay">
            {!cart.state.deliveryLocation &&
              <div><IntlText group="select-location" id="no-address-selected" /></div>
            }
          </div>
        </div>
      }
    </div>
  )

}
import FontAwesome from 'react-fontawesome'

import './style.css'

export function NotFound({message}) {
  return(
    <div id="not-found" className="container">
      <div id="not-found-wrapper">
        <FontAwesome name="exclamation-triangle"/>
        <p className="error-text">{message || "The requested page not found or already moved to another location."}</p>
      </div>
    </div>
  )
}

import { gql } from '@apollo/client'

const RESET_CUSTOMER_PASSWORD_MUTATION = gql`
  mutation resetCustomerPassword($token: String!, $password: String!){
    resetCustomerPassword(token: $token, password: $password){
      success
    }
  }
`

export default RESET_CUSTOMER_PASSWORD_MUTATION

import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const MY_PURCHASE_QUOTE_UPDATED_SUBSCRIPTION = gql`
  subscription myPurchaseQuoteUpdated{
    myPurchaseQuoteUpdated{
      ...PURCHASE_QUOTE_FRAGMENT
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`

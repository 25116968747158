import { useQuery } from '@apollo/client'

import { IS_LOGGED_IN, ME_QUERY } from '../../graphql'
import { LoaderV2 } from '../../components'
import { LoginForm } from '../../views'
import { NotFound } from '../../containers'

export function PrivateRoute({auth = () => true, fallback, children}) {

  const {data} = useQuery(IS_LOGGED_IN)
  const {loading, error, data: userData} = useQuery(ME_QUERY, {skip: !data?.isLoggedIn, notifyOnNetworkStatusChange: true})

  if (loading) return(
    <div id="loader" className="container" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <LoaderV2.Logo full />
    </div>
  )
  if (error) return(
    <NotFound message={error?.message} />
  )
  
  const { isLoggedIn } = data || {}
  const { me } = userData || {}

  if (!isLoggedIn) return <LoginForm />
  if (!auth(me)) return fallback

  return children

}


import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useModal } from '../../../hooks'
import { getImageInSize } from '../../../helpers'
import { ModalContext, IntlContext } from '../../../contexts'
import { EDIT_PURCHASE_FEEDBACK_MUTATION, CREATE_PURCHASE_FEEDBACK_MUTATION, CANCEL_MY_PURCHASE } from '../../../graphql'
import { Title, Button, IntlText, Rating, Actions, ActionsDropdown, Modalv3, WhatsAppHelp } from '../../../components'
import { PurchaseStatusText, PurchaseProgressBar, PurchasePayNow, PurchaseModal } from '../../../views'

import './style.css'

export function PurchasesList({purchases, refetch}){

  const modal = useModal()
  const orderModal = useModal()
  const { modals } = useContext(ModalContext)
  const { getTranslation } = useContext(IntlContext)
  const [cancelMyPurchase, { loading }] = useMutation(CANCEL_MY_PURCHASE)

  function isActive(purchase){
    switch (purchase.status) {
      case "INQUEUE":
      case "REQUIRES_PAYMENT":
      case "REQUIRES_CONFIRMATION":
      case "PROCESSED":
      case "INPREPARATION":
      case "READYFORCOLLECTION":
      case "ONTHEWAY":
      case "ATLOCATION":
        return true
      default:
        return false
    }
  }

  async function _cancelMyPurchase(_id){
    try {
      await cancelMyPurchase({variables: {id: _id, reason: "CANTPAY"}})
      toast.success(getTranslation({group: "purchases-list", id: "cancel-success"}))
    } catch (error) {
      if (error.graphQLErrors) return error.graphQLErrors.map(x => toast.error(x?.message))
      if (error.message) return toast.error(error?.message)
      toast.error(error?.message)             
    }
  }  

  return(
    <div className='purchases-list'>
      {purchases.map((purchase, key) =>
        <div key={key} className="purchase">
          <div className="purchase-inner">
            <Link className="purchase-vendor-box" to={`/${purchase.vendor.slug}`}>
              <div className="vendor-logo">
                <picture>
                  <source srcSet={getImageInSize(purchase.vendor.logo, "150", "webp")} type="image/webp" />
                  <source srcSet={getImageInSize(purchase.vendor.logo, "150", "png")} type="image/png" />
                  <img src={purchase.vendor.logo} alt={purchase.vendor.name} title={purchase.vendor.name}/>
                </picture>    
              </div>
              <div className="vendor-name">
                <Title tag="h3">{purchase.vendor.name}</Title>
              </div>
            </Link>
            <div className="purchase-status-box">
              <PurchaseStatusText purchase={purchase} />
            </div>
            <div className="purchase-info-box">
              {isActive(purchase) ?
                <div className="live-updates">
                  <div>
                    <span className="blink dot"></span>
                    <span><IntlText group="orders" id="live-updates" /></span>
                  </div>
                </div>              
              :
                <div className="purchase-date">
                  <span style={purchase.status === "COMPLETED" ? {color: "var(--valid-color"} : {color: "var(--invalid-color"}}><IntlText group="orders" id={purchase.status} /></span>
                  <span>{moment(purchase.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</span>
                </div>
              }
            </div>
          </div>
          {(purchase.status === "COMPLETED" || purchase.status === "UNPROCESSED") ?
            <div>
              {purchase.feedback ?
                <div className="ratings">
                  <Mutation mutation={EDIT_PURCHASE_FEEDBACK_MUTATION}>
                    {(editFeedback) =>
                      <Rating id="products" value={purchase.feedback.productsQuantitative} message={purchase.feedback.productsQualitative} label={<IntlText group="orders" id="products-rating" />} submit={(value, message) => editFeedback({variables: {id: purchase.feedback.id, data: {productsQuantitative: value, productsQualitative: message}}})}/>
                    }
                  </Mutation>
                  <Mutation mutation={EDIT_PURCHASE_FEEDBACK_MUTATION}>
                    {(editFeedback) =>
                      <Rating id="delivery" value={purchase.feedback.deliveryQuantitative} message={purchase.feedback.deliveryQualitative} label={<IntlText group="orders" id="delivery-rating" />} submit={(value, message) => editFeedback({variables: {id: purchase.feedback.id, data: {deliveryQuantitative: value, deliveryQualitative: message}}})}/>
                    }
                  </Mutation>
                </div>
              :
                <div className="ratings">
                  <Mutation mutation={CREATE_PURCHASE_FEEDBACK_MUTATION}>
                    {(createFeedback) =>
                      <Rating id="products" value={null} message={null} label={<IntlText group="orders" id="products-rating" />} submit={(value, message) => createFeedback({variables: {data: {purchase: purchase.id, productsQuantitative: value, productsQualitative: message}}})}/>
                    }
                  </Mutation>
                  <Mutation mutation={CREATE_PURCHASE_FEEDBACK_MUTATION}>
                    {(createFeedback) =>
                      <Rating id="delivery" value={null} message={null} label={<IntlText group="orders" id="delivery-rating" />} submit={(value, message) => createFeedback({variables: {data: {purchase: purchase.id, deliveryQuantitative: value, deliveryQualitative: message}}})}/>
                    }
                  </Mutation>
                </div>
              }            
            </div>
          : isActive(purchase)  ?
            <PurchaseProgressBar purchase={purchase}/>
          : null}
          <Actions inline>
            <Button onClick={() => orderModal.show({purchase})}><IntlText id="check-details" /></Button>
            {purchase?.type !== "CUSTOMER_PICKUP" && purchase?.timestamps?.vendorConfirmed && !["INQUEUE", "PROCESSED", "ATLOCATION", "COMPLETED", "CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "PAYMENTFAILED"].includes(purchase.status) &&
              <ActionsDropdown placeholder={<IntlText group="report-problem" id="button" />}>
                <li onClick={() => modals.reportProblem.current?.show({purchase})}><IntlText group="report-problem" id="order-late" /></li>
              </ActionsDropdown>
            }            
            {purchase?.type === "CUSTOMER_PICKUP" ? 
              <Button onClick={() => orderModal.show({purchase, selected: "order-address"})}><IntlText group='purchases-list' id='show-restaurant-location' /></Button>
            : null}
            <Button onClick={() => modals.orderHelp.current?.show()}><IntlText id="help" /></Button>
            {purchase.status === "DELIVERYFAILED" && 
              <WhatsAppHelp><IntlText group="purchases-list" id="contact-support" /></WhatsAppHelp>
            }
            {purchase.status === "REQUIRES_PAYMENT" && 
              <>
                <Button loading={loading} onClick={() => _cancelMyPurchase(purchase.id)} theme="ui"><IntlText group="purchases-list" id="cancel-purchase" /></Button>
                <Button onClick={() => modal.show(purchase)} theme="main"><IntlText group="purchases-list" id="pay-now" /></Button>
              </>
            }
          </Actions>      
        </div>
      )}
      <Modalv3 modal={orderModal} >
        <PurchaseModal purchase={orderModal.state?.purchase} selected={orderModal.state?.selected} />
      </Modalv3>   
      <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="purchases-list" id="pay-now-modal" /></Title>} >
        <PurchasePayNow purchase={modal.state} refetch={refetch} close={modal.hide} />
      </Modalv3>   
    </div>
  )
}
import React from 'react'

import { getAsset } from '../../helpers'
import { Title, IntlText } from '../../components'
import { CoverImage } from '../../views'

import './style.css'

export function AboutUs() {

  return(
    <div id="about-us" className="container">
      <CoverImage image={getAsset("about-us.jpg")}>
        <div className="row wrapped">
          <div className="call-to-action-form-wrapper">
            <div>
              <Title><IntlText group="about-us" id="who-we-are" /></Title>
              <div className="info"><IntlText group="about-us" id="desc" /></div>
            </div>
          </div>
        </div>
      </CoverImage>
      <div className="row wrapped">
        <div className="about-us">
          <div className="about-us-info">
            <div className="heading no-desc">
              <Title tag="h2"><IntlText group="about-us" id="our-mission" /></Title>
            </div>
            <div className="text">
              <IntlText group="about-us" id="our-mission-content" />
            </div>
          </div>
          <div className="about-us-info">
            <div className="heading no-desc">
              <Title tag="h2"><IntlText group="about-us" id="our-vision" /></Title>
            </div>
            <div className="text">
              <IntlText group="about-us" id="our-vision-content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

import { useContext }  from 'react'

import { IntlContext } from '../../contexts'

export function IntlBlock({language, children}){

    const { selectedLanguage } = useContext(IntlContext)

    if (selectedLanguage?.shortcode !== language) return null

    return children
}
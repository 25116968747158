import { useIntl } from '../../hooks'
import { getAsset } from '../../helpers'
import { Title, IntlText, IconBox, IntlValue, Text, Link} from '../../components'
import { MoreInfo, CoverImage } from '../../views'

export function ShareAndSave({page}) {

  const { getTranslatedValue, getTranslation } = useIntl()
  const text = getTranslatedValue(page?.content) || ""

  return(
    <div id="save-and-share" className={`page ${page.template}`}>
       <CoverImage image={getAsset("share-and-save.jpeg")}>
        <div className="row wrapped">
          <div className="call-to-action-form-wrapper">
            <div>
              <Title><IntlValue value={page.title} /></Title>
              <div className="info"><IntlValue value={page.description} /></div>
            </div>
          </div>
        </div>
      </CoverImage>
      <div className="row wrapped xl">
        <div className="row-header">
          <Title tag="h2"><IntlText group="share-and-save" id="how-it-works" /></Title>
        </div>        
        <IconBox.Box>
          <IconBox.Item title={getTranslation({group: "share-and-save", id: "sign-up"})} image="https://d2l0zqfhpatq4j.cloudfront.net/icons/reliability.png" description={getTranslation({group: "share-and-save", id: "sign-up-desc"})} />
          <IconBox.Item title={getTranslation({group: "share-and-save", id: "share"})} image="https://d2l0zqfhpatq4j.cloudfront.net/icons/communication.png" description={getTranslation({group: "share-and-save", id: "share-desc"})} />
          <IconBox.Item title={getTranslation({group: "share-and-save", id: "earn"})} image="https://d2l0zqfhpatq4j.cloudfront.net/icons/earnings.png" description={getTranslation({group: "share-and-save", id: "earn-desc"})} />
        </IconBox.Box>
      </div>
      <div className="row wrapped xl">
        <Text>{text}</Text>     
        <div style={{display: "flex", justifyContent: "center", marginTop: 40}}>
            <Link button className='main' style={{fontSize: "var(--font-l)", padding: 20}} to="/account/referrals"><IntlText group='share-and-save' id="start" /></Link>
        </div>
      </div>
      <MoreInfo />
    </div>
  )
}

import { gql } from '@apollo/client'

export const VERIFY_CUSTOMER_CEL = gql`
  mutation verifyCustomerCel($code: String!){
     verifyCustomerCel(code: $code) {
        inSession
        cel{
            countryCode
            phoneCode
            phoneNumber
        }
        status
        lastWhatsappIntent  
        nextWhatsappIntent
        whatsappIntentCount
        lastSMSIntent
        nextSMSIntent
        SMSIntentCount
        uid
     }
  }
`

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useSaveReferralCode(){

    const location = useLocation()
    const referralCode = new URLSearchParams(location.search)?.get("referral")

    useEffect(() => {
        if (!referralCode) return null

        const timestamp = new Date().getTime()

        localStorage.setItem('referralCode', referralCode)
        localStorage.setItem('referralCodeSaveTime', timestamp)
    }, [referralCode])

    useEffect(() => {
        const storedTimestamp = localStorage.getItem('referralCodeSaveTime')

        if (storedTimestamp) {
            var storedDate = new Date(parseInt(storedTimestamp))
            var currentDate = new Date()
          
            // Calculate the difference in milliseconds
            var differenceInMs = currentDate - storedDate;
            var millisecondsInADay = 24 * 60 * 60 * 1000;
          
            if (differenceInMs > millisecondsInADay) {
                localStorage.setItem('referralCode', undefined)
            }          
        }        

    }, [])
}
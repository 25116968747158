import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useIntl } from '../../hooks'
import { getAsset } from '../../helpers'
import { Title, IntlText, IconBox, Link } from '../../components'
import { HowItWorks, CoverImage, FeaturedPartners } from '../../views'

export function Home() {

  const location = useLocation()
  const { getTranslation } = useIntl()
  const url = location?.pathname
  const icons = getAsset("icons.json")

  function tryApp(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return <a href={`https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PLAYSTORE_ID}`}><IntlText group="home" id="download-app-button" /></a>
      }
      
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return <a href={`https://apps.apple.com/mx/app/tomato-mx/id${process.env.REACT_APP_APPSTORE_ID}`}><IntlText group="home" id="download-app-button" /></a>
    }

    return <Link to="/delivery-service"><IntlText group="home" id="download-app-button" /></Link>
  }

  return(
    <div id="home" className="container">
      <Helmet>
        <title>{getTranslation({group: "meta", id: "home-title"})}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={getTranslation({group: "meta", id: "home-description"})} />
        <meta property="og:title" content={getTranslation({group: "meta", id: "home-title"})}/>
        <meta property="og:description" content={getTranslation({group: "meta", id: "home-description"})} />
        <meta property="og:url" content={`${window.location.protocol}//${window.location.host + url}`} />
        <link rel="canonical" href="https://tomato.mx/" />
      </Helmet>
      <div className="row" style={{margin: 0}}>
        <CoverImage image={getAsset("welcome.jpg")}>
          <Title tag="h1"><IntlText group="home" id="main-title" /></Title>
          <Link button theme="main" to="/delivery-service" title={getTranslation({group:"home", id:"main-button-title"})}><IntlText group="home" id="main-button" /></Link>
        </CoverImage>
      </div>
      <div className="row wrapped xl">
        <IconBox.Box>
          <IconBox.Item title={<IntlText group="home" id="download-app" />} image={icons.tryTheApp} description={<IntlText group="home" id="download-app-desc" />}>
            {tryApp()}
          </IconBox.Item>
          <IconBox.Item title={<IntlText group="home" id="ride-with-us" />} image={icons.becomeARider} description={<IntlText group="home" id="ride-with-us-desc" />}>
            <Link to='/ride-with-us'><IntlText group="home" id="ride-with-us-button" /></Link>      
          </IconBox.Item>
          <IconBox.Item title={<IntlText group="home" id="for-restaurants" />} image={icons.becomeAPartner} description={<IntlText group="home" id="for-restaurants-desc" />}>
            <Link to='/for-restaurants-and-shops'><IntlText group="home" id="for-restaurants-button" /></Link>   
          </IconBox.Item>
        </IconBox.Box>        
      </div>        
      <FeaturedPartners />
      <HowItWorks />
    </div>
  )
}

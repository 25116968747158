import React from 'react'

import './Ribbon.css'

export default function Ribbon({children}) {

  return(
       <div className="ribbon"><span>{children}</span></div>
  )
}

import React, { useRef, useEffect } from 'react'

import { IntlText, Loader } from '../../components'

import './style.css'

export function InfiniteList({className = "", loading, disabled, fetchMore, array, pageInfo, children}){

  const buttonRef = useRef(null)

  useEffect(() => {
    function handleOnScroll(){
      if (!buttonRef.current) return false

      var clientHeight = document.documentElement.clientHeight || window.innerHeight
      var rect = buttonRef.current.getBoundingClientRect()
      var elemTop = rect.top
      var elemBottom = rect.bottom

      // Only completely visible elements return true:
      var isVisible = (elemTop >= 0) && (elemBottom <= clientHeight)
      // Partially visible elements return true:
      isVisible = (elemTop - (clientHeight*0.3)) < clientHeight && (elemBottom - (clientHeight*0.3)) >= 0

      if (isVisible) buttonRef.current.click()
    }

    window.addEventListener("scroll", handleOnScroll)

    return () => {
      window.removeEventListener("scroll", handleOnScroll)
    }
  }, [])

  return(
    <div className="infinite-list">
      <div className={className}>
        {array.map(children)}
      </div>
      {pageInfo?.hasNextPage &&
        <button className="button load-more" disabled={disabled ? "disabled" : ""} ref={buttonRef} onClick={fetchMore}>{loading && <Loader theme="main" />}<IntlText id="load-more" /></button>
      }
    </div>
  )

}

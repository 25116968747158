import React from 'react'
import { Query } from '@apollo/client/react/components'

import { SETTING_QUERY } from '../../graphql'
import { WhatsAppHelp, IntlText, Loader } from '../../components'

import './style.css'

export function MoreInfo() {
  return(
    <Query query={SETTING_QUERY} variables={{key: "supportEmail"}}>
      {({ loading, error, data }) => {
        if (loading) return <Loader theme="main" />
        if (error) return `Error! ${error.message}`

        const { setting } = data

        if (setting) return(
          <div className="more-info">
            <span><IntlText group="more-info" id="message" /></span>
            <a className="link" href={`mailto:${setting.value}`} rel="nofollow">{setting.value}</a>
            <span><IntlText id="or" /></span>
            <WhatsAppHelp />
          </div>          
        )

        return null
      }}
    </Query>    
  )
}

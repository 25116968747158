import { useNavigate } from 'react-router-dom'

import config from '../../config'
import { getAsset } from '../../helpers'

import './style.css'

export function Logo({className, appIcon}) {

  const navigate = useNavigate()

  return(
    <div onClick={() => navigate("/delivery-service")}>
      <div className={`logo ${className}`}>
        <img src={appIcon ? getAsset("app-icon.png") : getAsset("logo.png")} alt={config.siteName} />
      </div>
    </div>
  )

}
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { getImageInSize } from '../../../helpers'
import { PURCHASE_QUERY } from '../../../graphql'
import { Title, IntlText, Loader } from '../../../components'
import { PurchaseProducts, PurchaseTotals, PurchaseInfo, PurchaseAddress, OrderInvoices, PurchaseRevisions, PurchaseRefunds } from '../../../views'

import './style.css'

export function PurchaseModal({purchase, selected}) {

  const { data, loading } = useQuery(PURCHASE_QUERY, {variables: {id: purchase?.id}, skip: !purchase?.id})

  if (loading) return <Loader theme='main' />

  return(
    <Inner purchase={data?.purchase} selected={selected} />
  )
}

function Inner({purchase, selected}){

  const [selectedTab, changeTab] = useState(selected || "order-info")

  if (!purchase) return null

  return(
      <div id='order-modal'>
        <Link className="partner-box" to={`/${purchase.vendor.slug}`}>
          <div className="partner-logo">
            <div>
              <picture>
                <source srcSet={getImageInSize(purchase.vendor.logo, "150", "webp")} type="image/webp" />
                <source srcSet={getImageInSize(purchase.vendor.logo, "150", "png")} type="image/png" />
                <img src={purchase.vendor.logo} alt={purchase.vendor.name} title={purchase.vendor.name}/>
              </picture>                   
            </div>
          </div>
          <div className="partner-name">
            <Title tag="h2">{purchase.vendor.name}</Title>
          </div>
        </Link>
        <div className="tabs-menu">
          <div className={`tabs-menu-item ${selectedTab === "order-info" ? "active" : ""}`} onClick={() => changeTab("order-info")}>
            <span><IntlText group="order-modal" id="info" /></span>
          </div>
          <div className={`tabs-menu-item ${selectedTab === "order-items" ? "active" : ""}`} onClick={() => changeTab("order-items")}>
            <span><IntlText group="order-modal" id="items" /></span>
          </div>
          {purchase?.refunds?.length > 0 ?
            <div className={`tabs-menu-item ${selectedTab === "order-refunds" ? "active" : ""}`} onClick={() => changeTab("order-refunds")}>
              <span><IntlText group="order-modal" id="refunds" /></span>
            </div>
          : null}
          <div className={`tabs-menu-item ${selectedTab === "order-address" ? "active" : ""}`} onClick={() => changeTab("order-address")}>
            <span><IntlText group="order-modal" id="address" /></span>
          </div>
          {purchase.revisions?.length > 0 &&
            <div className={`tabs-menu-item ${selectedTab === "order-history" ? "active" : ""}`} onClick={() => changeTab("order-history")}>
              <span><IntlText group="order-modal" id="history" /></span>
            </div>
          }
          <div className={`tabs-menu-item ${selectedTab === "order-invoices" ? "active" : ""}`} onClick={() => changeTab("order-invoices")}>
            <span><IntlText group="order-modal" id="invoice" /></span>
          </div>
        </div>
        <div className="tabs-wrapper">
          <div className="tabs">
            {selectedTab === "order-info" &&
              <div className="tab active">
                <PurchaseInfo purchase={purchase} />
              </div>
            }
            {selectedTab === "order-items" &&
              <div className="tab active">
                <PurchaseProducts purchase={purchase} />
                <PurchaseTotals purchase={purchase}>
                  {purchase.revisions?.length > 0 &&
                    <div id="revisions" className="totals-info" onClick={() => changeTab("order-history")}>
                      <div className="icon">
                        <FontAwesome name="exclamation-triangle"/>
                      </div>
                      <div className="info">
                        <IntlText group="order-totals" id="purchase-history"/>
                      </div>
                    </div>              
                  }                     
                </PurchaseTotals>
              </div>
            }
            {selectedTab === "order-refunds" &&
              <div className="tab active">
                <PurchaseRefunds purchase={purchase} />
              </div>
            }
            {selectedTab === "order-address" &&
              <div className="tab active">
                <PurchaseAddress purchase={purchase} />
              </div>
            }
            {selectedTab === "order-invoices" &&
              <div className="tab active">
                <OrderInvoices purchase={purchase} />
              </div>
            }
            {selectedTab === "order-history" &&
              <div style={{overflow: "auto"}} className="tab active">
                <PurchaseRevisions purchase={purchase} />        
              </div>
            }
          </div>
        </div>
      </div>  
  )
}

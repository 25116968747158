import FontAwesome from 'react-fontawesome'

import { useIntl } from '../../hooks'
import { Modalv3, Title } from '../'

import './style.css'

export function SelectModal({id, modal, selected, options, placeholder, onChange, labelId = "label", children}) {

  const { getTranslation } = useIntl()

  return(
    <Modalv3 id={id} modal={modal} header={<Title tag="h3">{placeholder}</Title>} padding>
      <div id={id} className={`select-modal`}>
        {children && <div style={{padding: "0 15px 15px"}}>{children}</div>}
        <ul>
          {options.length > 0 ? options.map((option, key) =>
            <SelectItem key={key} selected={option.id === selected?.id} onChange={onChange} option={option} labelId={labelId} />
          ) : 
            <div className='no-results'>
              <FontAwesome name="search-minus"/>
              <p className="error-text">{getTranslation({id: "no-options-found"})}</p>
            </div>
          }
        </ul>
      </div>
    </Modalv3>       
  )
}

function SelectItem({selected, option, onChange, labelId}){

  function _onChange(){
    onChange(option)
  }
  
  return(
    <li className={`${selected ? "selected" : ""}`} onClick={_onChange}>
      <span>{option[labelId]}</span>
      {selected &&
        <span className='icon'><FontAwesome name="check" /></span>
      }
    </li>
  )
}
import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import { ModalContext } from '../../../contexts'
import { SETTING_QUERY } from '../../../graphql'
import { IntlText, Modal, Loader, Button } from '../../../components'

import './style.css'

export function ReportProblemModal() {

  const { modals } = useContext(ModalContext)

  return(
    <Modal id="report-problem" ref={modals?.reportProblem} header={<ModalHeader />} footer={<ModalFooter />}>
      {({state: {purchase}}) => <ModalInner purchase={purchase} />}
    </Modal>
  )

}

function ModalInner({purchase}){

  const { data: settings, loading, error } = useQuery(SETTING_QUERY, {variables: {key: "ESTIMATED_DELAY"}})

  const { timestamps: {vendorConfirmed, collected}, preparationTime, status, rider, estimatedDeliveryTime, arrivedAtVendor } = purchase

  function getEllapsedTime({vendorConfirmed, preparationTime}){
    let now = moment(vendorConfirmed).add(preparationTime, "m")
    let future = moment()
    let diff = future - now
    let mins  = Math.floor( diff / (1000*60) )

    return mins
  }

  function getEstimatedTime({collected, estimatedDeliveryTime, expectedDelay}){
    let now = moment(collected).add(estimatedDeliveryTime + expectedDelay, "m")
    let future = moment()
    let diff = future - now
    let mins  = Math.floor( diff / (1000*60) )

    if (mins > 0) return false

    return Math.abs(mins)
  }

  if (loading) return <Loader theme="main" loading={loading} />
  if (error) return `Error: ${error}`

  const { setting } = settings
  const diff = getEllapsedTime({vendorConfirmed, preparationTime})
  const deliveryDiff = getEstimatedTime({estimatedDeliveryTime, collected, expectedDelay: parseInt(setting?.value)})

  if (status === "ONTHEWAY" && deliveryDiff > 0) {
    return(
      <div className="report valid">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="on-the-way" variables={{riderName: rider?.name || "", estimatedDeliveryTime: deliveryDiff}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  } else if (status === "ONTHEWAY" && deliveryDiff <= 0) {
    return(
      <div className="report warning">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="on-the-way-late" variables={{riderName: rider?.name || "", estimatedDeliveryTime: deliveryDiff}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  } else if (arrivedAtVendor && diff >= 0) {
    return(
      <div className="report warning">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="stuck-in-restaurant" variables={{mins: Math.abs(diff)}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  } else if (arrivedAtVendor && diff < 0) {
    return(
      <div className="report valid">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="wait-in-restaurant" variables={{mins: Math.abs(diff), riderName: rider?.name || ""}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  } else if (!arrivedAtVendor && diff >= 0 ) {
    return(
      <div className="report warning">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="we-are-in-late" variables={{mins: Math.abs(diff), riderName: rider?.name || ""}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  } else {
    return(
      <div className="report valid">
        <div className="message">
          <FontAwesome name="exclamation-triangle" />
          <IntlText group="report-problem-modal" id="order-on-time" variables={{mins: Math.abs(diff)}}/>
        </div>
        <div className="thank-you">
          <span><IntlText group="report-problem-modal" id="thank-you" /></span>
        </div>
      </div>
    )
  }

}

function ModalHeader(){
  return(
    <div className="modal-title">
      <h1 className="title"><IntlText group="report-problem-modal" id="title" /></h1>
    </div>
  )
}

function ModalFooter(){

  const { modals } = useContext(ModalContext)

  return(
    <div className="actions">
      <Button onClick={() => modals?.reportProblem?.current?.hide()}><IntlText id="ok" /></Button>
    </div>
  )
}

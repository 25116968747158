import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useForm } from '../../hooks'
import { parsePhone, validateEmail, validatePhonenumber } from '../../helpers'
import { IntlContext } from '../../contexts'
import { SEND_CUSTOMER_PASSWORD_RESET_MUTATION, GET_ONE_TIME_PASSWORD_OF_CUSTOMER } from '../../graphql'
import { Form, Title, IntlText, LabeledInput, Validation, Button, SegmentedControl, PhoneNumberInput, Select, Notificaion } from '../../components'
import { OneTimeLogin } from '../../views'

import './style.css'

export function ForgetPassword() {

  const { method } = useParams()
  const [selectedMethod, selectMethod] = useState(method || "email")
  const { getTranslation } = useContext(IntlContext)
  const [getOneTimePassword, {loading}] = useMutation(GET_ONE_TIME_PASSWORD_OF_CUSTOMER)
  const [sendPasswordReset, {loading: emailLoading}] = useMutation(SEND_CUSTOMER_PASSWORD_RESET_MUTATION)
  const methods = [{id: "WHATSAPP", label: getTranslation({group: "verify-number", id: "WHATSAPP"})}, {id: "SMS", label: getTranslation({group: "verify-number", id: "SMS"})}]
  const { form, updateField, errors, validate, resetForm, success, fail, status } = useForm({
    recoveryMethod: methods[0]
  }, {
    uid: selectedMethod === "phone" ? [
      {id: "required", validation: (val) => !!(val?.phoneNumber && val?.phoneCode)},
      {id: "incorrectNumber", validation: (val => val && validatePhonenumber(val))},
    ] : [
      {id: "required", validation: (val) => !!val},
      {id: "email", validation: (val => validateEmail(val))},
    ]
  })
  const { uid, recoveryMethod } = form

  function _selectMethod(_method){
    resetForm()
    selectMethod(_method)
  }

  async function submit(){
    try {
      const { valid } = validate()
  
      if (!valid) return toast.error(getTranslation({id: "form-error"}))

      if (selectedMethod === "phone"){
        const _uid = `${uid.countryCode}:${uid.phoneCode.replace('+', '')}:${uid.phoneNumber.replace(/\s/gm, '')}`
        const result = await getOneTimePassword({variables: {uid: _uid, method: recoveryMethod?.id}})

        success(result)
      }
      
      if (selectedMethod === "email"){
        await sendPasswordReset({variables: {email: uid}})
        success()
      }
    } catch (e) {
      fail()
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="account-recovery" className="container">
      <div className="row wrapped">
        <div className="heading no-desc">
          <Title tag="h2"><IntlText group="account-recovery" id="title" /></Title>
          <div className="desc"><span><IntlText group="account-recovery" id="desc"> /</IntlText></span></div>
        </div>
        {status?.id === "success" ?
          <div>
            <Notificaion title={<IntlText group="account-recovery" id="success-title" />}>
              <span><IntlText group="account-recovery" id={`success-info-${selectedMethod}`} /></span>
            </Notificaion>
            {selectedMethod === "phone" &&
              <OneTimeLogin uid={uid} />
            }
          </div>
        :
          <Form.Form>
            <Form.Field centered>
              <SegmentedControl value={selectedMethod} options={[{id: "email", label: getTranslation({group: "account-recovery", id: "email"})}, {id: "phone", label: getTranslation({group: "account-recovery", id: "phone"})}]} onClick={(_option) => _selectMethod(_option.id)}/>          
            </Form.Field>
            {selectedMethod === "phone" ? 
              <>
                <Form.Field>
                  <Validation errors={errors.method}>
                    <Select id="recoveryMethod" selected={recoveryMethod} defaultValue={<IntlText id="nothing-selected" />} placeholder={<IntlText group="verify-number" id="select-method" />} options={methods} onChange={(selected) => updateField({key: "recoveryMethod", value: selected})} />
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <Validation errors={errors.uid}>
                    <PhoneNumberInput name="uid" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(uid)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "uid", value: {countryCode: _data?.countryCode, phoneNumber: _data?.phoneNumber, phoneCode: _data?.phoneCode}})} disablePreferences={true} />
                  </Validation>
                </Form.Field>
              </>
            : selectedMethod === "email" ?
              <Form.Field>
                <Validation errors={errors.uid}>
                  <LabeledInput name="uid" placeholder={getTranslation({id: "input-uid"})} value={uid} onChange={({target}) => updateField({key: target.name, value: target.value.replace(/\s/g, '')})}/>
                </Validation>
              </Form.Field>
            : null}
            <Form.Field>
              <Button fluid theme="main" loading={loading || emailLoading} onClick={submit}><IntlText id="send" /></Button>
            </Form.Field>
          </Form.Form>
        }
      </div>
    </div>
  )
}

import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'
import { PURCHASE_FRAGMENT } from '../purchase'

export const BUY_PURCHASE_QUOTE = gql`
  mutation buyPurchaseQuote($id: ID!, $saveCard: Boolean){
    buyPurchaseQuote(id: $id, saveCard: $saveCard){
      quote{
        ...PURCHASE_QUOTE_FRAGMENT
      }
      purchase{
        ...PURCHASE_FRAGMENT
      }
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
  ${PURCHASE_FRAGMENT}
`

import { useState } from 'react'
import { AsYouType } from 'libphonenumber-js'
import FontAwesome from 'react-fontawesome'

import { useIntl, useModal } from '../../hooks'
import { Button, IntlText, SelectModal } from '../'

import './style.css'

import _countries from './countries.json'

export function PhoneNumber({onChange, value, defaultCountry, featuredCountries, name, placeholder, disablePreferences, disabled}) {

  const modal = useModal()
  const { getTranslation } = useIntl()
  const [search, setSearch] = useState("")
  const [preference, setPreference] = useState(false)
  const [countries, setCountries] = useState(_countries)

  function onSearch(string){
    let filtered = _countries.filter((country) => country.name.toLowerCase().search(string.toLowerCase()) !== -1 || country.phoneCode.search(string.toLowerCase()) !== -1)

    setSearch(string)
    setCountries(filtered)
  }

  function _onChange(data){
    onChange(data)
    modal.hide()
  }

  let selectedCountry =  value || _countries.filter((data) => data.countryCode.toLowerCase() === defaultCountry)[0] || countries[0]
  let countriesList = countries.reduce((current, next) => {
    let listItem = {
      ...next,
      label: (
        <div style={{display: "flex", gap: 10}}>
          <span className={"flag-icon flag-icon-" + next.countryCode.toLowerCase()}></span>
          {`${next.name} (${next.phoneCode})`}
        </div>
      ),
      id: next.countryCode
    }

    if (featuredCountries && (featuredCountries.includes(next.countryCode.toLowerCase()) || featuredCountries.includes(next.countryCode))){
      current.featured.push(listItem)
    } else {
      current.normal.push(listItem)
    }

    return current

  }, {featured: [], normal: []})
  const options = [...countriesList.featured, ...countriesList.normal]

  if ((!value?.phoneNumber && !preference) && !disablePreferences) return(
    <div className="phone-number-input">
      <div className="user-preference">
        <Button onClick={() => setPreference("whatsapp")}><i className="fab fa-whatsapp"></i><IntlText group="phone-number-input" id="whatsapp" /> <IntlText group="phone-number-input" id="recommended" /></Button>
        <Button onClick={() => setPreference("phone")}><i className="fa fa-phone"></i><IntlText group="phone-number-input" id="phone" /></Button>
      </div>
    </div>
  )

  return(
    <div className="phone-number-input">
      {!disablePreferences &&
        <div className="change-preference">
          {preference === "whatsapp" ?
            <Button onClick={() => setPreference("phone")}><i className="fab fa-whatsapp"></i><IntlText group="phone-number-input" id="whatsapp" /></Button>
            : preference === "phone" ?
            <Button onClick={() => setPreference("whatsapp")}><i className="fa fa-phone"></i><IntlText group="phone-number-input" id="phone" /></Button>
            : null}
        </div>
      }
      <div className={`phone-picker ${disabled ? "disabled" : ""}`} >
        <div className="country" onClick={!disabled ? () => modal.show() : null}>
          <span className={"flag-icon flag-icon-" + selectedCountry.countryCode.toLowerCase()}></span>
          <span className="country-code">{selectedCountry.phoneCode}</span>
          <FontAwesome name="caret-down" />
        </div>
        <input className='input' disabled={disabled} type="phone" name={name} placeholder={placeholder} value={value?.phoneNumber ? new AsYouType(value?.countryCode).input(value?.phoneNumber) : ""} onChange={({target}) => onChange({phoneCode: value.phoneCode, countryCode: value.countryCode, phoneNumber: target.value})} />
      </div>
      <SelectModal id="select-country-code" modal={modal} selected={options.find(_item => _item.countryCode === value?.countryCode)} placeholder={getTranslation({group: "phone-number-input", id:"select-country"})} options={options} onChange={(_value) => _onChange({phoneCode: _value.phoneCode, countryCode: _value.countryCode, phoneNumber: value.phoneNumber})}>
        <div className='search-wrapper'>
          <FontAwesome name="search" />
          <input className='search' value={search} onChange={({target}) => onSearch(target.value)} />
        </div>
      </SelectModal>      
    </div>
  )
}

import React from 'react'

import './CoverImage.css'

export default function CoverImage({image, children}) {
  return(
    <div className="cover-image" style={{backgroundImage: `url(${image})`}}>
      <div className="content-box">
        {children}
      </div>
    </div>
  )
}

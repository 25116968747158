import { toast } from 'react-toastify'
import { AsYouType } from 'libphonenumber-js'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import { useIntl } from '../../../hooks'
import { orderStatus } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function PurchaseInfo({purchase: { id, customer, status, type, payment, paymentStatus, createdAt, timestamps, customerNote}}) {

  const { getTranslation } = useIntl()

  function finishedDate(){
    switch (status) {
      case "COMPLETED":
        return moment(timestamps.completed).format("YYYY MMMM DD. HH:mm:ss")
      case "DELIVERYFAILED":
        return moment(timestamps.deliveryFailed).format("YYYY MMMM DD. HH:mm:ss")
      case "CANCELLED":
        return moment(timestamps.cancelled).format("YYYY MMMM DD. HH:mm:ss")
      case "REJECTED":
        return moment(timestamps.rejected).format("YYYY MMMM DD. HH:mm:ss")
      case "UNPROCESSED":
        return moment(timestamps.unprocessed).format("YYYY MMMM DD. HH:mm:ss")
      case "PAYMENTFAILED":
        return moment(timestamps.paymentFailed).format("YYYY MMMM DD. HH:mm:ss")
      default:
        return null
    }
  }  

  function isTerminated(){
    return ["COMPLETED", "DELIVERYFAILED", "CANCELLED", "REJECTED", "UNPROCESSED", "PAYMENTFAILED"].includes(status)
  }

  function copy(){
    navigator.clipboard.writeText(id)
    toast.success(getTranslation({id: "copy-success"}))
}


  return(
    <div id="order-info" className="info-table">
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="hashtag" />
          <span><IntlText group="order-info" id="id" /></span>
        </div>
        <div className="info" onClick={copy}>
          <span>{id}</span>
          <FontAwesome name="copy" />
        </div>
      </div>
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="info-circle" />
          <span><IntlText group="order-info" id="status" /></span>
        </div>
        <div className="info">
          <span>{orderStatus(status)}</span>
        </div>
      </div>
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="user" />
          <span><IntlText group="order-info" id="name" /></span>
        </div>
        <div className="info">
          <span>{customer.name}</span>
        </div>
      </div>
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="phone" />
          <span><IntlText group="order-info" id="phone" /></span>
        </div>
        <div className="info">
          <span>+{customer.phone?.phoneCode} {new AsYouType(customer.phone?.countryCode).input(customer.phone?.phoneNumber)}</span>
        </div>
      </div>
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="envelope" />
          <span><IntlText group="order-info" id="email" /></span>
        </div>
        <div className="info">
          <span>{customer.email}</span>
        </div>
      </div>
      {customer?.contactPreference &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="bell" />
            <span><IntlText group="order-info" id="contact-preference" /></span>
          </div>
          <div className="info">
            <span><IntlText group="order-info" id={customer?.contactPreference} /></span>
          </div>
        </div>
      }
      {customerNote &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="file-signature" />
            <span><IntlText group="order-info" id="customerNote" /></span>
          </div>
          <div className="info">
            <span>{customerNote}</span>
          </div>
        </div>
      }      
      {type &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="box" />
            <span><IntlText group="order-info" id="type" /></span>
          </div>
          <div className="info">
            <span><IntlText group="order-info" id={type} /></span>
          </div>
        </div>
      }
      {payment &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="money-check-alt" />
            <span><IntlText group="order-info" id="payment-method" /></span>
          </div>
          <div className="info">
            <span><IntlText group="order-info" id={payment} /></span>
          </div>
        </div>
      }
      {paymentStatus &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="hourglass" />
            <span><IntlText group="order-info" id="payment-status" /></span>
          </div>
          <div className="info">
            <span><IntlText group="order-info" id={paymentStatus} /></span>
          </div>
        </div>
      }
      <div className="info-table-item">
        <div className="label">
          <FontAwesome name="calendar" />
          <span><IntlText group="order-info" id="order-placed" /></span>
        </div>
        <div className="info">
          <span>{moment(createdAt).format("YYYY MMMM DD. HH:mm:ss")}</span>
        </div>
      </div>
      {isTerminated() &&
        <div className="info-table-item">
          <div className="label">
            <FontAwesome name="calendar-check" />
            <span><IntlText group="order-info" id={status} /></span>
          </div>
          <div className="info">
            <span>{finishedDate()}</span>
          </div>
        </div>
      } 
    </div>
  )
}

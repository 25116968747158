import React from 'react'

import { PartnerCardSkeleton } from '../../../views'

export function PartnersLoader({count = 48}){

  return(
    <div id="parnter-loader">
      <div className="partner-cards">
        {[...Array(count)].map((data, key) => <PartnerCardSkeleton key={key}/>)}
      </div>
    </div>
  )

}

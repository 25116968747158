import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { Title, IntlText, Loader, Link } from '../../../components'
import { PurchasesList, OrderSkeleton } from '../../../views'
import { MY_PURCHASES_QUERY, SETTING_QUERY, MY_PURCHASES_UPDATE_SUBSCRIPTION } from '../../../graphql'

import './style.css'

export function PendingPurchases() {

  const { loading, data, error, refetch, subscribeToMore } = useQuery(MY_PURCHASES_QUERY, {variables: {status: ["REQUIRES_PAYMENT", "REQUIRES_CONFIRMATION", "INQUEUE", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}})

  useEffect(() => {
    async function updatePurchases() {
      try {
        if (document.visibilityState !== 'visible') return false
        await refetch()
      } catch (error) {
        console.log("Update purchase error", error)
      }      
    }

    const unsub =  subscribeToMore({
      document: MY_PURCHASES_UPDATE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = Array.from(prev?.myPurchases || [])
        const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
        if (index >= 0){
          if (["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
            _data.splice(index, 1)
          } else {
            _data[index] = subscriptionData.data.myPurchasesUpdated
          }
          return Object.assign({}, prev, {
            myPurchases: _data
          })
        } else if (index === -1 && !["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(subscriptionData.data.myPurchasesUpdated.status)) {
          return Object.assign({}, prev, {
            myPurchases: [subscriptionData.data.myPurchasesUpdated, ..._data]
          })          
        }
      }
    })    
    
    updatePurchases()

    document.addEventListener("visibilitychange", updatePurchases)
    return () => {
      unsub()
      document.removeEventListener("visibilitychange", updatePurchases)
    }
 // eslint-disable-next-line    
  }, [])

  if (loading) return(
    <div id="pending-orders">
      {[...Array(8)].map((_, key) => <OrderSkeleton key={key}/>)}
    </div>
  )

  if (error) return `Error! ${error.message}`

  let { myPurchases } = data

  if (!myPurchases.length > 0) return(
    <div id="pending-purchases">
      <div className="no-orders">
        <span className="help"><IntlText group="pending-orders" id="no-orders" /></span>
        <div>
          <Link to="/delivery-service" className="main" button><IntlText group="pending-orders" id="no-orders-button" /></Link>
        </div>
      </div>
    </div>
  )

  return(
    <div id="pending-purchases">
      <div className="heading no-desc">
        <Title tag="h2"><IntlText group="orders" id="active-orders" /></Title>
        <div className="desc"><IntlText group="orders" id="active-orders-desc" /></div>
      </div>
      <div className="order-alerts">
        <OrderAlerts />
      </div>
      <PurchasesList purchases={myPurchases} refetch={refetch} />
    </div>
  )
}

function OrderAlerts(){

  const { loading, data, error } = useQuery(SETTING_QUERY, {variables: {key: "largeInflow"}})

  if (loading || error) return <Loader />

  const { setting } = data

  if (setting?.value === "true") return(
    <div className="large-inflow warning"><FontAwesome name="exclamation-triangle" /><IntlText group="pending-orders" id="large-inflow" /></div>
  )
    
  return null
}

import React, { useContext, useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import validator from 'validator'

import { Button, LabeledTextarea, IntlText } from '../../components'
import { IntlContext } from '../../contexts'

import './Rating.css'

export default function Rating({value = null, message = "", label, submit}) {

  const { getTranslation } = useContext(IntlContext)
  const element = useRef(null)
  const [show, toggleShow] = useState(false)
  const [loading, toggleLoading] = useState(false)
  const [selected, selectOne] = useState(value)
  const [submitted, toggleSubmit] = useState(!!value)
  const [_message, setMessage] = useState(message || "")

  useEffect(() => {
    function handleClick(e){
      if (element.current?.contains(e.target)) return false
      if (!submitted) selectOne(null)
      return toggleShow(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [submitted])

  function changeSelected(val){
    selectOne(val)
    toggleShow(true)
  }

  async function _submit(data){
    toggleLoading(true)

    if ((selected === 1 || selected === 2) && validator.isEmpty(_message)){
      toggleLoading(false)
      return toast.error(getTranslation({group: "rating", id: "required-message", defaultValue: "Message required on negative feedbacks to help the partners improve their service"}))
    }

    try {
      await submit(selected, _message)
      toggleSubmit(true)
      toggleShow(false)
      toggleLoading(false)
      toast.success(getTranslation({group: "rating", id: "success", defaultValue: "Feedback sent successfull!"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div className="rating" ref={element}>
      {label && <span className="help">{label}</span>}
      <div className="faces">
        <FontAwesome name="frown" className={selected === 1 ? "active" : null} onClick={() => changeSelected(1)}/>
        <FontAwesome name="meh" className={selected === 2 ? "active" : null} onClick={() => changeSelected(2)}/>
        <FontAwesome name="smile" className={selected === 3 ? "active" : null} onClick={() => changeSelected(3)}/>
      </div>
      {show &&
        <div className="rating-form">
          <LabeledTextarea name="message" placeholder={getTranslation({group: "rating", id: "type-here", defaultValue: "Type here your feedback"})} value={_message} onChange={(e) => setMessage(e.target.value)}></LabeledTextarea>
          <Button theme="main" loading={loading} onClick={() => _submit()}><IntlText id="send">Send</IntlText></Button>
        </div>
      }
    </div>
  )
}

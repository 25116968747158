import React, { useContext } from 'react'

import { getImageInSize } from '../../../helpers'
import { CartItem, Title, Link, Loader, IntlText, IntlValue } from '../../../components'
import { PlacePurchase } from '../../../views'
import { CartContext } from '../../../contexts'

import './style.css'

export function CheckoutSummary({loading, error, refetch, purchaseQuote}) {

  const { state: { items, vendor }, removeItem, increaseQty, decreaseQty } = useContext(CartContext)

  return(
    <div id="checkout-summary">
      <div id="cart" className="sticky">
        {loading && <div id="cart-loader"><Loader theme="main" /></div>}
        <div id="cart-inner">
          {vendor.id &&
            <div className="selected-partner">
              <div className="partner-logo">
                <div>
                  <picture>
                    <source srcSet={getImageInSize(vendor.logo, "150", "webp")} type="image/webp" />
                    <source srcSet={getImageInSize(vendor.logo, "150", "png")} type="image/png" />
                    <img src={vendor.logo} alt={vendor.name} title={vendor.name} />
                  </picture>                       
                </div>
              </div>
              <div className="info">
                <Title tag="h2">{vendor.name}</Title>
                <span className="desc"><IntlValue value={vendor.intro} /></span>
                <Link to={`/${vendor.slug}`} className='small' button><IntlText id="view-menu" /></Link>
              </div>
            </div>
          }
          <div id="cart-items">
            {items.map((item, key) =>
              <CartItem key={key} item={item} remove={() => removeItem(key)} increase={() => increaseQty(key)} decrease={() => decreaseQty(key)} />
            )}
          </div>
          <PlacePurchase loading={loading} error={error} refetch={refetch} purchaseQuote={purchaseQuote} />
        </div>
      </div>
    </div>
  )

}

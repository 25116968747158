import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from '../fragments'

const SETTINGS_QUERY = gql`
  query settings($keys: [String]){
    settings(keys: $keys){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`

export default SETTINGS_QUERY

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import './style.css'

function Slider({params, children}){
    
    const _params = {
        slidesPerView: "auto",
        spaceBetween: 15,
        modules: [FreeMode, Mousewheel, Autoplay],
        freeMode: true,
        autoplay: true,
        mousewheel: {forceToAxis: true, releaseOnEdges: false},
        ...params
    }

    return(
        <Swiper className='slider' {..._params}>
            {children}
        </Swiper>                    
    )
}


const slider = { Slider, Slide: SwiperSlide}

export default slider
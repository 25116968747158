import { gql } from '@apollo/client'

export const SAVED_CARDS = gql`
  query savedStripeCards{
     savedStripeCards {
        id
        card{
          brand
          exp_month
          exp_year
          last4
        }
        created
        canBeUsed
        hasExpired
     }
  }
`

import { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { MOST_POPULAR_SEARCHES } from '../../../graphql'
import { useIntl, useDetectOutsideClick } from '../../../hooks'
import { MediaQuery, Title } from '../../../components'

import './style.css'

export function SearchBox(){

  const wrapper = useRef(null)
  const menu = useRef(null)
  const navigate = useNavigate()
  const { search } = useLocation()
  const { getTranslation } = useIntl()
  const searchParams = new URLSearchParams(search)
  const _query = searchParams.get("query") || ''
  const [query, setQuery] = useState(_query)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})

  useEffect(() => {
    setQuery(_query)
  }, [_query])

  function _search(){
    if (!query) {
      clear()
    } else {
      navigate(`/search?query=${query}`)
    }
  }

  function onEnter(e){
    if (e.key !== 'Enter') return false
    
    _search()
  }

  function onClick(str){
    navigate(`/search?query=${str}`)
    setQuery(str)
  }

  function clear(){
    setQuery("")
    searchParams.delete("query")
    navigate({search: searchParams.toString(), replace: true})
  }

  return(
  <>
    <MediaQuery min={1259}>
      <div className='search-box' ref={wrapper}>
        <FontAwesome name='search' className='search-icon' />
        <input className='search-input' type='text' placeholder={getTranslation({group: "search-box", id: "placeholder"})} value={query} onChange={({target}) => setQuery(target.value)} onKeyUp={onEnter} onFocus={() => setIsActive(true)} />
        {query &&
          <FontAwesome name='times-circle' className='go-icon' onClick={clear} />
        }
        {isActive && <PopularSearches menu={menu} onClick={onClick} close={() => setIsActive(false)} />}
      </div>
    </MediaQuery>
    <MediaQuery max={1259}>
      <SearchBoxResponsive />
    </MediaQuery>
  </>
  )
}

  function PopularSearches({menu, onClick, close}){

    const { loading, data, error } = useQuery(MOST_POPULAR_SEARCHES, {variables: {first: 20}})    
    const { getTranslation } = useIntl()
    
    if (loading || error) return null

    return(
      <div className='popular-searches'>
        <Title tag='h3'>
          {getTranslation({group: "search-box", id: "most-popular-searches"})}
          <FontAwesome name='times-circle' onClick={close} />
        </Title>
        <div className='list' ref={menu}>
          {data?.mostPopularSearches?.edges?.map(({node}) => 
            <div className='list-item' key={node.id} onClick={() => onClick(node.term)} >
              <span>{node.term}</span>
            </div>
          )}
        </div>
      </div>      
    )
  }

  function SearchBoxResponsive(){

    const wrapper = useRef(null)
    const menu = useRef(null)
    const navigate = useNavigate()
    const { search } = useLocation()
    const { getTranslation } = useIntl()
    const searchParams = new URLSearchParams(search)
    const _query = searchParams.get("query") || ''
    const [query, setQuery] = useState(_query)
    const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})
    const [isPopularSearchesActice, setPopularSearchesActiceIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})

    useEffect(() => {
      setQuery(_query)
    }, [_query])
  
    function _search(){
      if (!query) {
        clear()
      } else {
        navigate(`/search?query=${query}`)
      }
      setPopularSearchesActiceIsActive(false)
    }
  
    function onEnter(e){
      if (e.key !== 'Enter') return false
      
      _search()
    }    

    function clear(){
      setQuery("")
      searchParams.delete("query")
      navigate({search: searchParams.toString(), replace: true})
    }

    function onClick(str){
      navigate(`/search?query=${str}`)
      setQuery(str)
    } 
    
    return(
      <div className={`search-box-responsive ${isActive ? "show" : ""}`} ref={wrapper} onClick={() => setIsActive(true)}>
        <FontAwesome name='search' className='search-icon' />
        <div className='background' />
        <div className='search-input'>
          <div className='inner'>
            <FontAwesome name='search' className='search-icon' />
            <input type='text' placeholder={getTranslation({group: "search-box", id: "placeholder"})} value={query} onChange={({target}) => setQuery(target.value)} onKeyUp={onEnter} onFocus={() => setPopularSearchesActiceIsActive(true)} />
            {query &&
              <FontAwesome name='times-circle' className='icon' onClick={clear} />
            }            
            {isPopularSearchesActice && <PopularSearches menu={menu} onClick={onClick} close={() => setPopularSearchesActiceIsActive(false)} />}
          </div>
        </div>
      </div>
    )
  }
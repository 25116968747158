import React, { useEffect } from 'react'

import { Home } from '../'

export function AppDetect() {
    useEffect(() => {
        function getOS() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                return "Android";
            }
        
            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
        
            return "unknown";
        }

        switch (getOS()) {
            case "Android":
                window.location.replace(`https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PLAYSTORE_ID}`)    
                break;
            case "iOS":
                window.location.replace(`https://apps.apple.com/mx/app/tomato-mx/id${process.env.REACT_APP_APPSTORE_ID}`)    
                break;
            default:
                break;
            }
    }, [])

    return <Home />
}

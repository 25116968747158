import { useIntl } from '../../../hooks'
import { Filters, IntlText } from '../../../components'

export function ProductFilters({params, removeParam, updateQuery}){

  const { getTranslation } = useIntl()

  return(
    <>
      <Filters.Button id="mustAcceptCard" onClick={() => updateQuery({mustAcceptCard: {value: true, id: "mustAcceptCard", }})} reset={() => removeParam("mustAcceptCard")} active={!!params?.mustAcceptCard?.value}><IntlText group="menu-filter" id="online-payment" /></Filters.Button>
      <Filters.Button id="highRatingVendor" onClick={() => updateQuery({highRatingVendor: {value: true, id: "highRatingVendor"}})} reset={() => removeParam("highRatingVendor")} active={!!params?.highRatingVendor?.value}><IntlText group="menu-filter" id="high-rating" /></Filters.Button>
      <Filters.Button id="canOrderNow" onClick={() => updateQuery({canOrderNow: {value: true, id: "canOrderNow"}})} reset={() => removeParam("canOrderNow")} active={!!params?.canOrderNow?.value}><IntlText group="menu-filter" id="can-order-now" /></Filters.Button>
      <Filters.Button id="onlyWithPhoto" onClick={() => updateQuery({onlyWithPhoto: {value: true, id: "onlyWithPhoto"}})} reset={() => removeParam("onlyWithPhoto")} active={!!params?.onlyWithPhoto?.value}><IntlText group="menu-filter" id="only-with-photo" /></Filters.Button>
      <Filters.Select hideArrow value={!!params?.sortBy?.value ? getTranslation({group: "menu-filter", id: params?.sortBy?.value}) : ""} placeholder={getTranslation({group: "menu-filter", id: "sort-by"})} icon="sort-amount-down" reset={() => removeParam("sortBy")}>
        <Filters.SelectItem active={params?.sortBy?.value === "RELEVANCE"} onClick={() => updateQuery({sortBy: {value: "RELEVANCE", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="relevance" />
        </Filters.SelectItem>
        <Filters.SelectItem active={params?.sortBy?.value === "VENDOR_RATING"} onClick={() => updateQuery({sortBy: {value: "VENDOR_RATING", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="rating" />
        </Filters.SelectItem>
        <Filters.SelectItem active={params?.sortBy?.value === "PRODUCT_PRICE_ASC"} onClick={() => updateQuery({sortBy: {value: "PRODUCT_PRICE_ASC", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="price-asc" />
        </Filters.SelectItem>
        <Filters.SelectItem active={params?.sortBy?.value === "PRODUCT_PRICE_DESC"} onClick={() => updateQuery({sortBy: {value: "PRODUCT_PRICE_DESC", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="price-desc" />
        </Filters.SelectItem>                  
        <Filters.SelectItem onClick={() => removeParam("sortBy")}>
          <IntlText id="reset" />
        </Filters.SelectItem>                
      </Filters.Select>                   
    </>
  )

}

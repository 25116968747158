import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`

export const resolvers = {
  // Query: {
  //   settings: (_root, variables, { cache, getCacheKey }) => {
  //     console.log("root", _root);
  //     console.log("variables", variables);
  //   }
  // }
}

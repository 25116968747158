import React from 'react'

import { Title, IntlText } from '../../components'
import { SelectLocation } from '../../views'

export default function Addresses() {
  return(
    <div id="addresses" className="container">
      <div className="row wrapped">
        <div id="addresses-wrapper">
          <div className="heading">
            <Title tag="h2"><IntlText group="addresses" id="title" /></Title>
            <div className="desc"><IntlText group="addresses" id="desc" /></div>
          </div>
          <div className="content">
            <SelectLocation/>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import './LabeledInput.css'

export default function LabeledInput({id, style, name, type = "text", value = "", children, pattern, placeholder, disabled, autocomplete, onChange, onFocus, onBlur}){
  const [focused, toggle] = useState(false)

  const _onFocus = (e) => {
    if (onFocus) onFocus(e)
    toggle(true)
  }

  const _onBlur = (e) => {
    if (onBlur) onBlur(e)
    toggle(false)
  }

  return(
    <div id={id} className={`labeled-input ${focused ? "focused" : ""} ${value !== "" ? "populated" : ""}`} style={style}>
      <label>
        <input
          name={name}
          type={type}
          value={value || children || ""}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          onFocus={(e) => _onFocus(e)}
          onBlur={(e) => _onBlur(e)}
          onChange={onChange}
          autoComplete={autocomplete}
          />
        <span>{placeholder}</span>
      </label>
    </div>
  )
}

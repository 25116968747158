import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import ReactGA from 'react-ga4'

import client from './client'
import './subscriptions'
import App from './App'
import smoothscroll from 'smoothscroll-polyfill'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'react-toastify/dist/ReactToastify.css'
import './style/index.css'

smoothscroll.polyfill()

window.onbeforeunload = function () {
  window.scrollTo({
    top: 0,
    left: 0
  })
}

if(process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS)
}

ReactDOM.render(
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>,
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()

import { gql } from '@apollo/client'

export const PURCHASE_VENDOR_FRAGMENT = gql`
  fragment PURCHASE_VENDOR_FRAGMENT on PurchaseVendor {
    originalId
    name
    slug
    logo
    cover
    contract
    type
    openingHours{
      from
      to
      isOpen
    }
    description{
      en
      es
    }
  }
`

import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'

import { useForm, useAuth } from '../../../hooks'
import { validatePhonenumber, parsePhone, toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'
import { Form, IntlText, Validation, PhoneNumberInput, Button } from '../../../components'
import { HelpBox } from '../../../views'

const validations = {
    phone: [{id: "required", validation: (val) => !!(val?.phoneNumber && val?.phoneCode)}, {id: "incorrectNumber", validation: (val => validatePhonenumber(val))}],
}

export function AddPhoneNumber({onUpdated}){

    const { user } = useAuth()
    const { getTranslation } = useContext(IntlContext)
    const { form, updateField, errors, validate } = useForm({phone: user?.phone}, validations)    
    const { phone } = form
    const [editProfile, {loading}] = useMutation(EDIT_CUSTOMER_MUTATION)
    const [showHelp, toggleHelp] = useState(false)
    
    async function addPhoneNumber(){
        const alert = toast.loading()
        try {
            const { valid } = validate()
            
            if (!valid) throw new Error(getTranslation({id: "form-error"}))            

            await editProfile({variables:{id: user?.id, data: {phone: {
                phoneCode: phone?.phoneCode,
                phoneNumber: phone?.phoneNumber,
                countryCode: phone?.countryCode,
            }}}})
            alert.success(getTranslation({id: "save-success"}))
            onUpdated && onUpdated()
        } catch (error) {
            toggleHelp(true)
            alert.apolloError(error)
        }
    }

    return(
        <Form.Form>
            <Form.Field>
                <Validation errors={errors.phone}>
                    <PhoneNumberInput name="phone" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(phone)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "phone", value: {countryCode: _data?.countryCode, phoneNumber: _data?.phoneNumber, phoneCode: _data?.phoneCode}})} disablePreferences={true} />
                </Validation>            
            </Form.Field>
            <Form.Field>
                <Button theme="main" fluid loading={loading} onClick={addPhoneNumber}><IntlText id="save" /></Button>
            </Form.Field>
            {showHelp && 
            <Form.Field>
                <HelpBox style={{display: "flex", justifyContent: "center"}} />
            </Form.Field>
            }
        </Form.Form>
    )
}
import React from 'react'

import { IntlValue } from '../../../components'
import { formatPrice } from '../../../helpers'

import './style.css'

export function PurchaseProducts({purchase}) {
  
  return(
    <div className="purchase-products">
      {purchase?.products.map((product, key) =>
        <div key={key} className="purchase-product">
          <div className="product-name-box">
            <span><IntlValue value={product.product.name} /></span>
            <div className="addons">
              {product.addons.map((addon, addonKey) =>
                <div key={addonKey} className="addon">
                  <div className="selected-options">
                    {addon.selectedOptions.map((selectedOption, selectedOptionKey) =>
                      <div key={selectedOptionKey} className="selected-option">
                        <span>{selectedOption.quantity || 1}</span>
                        <span style={{marginLeft: 3, marginRight: 3}}>x</span>
                        <span><IntlValue value={selectedOption.name} /></span>
                        {selectedOption.price > 0 &&
                          <div className="price-wrapper">
                            {formatPrice(selectedOption.price)}
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="product-price-box">
            <span className="qty">{product.quantity}</span> x <span className="price">{formatPrice(product.totalPricePerProduct)}</span>
          </div>
        </div>
      )}
    </div>
  )
}

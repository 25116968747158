import { gql } from '@apollo/client'

export const REQUEST_CUSTOMER_VERIFICATION_CODE = gql`
  mutation requestCustomerVerificationCode($customer: ID, $method: CEL_VERIFICATION_METHOD!){
     requestCustomerVerificationCode(customer: $customer, method: $method) {
        inSession
        cel{
            countryCode
            phoneCode
            phoneNumber
        }
        status
        lastWhatsappIntent  
        nextWhatsappIntent
        whatsappIntentCount
        lastSMSIntent
        nextSMSIntent
        SMSIntentCount
        uid
     }
  }
`

import { gql } from '@apollo/client'

import { ADDRESS_FRAGMENT } from './'

export const ADDRESS_QUERY = gql`
  query address($id: ID){
    address(id: $id){
      ...ADDRESS_FRAGMENT
    }
  }
  ${ADDRESS_FRAGMENT}
`

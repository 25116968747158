import React from 'react'
import FontAwesome from 'react-fontawesome'

import { useEditPurchase, useModal, useIntl } from '../../../../hooks'
import { Toggle, IntlText, Loader, SelectModal } from '../../../../components'

export function AskChange({purchaseQuote}) {
  
  const modal = useModal()
  const { editPurchaseQuote, loading } = useEditPurchase({})
  const { getTranslation } = useIntl()
  const checked = !!purchaseQuote?.banknote
  const bills = [
    {id: "1000", label: getTranslation({group: "cod-options", id: "1000"})}, 
    {id: "500", label: getTranslation({group: "cod-options", id: "500"})}, 
    {id: "200", label: getTranslation({group: "cod-options", id: "200"})}, 
    {id: "0", label: getTranslation({group: "cod-options", id: "0"})}
  ]

  function select(selected){
    editPurchaseQuote({banknote: parseInt(selected.id)})
    modal.hide()
  }

  return(
    <div id="ask-change" className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'><FontAwesome name="coins" /></span>
      <div className="info">
        <div className="texts">
          <div className="desc"></div>
          <div><IntlText group="cod-options" id="need-change" /></div>
        </div>
        {checked ?
          <div className='checkout-option-value' onClick={() => modal.show()}>
            <div className='placeholder'><IntlText group="cod-options" id="select-bills" /></div>            
            <div className='value'>
              <span><IntlText group='cod-options' id={purchaseQuote?.banknote} /></span>
              <FontAwesome name="edit" />
            </div>
          </div>
        :
          <Toggle id="bill" checked={checked} onChange={() => modal.show()} />
        }
        <SelectModal modal={modal} selected={bills.find(_bill => parseInt(_bill.id) === purchaseQuote?.banknote)} defaultValue={<IntlText group="cod-options" id="nothing-selected" />} placeholder={<IntlText group="cod-options" id="select-bills" />} options={bills} onChange={select} />
      </div>
    </div>   
  )

}

import { gql } from '@apollo/client'

const GET_FACEBOOK_DATA = gql`
  query getFacebookData {
    email @client
    name @client
    facebookID @client
  }
`

export default GET_FACEBOOK_DATA

import React from 'react'

import { Title, IntlValue, IntlText } from '../../components'
import { MenuItem } from './'

export default function MenuCategory({vendor, category: {category, category: {slug, name, description, disabled, isAvailable, availability}, products}}) {
    return(
    <div id={"category-" + slug} className="menu-category">
      <div className="menu-category-header">
        <div className="heading">
          <Title tag="h2">
            <IntlValue value={name} />
            {availability && (availability.from || availability.to) &&
              <div className="availability-time">
                <span><IntlText group="menu-category" id="category-available">(available </IntlText></span>
                <span>{availability.from && `from ${availability.from.substring(0,2)}:${availability.from.substring(2)} `}</span>
                <span>{availability.to && `to ${availability.to.substring(0,2)}:${availability.to.substring(2)}`}</span>
                <span>)</span>
              </div>
            }
          </Title>
          <div className="desc"><IntlValue>{description}</IntlValue></div>
        </div>
      </div>
      <div className="menu-items">
        {products.map((product, key) =>
          <MenuItem key={product.id} item={product} vendor={vendor} category={category} categoryDisabled={disabled || !isAvailable}/>
        )}
      </div>
    </div>
    )
}

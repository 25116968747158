import { toast } from 'react-toastify'

function loading(string){
    const toastID = toast.loading(string || "Please wait...", {autoClose: 3000})

    function success(_string){
        toast.update(toastID, { render: _string, type: "success", isLoading: false, autoClose: 3000 })
    }

    function error(_string){
        toast.update(toastID, { render: _string, type: "error", isLoading: false, autoClose: false, closeOnClick: true })
    }

    function hide(_string){
        toast.dismiss(toastID)
    }
    
    function apolloError(error){
        if (error.graphQLErrors?.length > 0){
            error.graphQLErrors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: false, closeOnClick: true })) 
        } else if(error.networkError?.result?.errors) {
            error.networkError.result.errors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: false, closeOnClick: true })) 
        } else {
            toast.update(toastID, { render: error.message, type: "error", isLoading: false, autoClose: false, closeOnClick: true })
        }        
    }

    return {
        toastID,
        success,
        error,
        apolloError,
        hide
    }
}

function apolloError(error){
    if (error.graphQLErrors?.length > 0){
        error.graphQLErrors.map(x => toast.error(x.message, {autoClose: false, closeOnClick: true})) 
    } else if(error.networkError?.result?.errors) {
        error.networkError.result.errors.map(x => toast.error(x.message, {autoClose: false, closeOnClick: true})) 
    } else if (error?.message){
        toast.error(error.message, {autoClose: false, closeOnClick: true})
    } else {
        toast.error(error, {autoClose: false, closeOnClick: true})
    }
}

const _toast = {
    ...toast,
    apolloError,
    loading
}

export default _toast

import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const CREATE_PURCHASE_MUTATION = gql`
  mutation createPurchase($data:PurchaseInput!){
    createPurchase(data:$data){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`

import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import QRCode from 'react-qr-code'

import config from '../../../config'
import { useAuth, useIntl } from '../../../hooks'
import { CREATE_REFERRAL_CODE } from '../../../graphql'
import { toast, copyToClipboard } from '../../../helpers'
import { Title, IntlText, LabeledInput, Button } from '../../../components'

import referral from './referral.svg'

import './style.css'

export function ReferralCode() {

  const { getTranslation } = useIntl()
  const { loading, user, error } = useAuth()
  const [createReferralCode, {loading: createLoading}] = useMutation(CREATE_REFERRAL_CODE)
  const [referralCode, changeReferralCode] = useState(user?.referrerCode)

  useEffect(() => {
    changeReferralCode(user?.referrerCode)
  }, [user?.referrerCode])

  async function _createReferralCode(){
    const alert = toast.loading()
    try {
      await createReferralCode({variables: {code: referralCode}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  // Helper function to load an image
  function loadImage(image) {
    return new Promise(function (resolve, reject) {
      image.onload = resolve;
      image.onerror = reject;
    });
  }  

  function downloadQR(){
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image()
    const referralSvg = new Image()
    
    referralSvg.src = referral
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
    canvas.width = 800;
    canvas.height = 954;    
    
    // Wait for both SVG files to load
    Promise.all([loadImage(img), loadImage(referralSvg)])
    .then(function () {
      // Draw the first SVG on the canvas
      ctx.drawImage(referralSvg, 0, 0);
      
      // Draw the second SVG on the canvas
      var svg1X = canvas.width / 2 - 220 / 2 - 3;
      var svg1Y = canvas.height / 2 - 220 / 2 - 40;      
      ctx.drawImage(img,  svg1X, svg1Y, 220, 220);
      
      // Save the merged canvas as an image (optional)
      const mergedImage = canvas.toDataURL('image/png');
      const downloadLink = document.createElement("a");
      
      downloadLink.download = "qrcode";
      downloadLink.href = `${mergedImage}`;
      downloadLink.click();    
    })
    .catch(function (error) {
      console.error('Error loading SVG files:', error);
    })

  }

  function copyLink(){
    copyToClipboard(`${config.home}/delivery-service?referral=${user?.referrerCode}`)
  }

  if (loading || error) return null

  return(
    <>
      <Title tag="h3" styled><IntlText group="referred-customers" id="generate-your-code"/></Title>
      <div id="referral-code">
        {user?.referrerCode &&
          <QRCode id="qrcode" size={160} value={`${config.home}/delivery-service?referral=${user?.referrerCode}`} />
        }
        <div style={{display: "flex", gap: 15, flexDirection: "column"}}>
          <LabeledInput value={referralCode} placeholder={getTranslation({group: "referred-customers", id: "code"})} onChange={({target}) => changeReferralCode(target.value)} />
          <Button onClick={_createReferralCode} loading={createLoading}>
            {user?.referrerCode ? 
              <IntlText group="referred-customers" id="edit-code"/>
              :
              <IntlText group="referred-customers" id="create-code"/>
            }
          </Button>
          {user?.referrerCode &&
            <>
              <Button onClick={downloadQR}>
                <IntlText group="referred-customers" id="download-code"/>
              </Button>
              <Button onClick={copyLink}>
                <IntlText group="referred-customers" id="copy-code"/>
              </Button>
            </>
          }
        </div>
      </div>
    </>
  )
}

import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const EDIT_PURCHASE_QUOTE = gql`
  mutation createOrEditPurchaseQuote($data: PurchaseQuoteInput!){
    createOrEditPurchaseQuote(data: $data){
      ...PURCHASE_QUOTE_FRAGMENT
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`

import React from 'react'

import { IntlText } from '../../components'
import { formatPrice } from '../../helpers'

import './DeliveryFeeDetails.css'

export default function DeliveryFeeDetails({fees: {deliveryDistance, deliveryFeeBase, deliveryFeeDistance}}) {

  return(
    <table id="delivery-fee-details">
      <tbody>
        <tr>
          <td><IntlText group="delivery-fee-details" id="delivery-distance">Delivery distance</IntlText></td>
          <td>{`${deliveryDistance.toFixed(2)} km`}</td>
        </tr>
        <tr>
          <td><IntlText group="delivery-fee-details" id="delivery-fee-base">Delivery fee base</IntlText></td>
          <td>{formatPrice(deliveryFeeBase)}</td>
        </tr>
        <tr>
          <td><IntlText group="delivery-fee-details" id="delivery-fee-distance">Delivery fee distance</IntlText></td>
          <td>{formatPrice(deliveryFeeDistance)}</td>
        </tr>
      </tbody>
    </table>
  )
}

import { gql } from "@apollo/client"

import { CUSTOMER_FRAGMENT } from './'

export const LOGIN_WITH_ONETIME_PASSWORD_MUTATION = gql`
  mutation loginWithOneTimePassword($uid: String!, $password:String!){
    loginWithOneTimePassword(uid: $uid, password:$password, role:CUSTOMER){
      token
      user{...CUSTOMER_FRAGMENT}
    }
  }
  ${CUSTOMER_FRAGMENT}
`

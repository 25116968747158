import React from 'react'

import './OrderSkeleton.css'

export default function OrderSkeleton() {

  return(
    <div className="order-skeleton">
      <div className="order">
        <div className="order-info-bar">
          <div className="order-vendor-box">
            <div className="vendor-logo">
              <div><div className="skeleton-thumb" /></div>
            </div>
            <div className="vendor-name">
              <div className="line" />
              <div className="line" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

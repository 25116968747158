import { gql } from "@apollo/client"

import { CUSTOMER_FRAGMENT } from '../'

const FACEBOOK_REGISTER_MUTATION = gql`
  mutation register($data: CustomerInput!, $email: String!, $facebookID: String!){
    createCustomer(data: $data){
      id
    }

    facebookLogin(email: $email, facebookID:$facebookID){
      token
      user{...CUSTOMER_FRAGMENT}
    }
  }
  ${CUSTOMER_FRAGMENT}
`

export default FACEBOOK_REGISTER_MUTATION

import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm, useAuth, useIntl } from '../../hooks'
import { toast } from '../../helpers'
import { Title, IntlText, LabeledInput, Validation, Button, Loader } from '../../components'
import { EDIT_CUSTOMER_MUTATION } from '../../graphql'

import './style.css'

export function ChangePassword() {

  const validations = {
    password: [{id: "required", validation: (val) => !!val}],
    password2: [{id: "equal", validation: (val, form) => val === form.password}],
  }

  const { getTranslation } = useIntl()
  const { loading, user, error } = useAuth()
  const navigate = useNavigate()
  const [editProfile, {loading: editLoading}] = useMutation(EDIT_CUSTOMER_MUTATION)
  const { form: {password, password2}, updateField, errors, validate } = useValidatedForm({}, validations)

  if (loading) return <Loader />
  if (error) return `Error! ${error.message}`

  async function submit(){
    let { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await editProfile({variables: {id: user?.id, data: {password}}})
      toast.success(getTranslation({group: "change-password", id: "success"}))
      navigate("/delivery-service")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="change-password" className="container">
      <div className="row wrapped">
        <div id="change-password-form-wrapper" className="form-wrapper">
          <div className="heading">
            <Title tag="h2"><IntlText group="change-password" id="title" /></Title>
            <div className="desc"><span><IntlText group="change-password" id="desc" /></span></div>
            <div id="change-password-form">
              <Validation errors={errors.password}>
                <LabeledInput name="password" type="password" placeholder={getTranslation({group: "change-password", id: "input-password"})} value={password} onChange={(e) => updateField({key: [e.target.name], value: e.target.value})}/>
              </Validation>
              <Validation errors={errors.password2}>
                <LabeledInput name="password2" type="password" placeholder={getTranslation({group: "change-password", id: "input-password2"})} value={password2} onChange={(e) => updateField({key: [e.target.name], value: e.target.value})}/>
              </Validation>
              <div className="actions">
                <Button theme="main" className="full" loading={editLoading} onClick={() => submit()}><IntlText group="change-password" id="submit" /></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import FontAwesome from 'react-fontawesome'
import { NavLink, Link as DefaultLink } from 'react-router-dom'

import { Loader } from '../'

import './style.css'

export function Link({className, activeClassName, theme = "ui", loading, button, children, icon, external, plain, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  if (!rest.to) return null

  if (external) return (
      <a href={rest.to} target="_blank" rel="noreferrer" className={`link ${className || ""} ${button ? "button" : ""} ${icon ? "icon" : ""}`} {...rest}>
        {icon && showIcon()}
        {children}
      </a>
  )  

  if (plain) return(
    <DefaultLink {...rest} className="link plain">
      {children}      
    </DefaultLink>
  )

  return(
    <NavLink  className={`${className || ""} ${button ? "button" : "link"} ${icon ? "icon" : ""} ${theme} ${activeClassName ? activeClassName : ''}`} {...rest}>
      {icon && showIcon()}
      <>{loading && <Loader />}{children}</>
    </NavLink>
  )

}

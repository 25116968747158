import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Title, IntlValue, IntlText } from '../../components'
import { calculateItemPrice, formatPrice } from '../../helpers'

export default function CartItem({item, item: { addons, quantity, product, instructions, disabled}, increase, decrease, remove, onClick}) {

  return(
    <div className="cart-item" onClick={onClick}>
      <div className="qty">
        <div>
          <span><FontAwesome name="chevron-up" onClick={increase}/></span>
          <span>{quantity}</span>
          <span><FontAwesome name="chevron-down" onClick={decrease}/></span>
        </div>
      </div>
      <div className="item-info">
        <Title tag="h3"><IntlValue value={product.name} /></Title>
        {disabled && <span className="not-available"><IntlText group="menu-item" id="not-available" /></span>}
        <div className="selected-options">
          {addons && addons.length > 0 &&
            <div className="addons">
              {addons.map((addon, addonKey) =>
                <div key={addonKey} className="addon">
                  <div className="selected-options">
                    {addon.selectedOptions.map((selectedOption, selectedOptionKey) =>
                      <div key={selectedOptionKey} className="selected-option">
                        <span className='quantity'>{selectedOption.quantity || 1} x </span>
                        <span><IntlValue>{selectedOption.name}</IntlValue></span>
                        {selectedOption.price > 0 &&
                          <span className="price"> - {formatPrice(selectedOption.price)}</span>
                        }
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          }
        </div>
        {instructions &&
          <div className="instructions">
            {instructions}
          </div>
        }
      </div>
      <div className="price">
        <span>{formatPrice(calculateItemPrice(item))}</span>
        {product.freeDeliveryAvailable && <div className="free-delivery-badge">Free delivery</div>}
        <FontAwesome name="trash" onClick={remove}/>
      </div>
    </div>
  )
}

import FontAwesome from 'react-fontawesome'

import { useCart, useModal, useAuth, useIntl, useEditPurchase } from '../../../hooks'
import { validateEmail } from '../../../helpers'
import { IntlText, Loader, Button, Map, MapPin, EditBox, Notificaion } from '../../../components'
import { SelectLocationModal } from '../../../views'

import './style.css'

export function CustomerConfirm({purchaseQuote}){

  const modal = useModal()
  const { getTranslation, getTranslatedValue } = useIntl()
  const { state: { deliveryLocation }} = useCart()
  const { loading, user, error, editUser } = useAuth()
  const { editPurchaseQuote } = useEditPurchase({})
  const contactOptions = [
    {id: "WHATSAPP", label: getTranslation({group: "order-info", id: "WHATSAPP"})},
    {id: "PHONECALL", label: getTranslation({group: "order-info", id: "PHONECALL"})},
    {id: "DOORBELL", label: getTranslation({group: "order-info", id: "DOORBELL"})},
  ]

  if (loading || error) return <Loader />

  return(
    <div className="checkout-customer">
      {purchaseQuote?.type !== "CUSTOMER_PICKUP" ?
        <div className='selected-map'>
          <div className='map-actions'>
            <Button onClick={() => modal.show()}><IntlText group="customer-confirm" id="change-location" /></Button>
          </div>
          <Map center={{lng: deliveryLocation.location.coordinates[0], lat: deliveryLocation.location.coordinates[1]}} options={{draggable: false, gestureHandling: false}}>
            <MapPin lng={deliveryLocation.location.coordinates[0]} lat={deliveryLocation.location.coordinates[1]} {...deliveryLocation.location.coordinates}><FontAwesome name="home" /></MapPin>
          </Map>      
          <SelectLocationModal modal={modal} />
        </div>
      : 
        <>
          <div className='selected-map'>
            {purchaseQuote?.pickupLocation?.coordinates ? 
              <Map center={{lng: purchaseQuote?.pickupLocation?.coordinates[0], lat: purchaseQuote?.pickupLocation?.coordinates[1]}} >
                <MapPin lng={purchaseQuote?.pickupLocation?.coordinates[0]} lat={purchaseQuote?.pickupLocation?.coordinates[1]}><FontAwesome name="utensils-alt" /></MapPin>
              </Map>          
            : null}
          </div>
          <Notificaion type='ui' icon="info-circle" title={getTranslation({group: "customer-confirm", id: "pickup-location"})}>
            <span>{getTranslation({group: "customer-confirm", id: "pickup-location-desc"})}</span>
          </Notificaion>          
        </>
      }
      {purchaseQuote?.type === "CUSTOMER_PICKUP" ? 
        <EditBox.Textv2 icon="info" value={getTranslatedValue(purchaseQuote?.pickupPurchaseInstructions)}>{getTranslation({group: "customer-confirm", id: "pickup-instructions"})}</EditBox.Textv2>        
      : null}
      <EditBox.Textv2 icon="address-card" value={user?.name} save={(value) => editUser({data: {name: value}})}>{getTranslation({id: "input-name"})}</EditBox.Textv2>        
      <EditBox.Textv2 icon="envelope" value={user?.email} save={(value) => editUser({data: {email: value}})} validation={{validate: (val) => validateEmail(val), error: getTranslation({group: "validation", id: "email"})}}>{getTranslation({id: "input-email"})}</EditBox.Textv2>        
      <EditBox.Phone icon="phone" value={user?.cel} save={(value) => editUser({data: {cel: value}})}>{getTranslation({id: "input-phone"})}</EditBox.Phone>        
      {purchaseQuote?.type !== "CUSTOMER_PICKUP" ?
        <>
          <EditBox.Select id="contactPreference" icon="user-headset" value={contactOptions.find(option => option.id === purchaseQuote?.contactPreference) || contactOptions[0]} loading={loading} save={(selected) => editPurchaseQuote({contactPreference: selected?.id})} options={contactOptions}>{getTranslation({group: "place-purchase", id: "contact-preference"})}</EditBox.Select>        
          <EditBox.TextArea icon="info" value={purchaseQuote?.customerNote} save={(value) => editPurchaseQuote({customerNote: value})} note={getTranslation({group: "place-purchase", id: "note"})}>{getTranslation({group: "place-purchase", id: "instructions"})}</EditBox.TextArea>        
        </>
      : null}
      {!!purchaseQuote?.customerNote &&
        <div>
          <div className='warning'>
            <span>{getTranslation({group: "place-purchase", id: "note"})}</span>
          </div>
        </div>
      }  
    </div>
  )
}

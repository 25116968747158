import React from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import { IntlText } from '../'

import './style.css'

export function RangeSlider({value = 0, step = 1, onChange, min = 0, max = 100}){

  const _value = Math.round(value*100)

  function _onChange(val){
    if (val < min) return toast.error(<IntlText group="range-slider" id="min-error" />)
    if (val > max) return toast.error(<IntlText group="range-slider" id="max-error" />)
    onChange(val/100)
  }

  return(
    <div className="range-slider">
      <button type='button' onClick={() => _onChange(parseInt(_value)-step)}><FontAwesome name="minus" /></button>
      <input type="range" min="0" max="100" value={_value} className="range-slider" id="rider-tip" onChange={(e) => _onChange(parseInt(e.target.value))}/>
      <button type='button' onClick={() => _onChange(parseInt(_value)+step)}><FontAwesome name="plus" /></button>
      <div className="value">
        <span>{_value}</span>
        <span>%</span>
      </div>
    </div>
  )

}

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { LoginForm } from '../../views'

export default function Login() {

  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.id) navigate('/delivery-service')
    // eslint-disable-next-line
  }, [user])

  return(
    <div id="login" className="container">
      <div className="row wrapped">
        <LoginForm />
      </div>
    </div>
  )
}

import React from 'react'

import './style.css'

export function PartnerCardSkeleton() {
  return(
    <div>
      <div className="partner-card-skeleton">
        <div><div className="skeleton-thumb" /></div>
        <div className="info">
          <div className="line" />
          <div className="line" />
        </div>
      </div>
    </div>
  )
}

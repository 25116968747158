import { useMutation } from '@apollo/client'

import { toast } from '../helpers'
import { EDIT_PURCHASE_QUOTE, PURCHASE_QUOTE_QUERY } from '../graphql'

export function useEditPurchase({callback}){
    const [editPurchaseQuote, { loading }] = useMutation(EDIT_PURCHASE_QUOTE, {update: (cache, { data: { createOrEditPurchaseQuote } }) => {
        cache.writeQuery({
            query: PURCHASE_QUOTE_QUERY,
            data: { purchaseQuote: createOrEditPurchaseQuote},
        })
    }})

    async function _editPurchaseQuote(data){
        try {
            await editPurchaseQuote({variables: {data: {flow: "V1", ...data}}})
            if (callback) callback()
        } catch (error) {
            toast.error(error.message)
        }
      }        

    return {
        editPurchaseQuote: _editPurchaseQuote,
        loading
    }
}

import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { ME_QUERY, IS_LOGGED_IN } from '../graphql'
import { AuthKit } from '../helpers'

const AuthContext = React.createContext()

function AuthContextProvider({ children }){
    const isLoggedIn = useQuery(IS_LOGGED_IN)
    const { loading, data, error, refetch } = useQuery(ME_QUERY, {fetchPolicy: "network-only", skip: !isLoggedIn?.data?.isLoggedIn, onError: () => {
        AuthKit.logout()
    }})

  useEffect(() => {
    if (isLoggedIn?.data?.isLoggedIn) refetch()
// eslint-disable-next-line
  }, [isLoggedIn?.data?.isLoggedIn])  

  return(
    <AuthContext.Provider value={{
        loading,
        isLoggedIn: isLoggedIn?.data?.isLoggedIn,
        user: data?.me,
        error
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthContextProvider }

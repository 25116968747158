import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const PURCHASE_QUOTE_QUERY = gql`
  query purchaseQuote($data: PurchaseQuoteInput!){
    purchaseQuote(data: $data){
      ...PURCHASE_QUOTE_FRAGMENT
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`

import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const PAGINATED_VENDORS_LIST = gql`
  query paginatedVendorList($type: VENDOR_LIST_TYPE!, $connectionArgs: ConnectionArgs!){
    paginatedVendorList(type: $type, connectionArgs: $connectionArgs){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VENDOR_FRAGMENT}
`

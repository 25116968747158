import { useForm, useIntl } from '../../hooks'
import { sleep, toast } from '../../helpers'
import { Title, IntlValue, Text, LabeledInput, Form, Button, Validation } from '../../components'

export function DataDeleteRequest({page}) {

  const validations = {
    email: [{id: "required", validation: (val) => !!val}],
    phone: [{id: "required", validation: (val) => !!val}],
  }
  const { selectedLanguage, getTranslation } = useIntl()
  const { form: { email, phone }, updateField, validate, errors } = useForm({}, validations)
  
  if (!page) return null

  const { title, description, content, template } = page
  let text = content ? content[selectedLanguage.shortcode] : ""

  async function submit(){
    const alert = toast.loading()
    try {
      const { valid } = validate()
  
      if (!valid) throw new Error(getTranslation({id: "form-error"}))
  
      await sleep(1500)
  
      alert.success(getTranslation({group: "data-delete-request", id: "success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <div id="page" className={`container ${template}`}>
      <div className="row wrapped">
        <div className="row-header">
          <div className="heading">
            <Title tag="h2"><IntlValue>{title}</IntlValue></Title>
            <div className="desc"><IntlValue>{description}</IntlValue></div>
          </div>
        </div>
        <Text>{text}</Text>      
        <Form.Form>
          <Form.Field>
            <Validation errors={errors["email"]}>
              <LabeledInput name="email" placeholder={getTranslation({group: "data-delete-request", id: "email"})} value={email} onChange={({target}) => updateField({key: target.name, value: target.value})} />
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors["phone"]}>
              <LabeledInput name="phone" placeholder={getTranslation({group: "data-delete-request", id: "phone"})} value={phone} onChange={({target}) => updateField({key: target.name, value: target.value})} />
            </Validation>
          </Form.Field>
          <Form.Field>
            <Button onClick={submit} fluid theme='main'>{getTranslation({group: "data-delete-request", id: "submit"})}</Button>
          </Form.Field>
        </Form.Form>
      </div>
    </div>
  )

}

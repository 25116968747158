import { gql } from '@apollo/client'

export const PURCHASE_PRICE_FRAGMENT = gql`
  fragment PURCHASE_PRICE_FRAGMENT on PurchasePrice {
    subtotal
    serviceFeeCustomer
    discount
    deliveryFeeBase
    deliveryFeeDistance
    deliveryFeeTotal
    transactionFee
    tax
    total
    commissionFreeTotal
    currency
    applicableExchangeRate
    totalInCurrency
    grossTip
    customerCashBalanceAdjustment
    chargeInCash
    chargeOnCard
    cashChargeInCurrency
  }
`

import { useIntl } from '../../hooks'
import { Title, IntlValue, Text } from '../../components'

import { BecomeRider } from './BecomeRider'
import { BecomePartner } from './BecomePartner'
import { ShareAndSave } from './ShareAndSave'
import { DataDeleteRequest } from './DataDeleteRequest'

import './style.css'

export function Page({page}) {

  const { selectedLanguage } = useIntl()
  
  if (!page) return null

  const { title, description, content, template } = page
  let text = content ? content[selectedLanguage.shortcode] : ""

  function renderPage(){
    switch (template) {
      case "RIDE_WITH_US":
        return <BecomeRider page={page} />
      case "BECOME_PARTNER":
        return <BecomePartner page={page} />
      case "SAVE_AND_SHARE":
        return <ShareAndSave page={page} />
      case "DATA_DELETE_REQUEST":
        return <DataDeleteRequest page={page} />
      default:
        return(
          <div id="page" className={`container ${template}`}>
            <div className="row wrapped">
              <div className="row-header">
                <div className="heading">
                  <Title tag="h2"><IntlValue>{title}</IntlValue></Title>
                  <div className="desc"><IntlValue>{description}</IntlValue></div>
                </div>
              </div>
                <Text>{text}</Text>      
            </div>
          </div>
        )
    }
  }

  return renderPage()

}

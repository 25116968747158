export function getImageInSize(_url, _size, format = "png"){
    try {
        if (!_url) return null
        const _link = _url.split("/")
        if (!_link?.length) return null
        const _base = _link.slice(0, -1).join("/")
        const _filename = _link[_link.length - 1].split(".")
    
        return `${_base}/${_filename[0]}_${_size}.${format}`        
    } catch (error) {
        console.log("Get image url error: ", error)
    }

}
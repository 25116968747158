import React from 'react'

import { Title } from '../../components'

import './style.css'

function Box({children}) {

  return(
    <div className='icon-box'>
        {children}
    </div>
  )
}

function Item({title, description, image, children}) {

  return(
    <div className='item'>
        <img src={image} alt={title} />
        <Title tag='h3'>{title}</Title>
        <div className='info'>{description}</div>
        {children}
    </div>
  )
}

const components = { Box, Item }

export default components

import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { returnGraphqlError, formatPrice } from '../../helpers'
import {  IntlText, Loader } from '../../components'
import {  INVOICE_REQUEST_QUERY } from '../../graphql'

import './OrderInvoices.css'

export default function OrderInvoices({purchase}) {

  const { loading, data, error } = useQuery(INVOICE_REQUEST_QUERY, {variables: {purchase: purchase.id}})

  if (loading) return <Loader theme="main" />
  if (error) return returnGraphqlError(error)

  let { invoiceRequest } = data

  if (!invoiceRequest) return(
    <div id="order-invoices">
      <IntlText group="order-invoices" id="no-invoice-requested" />
    </div>
  )

  let { status, customer, email, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem, rfc, createdAt, amount, downloadlink } = invoiceRequest

  return(
    <div id="order-invoices">
      <p><IntlText group="order-invoices" id="invoice-details-title" /></p>
      <table className="table full">
        <tbody>
          <tr>
            <th><IntlText group="request-invoice" id="name" /></th>
            <td>{customer.name}</td>
          </tr>
          <tr>
            <th><IntlText group="request-invoice" id="rfc" /></th>
            <td>{rfc}</td>
          </tr>
          <tr>
            <th><IntlText group="request-invoice" id="email" /></th>
            <td>{email}</td>
          </tr>
          {street &&
            <tr>
              <th><IntlText group="request-invoice" id="street" /></th>
              <td>{street}</td>
            </tr>
          }
          {exterior &&
            <tr>
              <th><IntlText group="request-invoice" id="exterior" /></th>
              <td>{exterior}</td>
            </tr>
          }
          {interior &&
            <tr>
              <th><IntlText group="request-invoice" id="interior" /></th>
              <td>{interior}</td>
            </tr>
          }
          {neighborhood &&
            <tr>
              <th><IntlText group="request-invoice" id="neighborhood" /></th>
              <td>{neighborhood}</td>
            </tr>
          }
          {city &&
            <tr>
              <th><IntlText group="request-invoice" id="city" /></th>
              <td>{city}</td>
            </tr>
          }
          {zip &&
            <tr>
              <th><IntlText group="request-invoice" id="zip" /></th>
              <td>{zip}</td>
            </tr>
          }
          {municipality &&
            <tr>
              <th><IntlText group="request-invoice" id="municipality" /></th>
              <td>{municipality}</td>
            </tr>
          }
          {state &&
            <tr>
              <th><IntlText group="request-invoice" id="state" /></th>
              <td>{state}</td>
            </tr>
          }
          {taxSystem &&
            <tr>
              <th><IntlText group="request-invoice" id="taxSystem" /></th>
              <td>{taxSystem}</td>
            </tr>
          }
          <tr>
            <th><IntlText group="order-invoices" id="requested-at" /></th>
            <td>{moment(createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
          </tr>
          <tr>
            <th><IntlText group="order-invoices" id="status" /></th>
            <td>{status}</td>
          </tr>
          <tr>
            <th><IntlText group="order-invoices" id="invoice-amount" /></th>
            <td>{formatPrice(amount)}</td>
          </tr>
          <tr>
            <th><IntlText group="order-invoices" id="download" /></th>
            <td>{downloadlink ? <a href={downloadlink}><IntlText group="order-invoices" id="download-here" /></a> :  <span><IntlText group="order-invoices" id="pending" /></span>}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )

}

import { gql } from '@apollo/client'

export const VENDOR_FRAGMENT = gql`
  fragment VENDOR_FRAGMENT on Vendor {
    id
    name
    slug
    logo
    cover
    wallpaper
    contract
    type
    rank
    tags
    intro{
      en
      es
    }
    isOpen
    promotions{
      id
      slug
      name
      description{
        en
        es
      }
      vendorDescription
      label{
        en
        es
      }
      type
      restrictions{
        key
        value
      }
      level
      active
    }
    hidden
    suspended
    technicalBreak
    slow
    onVacation
    serviceFeeCoverage
    bankCardPaymentsAllowed
    bankCardPaymentsAccepted
    productTypes
    utensilsAvailable
    numOfFeedbacks
    percentageOfPositive
    openingHours{
      from
      to
      isOpen
    }
    intro{
      en
      es
    }
    description{
      en
      es
    }
    performanceRank
  }
`

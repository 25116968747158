import { gql } from '@apollo/client'

import { ADDRESS_FRAGMENT } from './'

export const MY_ADDRESSES_QUERY = gql`
  query myAddresses{
    myAddresses{
      ...ADDRESS_FRAGMENT
    }
  }
  ${ADDRESS_FRAGMENT}
`

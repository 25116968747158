import React from 'react'

import { IntlText } from '../../../components'

export function DeliveryFee({vendor}) {

  if (vendor.contract === "FREEDELIVERY" || (vendor.tags && vendor.tags.includes("freeDelivery"))) return(
    <div className="icon-info main">
      <span className="amount"><IntlText group="delivery-fee" id="free-delivery" /></span>
    </div>
  )

  if (vendor.tags && vendor.tags.includes("freeDeliveryOptions")) return(
    <div className="icon-info main">
      <span className="amount"><IntlText group="delivery-fee" id="free-delivery-options" /></span>
    </div>
  )

  return null
  // return(
  //   <div className="icon-info">
  //     <FontAwesome name="motorcycle" />
  //     <span className="amount">{formatPrice(30)}</span>
  //   </div>
  // )

}

import React, { useState } from 'react'
import { node } from 'prop-types'

const SlideInContext = React.createContext()
const SlideInConsumer = SlideInContext.Consumer

function SlideInProvider({children}) {

  const [menu, toggleMenu] = useState(null)

  function isAnyOpened(){
    return !!menu
  }

  function isOpened(id){
    return id === menu
  }

  function openMenu(id){
    toggleMenu(id)
  }
  
  function closeMenu(){
    toggleMenu(null)
  }
  
  function _toggleMenu(id){
    if (isOpened(id)) return closeMenu()
    openMenu(id)
  }

  return (
    <SlideInContext.Provider
      value={{
        isAnyOpened,
        isOpened,
        openMenu,
        closeMenu,
        toggleMenu: _toggleMenu
      }}
    >
      {children}
    </SlideInContext.Provider>
  )
}

SlideInProvider.propTypes = {
  children: node.isRequired,
}

export { SlideInContext, SlideInProvider, SlideInConsumer }

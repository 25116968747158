export function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function pad(number){
  return ('0' + number).slice(-2)
}

export function getSetting(key, settings){
  let ret = settings.filter(setting => setting.key === key) && settings.filter(setting => setting.key === key).length > 0 ? settings.filter(setting => setting.key === key)[0] : null

  return ret
}

export function extractSettings(settings){
  try {
    if (!settings?.length > 0) return {}
  
    settings = settings.reduce((current, next) => {
      switch (next.type) {
        case "BOOLEAN":
          current[next.key.toLowerCase()] = next.value === "true" ? true : false
          break;
        case "PERCENTAGE":
        case "NUMBER":
          current[next.key.toLowerCase()] = Number(next.value)
          break;
        default:
          current[next.key.toLowerCase()] = next.value
      }
  
      return current
    }, {})
  
    return settings    
  } catch (error) {
    console.log("Settings extract error", error)
  }
}

export function sleep(time){
  return new Promise(res => setTimeout(res, time, "done sleeping"))
}

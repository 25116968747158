import React from 'react'
import GoogleMapReact from 'google-map-react'

import config from '../../config'

export default function Map({center = config.defaultCenter, options, onClick, children}) {

  const mapOptions = {
    streetViewControl: false,
    scrollwheel: false,
    scaleControl: false,
    mapTypeControl: false,
    gestureHandling: "greedy",
    panControl: false,
    zoomControl: true,
    rotateControl: false,
    fullscreenControl: false,
    // styles: mapStyle
  }

  return(
    <GoogleMapReact bootstrapURLKeys={{key: process.env.REACT_APP_GMAPS_KEY}} center={center} zoom={16} options={{...mapOptions, ...options}} onClick={onClick} disableDefaultUI>
      {children}
    </GoogleMapReact>
  )
}

import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Autoplay } from 'swiper/modules'

import { IntlText, MediaQuery } from '../../components'

import 'swiper/css'
import 'swiper/css/free-mode'
import './style.css'

const _icons = [
  "pizza",
  "vegan",    
  "sushi",
  "burrito",
  "breakfast",
  "tacos",
  "burger",
  "bbq",
  "pasta",
  "salad",
  "asian",
  "bowl",
  "ceviche",
  "chicken",
  "chilaquiles",
  "chinese",
  "coffee",
  "curry",
  "dessert",
  "drinks",
  "eggs",
  "empanada",
  "fastfood",
  "healthy",
  "hummus",
  "icecream",
  "indian",
  "juice",
  "mexican",
  "nachos",
  "ramen",
  "salmon",
  "sandwiches",
  "shrimp",
  "smoothie",
  "soup",
  "thai",
  "tuna",
  "vegetarian"
]

export function IconFilter({onSelect, selected, reset}){

  const sliderParams = {
    slidesPerView: "auto",
    spaceBetween: 30,
    modules: [FreeMode, Mousewheel, Autoplay],
    freeMode: true,
    mousewheel: {forceToAxis: true, releaseOnEdges: false},

  }  
  function _onSelect(tag){
    if (selected === tag) return reset()
    
    onSelect(tag)
  }

  return(
    <div className="icon-filter">
      <MediaQuery min={1024}>
        <div className="icons">
          <Swiper {...sliderParams}>
            {_icons.map((_icon, key) => 
              <SwiperSlide key={key}>
                <Icon key={key} image={_icon} onSelect={() => _onSelect(_icon)} selected={!!(selected === _icon)} />
              </SwiperSlide>
            )}
          </Swiper>                
        </div>
      </MediaQuery>
      <MediaQuery max={1024}>
        <div className="row" style={{padding: 0, marginBottom: 0}}>
          <div className="responsive" >
            <div className="icons">
              {_icons.map((_icon, key) => 
                <Icon key={key} image={_icon} onSelect={() => _onSelect(_icon)} selected={!!(selected === _icon)} />
                )}  
            </div>            
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

function Icon({image, onSelect, selected}){

    const [_selected, select] = useState(selected)

    useEffect(() => {
        select(selected)
    }, [selected])

    const src = !_selected ? `https://d2l0zqfhpatq4j.cloudfront.net/icons/${image}.png` : `https://d2l0zqfhpatq4j.cloudfront.net/icons/${image}_selected.png`
    const webpSrc = !_selected ? `https://d2l0zqfhpatq4j.cloudfront.net/icons/${image}.webp` : `https://d2l0zqfhpatq4j.cloudfront.net/icons/${image}_selected.webp`

    return(
        <div className={`icon ${_selected ? "selected" : ""}`} onClick={onSelect}>
            <div>
                <picture>
                    <source srcSet={webpSrc} type="image/webp" />
                    <img alt={image} title={image} src={src} onMouseEnter={(e) => select(true)} onMouseOut={(e) => !selected ? select(false) : null}/>
                </picture>                   
            </div>
            <label><IntlText group="icon-list" id={image} /></label>
        </div>        
    )
}
import React, { useContext } from 'react'
import { CardElement } from '@stripe/react-stripe-js'

import { LabeledInput, SwitchBox } from '../../../components'
import { CartContext, IntlContext } from '../../../contexts'

import './StripeOptions.css'

export function StripeOptions() {

  const { getTranslation } = useContext(IntlContext)
  const {cardData, cardData: { name, saveCard }, setCardData} = useContext(CartContext)

  function updateField(field){
    setCardData({
      ...cardData,
      ...field
    })
  }

  return(
    <div id="stripe" className="payment-method-options">
      <LabeledInput
        type="text"
        name="name"
        value={name}
        placeholder={getTranslation({group: "conekta-options", id: "card-holder"})}
        required
        onChange={(e) => updateField({[e.target.name]: e.target.value})}
      />
      <div className="FormRow">
        <CardElement
          onChange={(e) => {
            updateField({complete: e.complete})
          }}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      <SwitchBox id="save-card" label={getTranslation({group: "stripe-options", id: "save-card-desc"})} checked={saveCard} onChange={({target}) => updateField({saveCard: target.checked})}>{getTranslation({group: "stripe-options", id: "save-card"})}</SwitchBox>
    </div>
  )

}

import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { Map, MapPin } from '../../../components'
import { NewAddressForm } from '../../../views'

export function NewAddress() {

  const [searchParams] = useSearchParams()
  const [location, setLocation] = useState(searchParams.get("lng") && searchParams.get("lat") ? {lng: parseFloat(searchParams.get("lng")), lat: parseFloat(searchParams.get("lat"))} : undefined)

  return(
    <div id="address" className="view">
      <div id="address-map">
        <Map class="map" center={location} onClick={setLocation}>
          {location && <MapPin {...location}><FontAwesome name="map-marker" /></MapPin>}
        </Map>
      </div>
      <div className="row wrapped">
        <NewAddressForm location={location} />
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import { Tooltip } from '../'

import './Validator.css'

export default function Validator({children, onChange, validations, values, errors}){

  const [activeErrors, setErrors] = useState(errors)

  const _onChange = (e) => {
    let _errors = []
    let value = e.target ? e.target.value : e

    validations.every((validation) => {
      let valid = validation.validation(value, values)

      if (!valid) _errors.push(validation.message)

      return valid
    })

    setErrors(_errors)

    onChange(e)
  }

  useEffect(() => {
    setErrors(errors)
  }, [errors])

  return(
    <div className="validator">
      {React.cloneElement(children, {onChange: (e) => _onChange(e)})}
      {activeErrors && activeErrors.length > 0 &&
        <div className="errors">
          <Tooltip text={activeErrors.join("\r\n")}>
            <FontAwesome className="error-tag" name="times" />
          </Tooltip>
        </div>
      }
    </div>
  )

}

import React, { useState, useContext } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'

import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { Button, IntlText, LabeledInput, Validation } from '../../../components'

import './style.css'

const validations = {
    name: [{id: "required", validation: (val) => !!val}],
}

export function PurchasePayNow({purchase, refetch, close}){

    const stripe = useStripe()
    const elements = useElements()
    const { getTranslation } = useContext(IntlContext)
    const { form: { name }, updateField, errors, validate } = useValidatedForm({}, validations)
    const [loading, toggleLoading] = useState(false)

    async function payNow(){
        try {
            toggleLoading(true)
            if (!stripe || !elements) return console.log("stripe error")
            let { valid } = validate()

            if (!valid) return toast.error(getTranslation({id: "form-error"}))
    
            const cardElement = elements.getElement(CardElement)
    
            const { error } = await stripe.confirmCardPayment(purchase.stripePaymentSecret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name,
                  email: purchase?.customer?.email,
                  phone: purchase?.customer.phone?.phoneCode + purchase?.customer?.phone?.phoneNumber
                }
              }
            })
            
            if (error) throw error     
            await refetch()
            close()
            
            
        } catch (error) {
            if (error.graphQLErrors) error.graphQLErrors.map(x => toast.error(x.message))
            if (error.message) toast.error(error.message) 
        } finally {
            toggleLoading(false)
        }        
    }

    return(
        <div id="purchase-pay-now">
            <Validation errors={errors.name}>
                <LabeledInput type="text" name="name" value={name} placeholder={getTranslation({group: "conekta-options", id: "card-holder"})} required onChange={({target}) => updateField({key: "name", value: target.value})} />            
            </Validation>
            <CardElement
                options={{
                    style: {
                    base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                        color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                    },
                }}
            />
            <Button theme="main" className="full" loading={loading} onClick={payNow}><IntlText group="purchase-pay-now" id="pay-now" /></Button>
        </div>
    )
}
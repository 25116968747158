import { gql } from '@apollo/client'

const CATEGORY_FRAGMENT = gql`
  fragment CATEGORY_FRAGMENT on ProductCategory {
    id
    slug
    name{
      en
      es
    }
    description{
      en
      es
    }
    rank
    media{
      cover
      thumbnail
    }
    availability{
      from
      to
    }
    isAvailable
    disabled
    hidden
  }
`

export default CATEGORY_FRAGMENT

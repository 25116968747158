import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useForm, useIntl } from '../../../hooks'
import { AuthKit, parsePhone } from '../../../helpers'
import { IS_LOGGED_IN,  LOGIN_WITH_ONETIME_PASSWORD_MUTATION } from '../../../graphql'
import { Form, IntlText, LabeledInput, Validation, Button, PhoneNumberInput } from '../../../components'
import { HelpBox } from '../../../views'

const validations = {
  uid: [{id: "required", validation: (val) => !!val}],
  password: [{id: "required", validation: (val) => !!val}],
}

export function OneTimeLogin({uid: phone}){

  const navigate = useNavigate()
  const { getTranslation } = useIntl()
  const [login, { loading, error }] = useMutation(LOGIN_WITH_ONETIME_PASSWORD_MUTATION, {update: updateStore})
  const { form, updateField, errors, validate, status } = useForm({uid: phone}, validations)
  const { uid, password } = form  

  async function submit(){
    try {
        const { valid } = validate()

        if (!valid) return toast.error(getTranslation({id: "form-error"}))
        
        const _uid = `${uid.countryCode}:${uid.phoneCode.replace('+', '')}:${uid.phoneNumber.replace(/\s/gm, '')}`

        await login({variables: {uid: _uid, password}})
    } catch (e) {
        e.graphQLErrors.map(x => toast.error(x.message))
        console.log("One time login error: ", e)
    }
  }  

  function updateStore(cache, { data: { loginWithOneTimePassword: { token, user } } }){
    try {
        cache.writeQuery({
            query: IS_LOGGED_IN,
            data: { isLoggedIn: !!user?.id},
        })
          
        AuthKit.login(user, token, () => navigate("/account/change-password"))
    } catch (error) {
        console.log("error", error);
    }
  }  

  return(
    <Form.Form id="one-time-login">
      <Form.Field>
        <Validation errors={errors.uid}>
            <PhoneNumberInput name="uid" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(uid)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "uid", value: {countryCode: _data?.countryCode, phoneNumber: _data?.phoneNumber, phoneCode: _data?.phoneCode}})} disablePreferences={true} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.password}>
          <LabeledInput name="password" placeholder={getTranslation({id: "input-password"})} type="password" value={password} autocomplete="false" onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Button fluid theme="main" className="login" loading={loading} onClick={submit}><IntlText group="login-form" id="login-button" /></Button>
      </Form.Field>
      {(status?.id === "failed" || error) &&
        <HelpBox />
      }
    </Form.Form>
  )
}
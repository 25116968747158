import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'

import client from '../../client'

import { validations, validate } from '../../helpers'
import { Title, Button, IntlText, LabeledInput, Validator } from '../../components'
import { IntlContext } from '../../contexts'
import { REQUEST_CONTACT_MUTATION } from '../../graphql'

export default function WorkWithUsForm() {

  const _validations = {
    name: [validations.required],
    phone: [validations.required]
  }

  const { getTranslation } = useContext(IntlContext)
  const [loading, toggleLoading] = useState(false)
  const [success, toggleSuccess] = useState(false)
  const [form, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const { name, phone, note } = form

  const updateField = (field) => {
    setValues({
      ...form,
      ...field
    })

    setErrors({})
  }

  const submit = async () => {
    let { valid, errors: _errors } = validate(_validations, form)
    setErrors(_errors)

    if (!valid) return toast.error(getTranslation({id: "form-error", defaultValue: "Please check the form"}))

    toggleLoading(true)

    try {
      await client.mutate({mutation: REQUEST_CONTACT_MUTATION, variables: {data: {name, phone, note, type: "INTERESTED_VENDOR"}}})
      toggleLoading(false)
      toggleSuccess(true)
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
      toggleLoading(false)
    }
  }

  return(
    <div className="call-to-action-form">
      {success ?
        <div className="success">
          <FontAwesome name="check" />
          <span><IntlText id="form-success" /></span>
        </div>
      :
        <div>
          <Title tag="h2"><IntlText group="for-vendors" id="form-call-to-action" /><span className="highlight"> <IntlText group="for-vendors" id="form-call-to-action-highlight" /></span></Title>
          <Validator validations={_validations.name} errors={errors.name} onChange={(e) => updateField({[e.target.name]: e.target.value})}>
            <LabeledInput name="name" placeholder={getTranslation({group: "for-vendors", id: "input-name"})} value={name}/>
          </Validator>
          <Validator validations={_validations.phone} errors={errors.phone} onChange={(e) => updateField({[e.target.name]: e.target.value})}>
            <LabeledInput name="phone" placeholder={getTranslation({group: "for-vendors", id: "input-phone"})} value={phone}/>
          </Validator>
          <LabeledInput name="note" placeholder={getTranslation({group: "for-vendors", id: "input-restaurant"})} value={note} onChange={(e) => updateField({[e.target.name]: e.target.value})}/>
          <Button loading={loading} theme="main" onClick={() => submit()}><IntlText group="for-vendors" id="form-button" /></Button>
        </div>
      }
    </div>
  )
}

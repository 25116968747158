import React from 'react'

import { useAuth } from '../../../hooks'
import { IntlText, Checkbox } from '../../../components'

import './style.css'

export function ReferralTerms() {

  const { loading, user, error, editUser } = useAuth()

  if (loading || error) return null

  return(
    <div className='referral-terms'>
      <Checkbox id="referralTermsAccepted" checked={user?.referralTermsAccepted || false} disabled={user?.referralTermsAccepted} onChange={() => editUser({data: {referralTermsAccepted: !user?.referralTermsAccepted}})}>
        <IntlText group="referred-customers" id="accept-terms-1" />
        <a href='/share-and-save-terms-and-condition'><IntlText group="referred-customers" id="terms" /></a>
        <IntlText group="referred-customers" id="accept-terms-2" />
      </Checkbox>        
    </div>
  )
}

import { gql } from "@apollo/client"

import { CUSTOMER_FRAGMENT } from './'

export const CREATE_REFERRAL_CODE = gql`
  mutation createReferralCode($id: ID, $code: String){
    createReferralCode(id: $id, code: $code){
      ...CUSTOMER_FRAGMENT
    }
  }
  ${CUSTOMER_FRAGMENT}
`

import React from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css'

export default function Title({id, styled, tag = "h1", icon, children}) {

  const Tag = tag

  return(
    <div id={id} className={`title ${styled ? "styled" : ""}`}>
      <Tag>
        {icon && <FontAwesome name={icon} />}
        {children}
      </Tag>
    </div>
  )
}

import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'

import { VENDOR_QUERY } from '../../../graphql'
import { useEditPurchase, useIntl } from '../../../hooks'
import { IntlText, Toggle, QuantitySelector, Loader } from '../../../components'

import './style.css'

export function AddUtensils({purchaseQuote}){

  const { getTranslation } = useIntl()
  const { editPurchaseQuote, loading } = useEditPurchase({})
  const { data } = useQuery(VENDOR_QUERY, {variables: {id: purchaseQuote?.vendor}})

  if (!data?.vendor?.utensilsAvailable) return null

  return(
    <div className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'><FontAwesome name="leaf" /></span>
      <div className="info">
        <div className="texts">
          <div className="desc">
            <IntlText group="eco" id="title" />
          </div>
          <div>
            <IntlText group="eco" id="desc" />
          </div>
        </div>
        {purchaseQuote?.sendUtensils > 0 ?
          <QuantitySelector qty={purchaseQuote?.sendUtensils} increase={() => editPurchaseQuote({sendUtensils: purchaseQuote?.sendUtensils + 1})} decrease={() => editPurchaseQuote({sendUtensils: purchaseQuote?.sendUtensils - 1})} unit={getTranslation({group: "eco",  id: "unit"})} />
          :
          <Toggle id="utensils" checked={!!purchaseQuote?.sendUtensils} onChange={() => editPurchaseQuote({sendUtensils: 1})} />
        }
      </div>
    </div>
  )

}

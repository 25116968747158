import React, { useContext, useState } from 'react'
import { node } from 'prop-types'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'

import { ADDRESS_QUERY } from '../../graphql'
import { IntlContext } from '../'
import { calculateItemPrice } from '../../helpers'

const initialState = {type: "ONDEMAND", newCart: true, items: [], vendor:{}, deliveryLocation: undefined, paymentMethod: undefined, contactPreference: undefined, currency: {id: "MXN", label: "MXN"}, sendUtensils: 0, tip: 0, banknote: null, useBalance: true}
const initialCardData = {
  name: '',
  complete: false
}

function loadCart(){
  try {
    let cart = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_APP_NAME || "tomato"}_cart`))
    if (!cart) return undefined

    if (!cart.currency || !cart.currency.id) cart.currency = {id: "MXN", label: "MXN"}
    if (!cart.sendUtensils) cart.sendUtensils = 0
    if (!cart.tip) cart.tip = 0
    if (!cart.newCart) return undefined
    if (!cart.type) cart.type = "ONDEMAND"

    return cart

  } catch (e) {
    localStorage.setItem(`${process.env.REACT_APP_APP_NAME || "tomato"}_cart`, JSON.stringify(initialState))
  }

}

const CartContext = React.createContext(loadCart() || initialState)
const CartConsumer = CartContext.Consumer

function CartProvider({children}) {

  const { getTranslation, selectedLanguage: { shortcode } } = useContext(IntlContext)
  const [cart, setCart] = useState(loadCart() || initialState)
  const [cardData, setCardData] = useState(initialCardData)
  const { items, vendor } = cart
  useQuery(ADDRESS_QUERY, {variables: {id: cart?.deliveryLocation?.id}, 
    onCompleted: (data) => {
      set("deliveryLocation", data?.address)
    },
    onError: () => {
      set("deliveryLocation", undefined)
  }})  

  function updateField(field){
    setCart({
      ...cart,
      ...field
    })
  }

  function saveCart(_cart){
    let cartToSave = {...cart, ..._cart}
    localStorage.setItem(`${process.env.REACT_APP_APP_NAME || "tomato"}_cart`, JSON.stringify(cartToSave))
    setCart(cartToSave)
  }

  function increaseQty(key){
    let max = items[key].product.maxPurchaseQuantity || undefined

    if (max && max <= items[key].quantity) return toast.error(getTranslation({group: "cart-context", id: "max-qty"}))

    items[key].quantity++

    saveCart({items, vendor})
  }

  function decreaseQty(key){
    let min = items[key].product.minPurchaseQuantity || 1

    if (items[key].quantity <= min) return toast.error(getTranslation({group: "cart-context", id: "min-qty"}))

    items[key].quantity--

    saveCart({items, vendor})
  }

  function addToCart(data, _vendor){
    let { product, quantity, addons, alternative, instructions } = data
    let _items = vendor && vendor.id === _vendor.id ? items : []
    let cartType = getCartType(_items)
    let _data = vendor?.id !== _vendor?.id ? {stripePaymentId: null} : {}

    if (!((cartType.free && product.freeDeliveryAvailable) || (!cartType.free && !product.freeDeliveryAvailable) || cartType.empty))
      return toast.error(getTranslation({group: "cart-context", id: "cant-mix"}))

    if (!((cartType.longPreparation && product.tags.includes("longPreparation")) || (!cartType.longPreparation && !product.tags.includes("longPreparation")) || cartType.empty))
      return toast.error(getTranslation({group: "cart-context", id: "cant-mix-long-preparation"}))

    _items.push({product, quantity, addons, alternative, instructions})

    saveCart({items: _items, vendor: _vendor, paymentMethod: undefined, ..._data})

  }

  function removeItem(key){
    let _items = [...items]
    _items.splice(key, 1)

    saveCart({items: _items, vendor})
  }

  function emptyCart(){
    let _cart = initialState
    setCardData(initialCardData)

    saveCart(_cart)
  }

  function calculateTotals(){
    let itemsTotal = items.reduce((totals, item) => ({subtotal: totals.subtotal + calculateItemPrice(item), qty: totals.qty + item.quantity}), {subtotal: 0, qty: 0})

    return itemsTotal
  }

  function set(id, value){
    updateField({[id]: value})
    localStorage.setItem(`${process.env.REACT_APP_APP_NAME || "tomato"}_cart`, JSON.stringify({...cart, [id]: value}))
  }

  function getCartType(items){
    if (!items || !items.length > 0) return {empty: true}
    let cartType = {}

    items.forEach((item, i) => {
      if (item.product.freeDeliveryAvailable) cartType.free = true
      if (item.product.tags.includes("longPreparation")) cartType.longPreparation = true
    })

    return cartType

  }

  function prepareItems(){
    let preparedItems = items.map(({product, quantity, instructions, alternative, addons}) => {

      let prod = {
        product: product.id,
        quantity,
        alternative,
        addons: addons.map((_addon) => ({
          addon: _addon.addon.id || _addon.addon.originalId,
          _selectedOptions: _addon.selectedOptions.map((_option) => ({
            id: _option.id || _option.originalId,
            quantity: _option.quantity || 1,
          }))
        })),        
      }

      if (instructions) prod.instructions = instructions

      return prod
    })
    return preparedItems
  }

  function processPurchaseQuote(purchaseQuote){
    if (!purchaseQuote) return false

    const { unavailableProducts } = purchaseQuote
    let _cart = {}

    _cart.items = items.map((item) => {
      if (unavailableProducts?.includes(item.product.id)) {
        toast.error(getTranslation({group: "cart-context", id: "item-not-available", variables: {name: item.product.name[shortcode]}}))
        return {...item, disabled: true}
      } else {
        return {...item, disabled: false}
      }
    })
    saveCart({...cart, ..._cart})
  }

  return (
    <CartContext.Provider
      value={{
        state: cart,
        addToCart,
        removeItem,
        increaseQty,
        decreaseQty,
        calculateTotals,
        emptyCart,
        set,
        cardData,
        setCardData,
        prepareItems,
        processPurchaseQuote,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: node.isRequired,
}

export { CartContext, CartProvider, CartConsumer }

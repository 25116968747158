import { useState, useEffect } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, Title, Loader, IntlText, WhatsAppHelp, Map } from '../../../components'
import { map } from '../../../assets'

import './style.css'

export function AddressLocationDetect() {

  const navigate = useNavigate()
  const [loading, toggleLoading] = useState(false)
  const [error, toggleError] = useState(false)

  useEffect(() => {
    toggleLoading(true)

    const watchID = navigator.geolocation.watchPosition((loc) => {
      toggleLoading(false)

      const location = {lat: loc.coords.latitude, lng: loc.coords.longitude}
      
      navigate({
        pathname: "/address/new",
        search: createSearchParams(location).toString()
      })     
    }, (_error) => {
      toast.error(_error.message)
      toggleLoading(false)
      toggleError(true)

    },
    {enableHighAccuracy: true})

    return () => navigator.geolocation.clearWatch(watchID)
    // eslint-disable-next-line
  }, [])

  return(
    <div id="address" className="view">
      <div id="address-map" className="blur">
        <Map class="map" />
        <div id="map-overlay">
          <div className="map-overlay-inner">
            <div id="detect-location">
              <Title><IntlText group="detect-location" id="title" /></Title>
              {loading && <Loader theme="main"/>}
              {error &&
                <div className="error-box">
                  <span className="error-message"><IntlText group="detect-location" id="error-message" /></span>
                  <Button theme="main" onClick={() => window.location.reload(false)} icon="sync"><IntlText group="detect-location" id="refresh-button" /></Button>
                  <div className="helps">
                    <Title tag="h5"><IntlText group="detect-location" id="help-title" /></Title>
                    <span className="placeholder"><IntlText group="detect-location" id="here-to-help" /></span>
                    <WhatsAppHelp />
                  </div>
                  <Button onClick={() => navigate(-1)}><IntlText id="back" /></Button>
                </div>
              }
            </div>            
          </div>
        </div>
      </div>
      <div id="address-help-box">
        <div className="icon">
          <img alt="add-address" title="add-address" src={map} />
        </div>
        <div className="info">
          <Title tag="h1"><IntlText group="address" id="detect-title" /></Title>
          <div className="desc"><IntlText group="address" id="detect-desc" /></div>
        </div>
      </div>
    </div>    
  )
}

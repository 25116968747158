import React from 'react'

import './RadioDot.css'

export default function RadioDot({id, name, label, checked, disabled, onChange, readOnly}) {

  return(
    <div className="radio-dot">
      <input id={id} name={name || id} type="radio" checked={checked} disabled={disabled} onChange={onChange} readOnly={readOnly}/>
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

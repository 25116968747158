import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const SUBMIT_PURCHASE_QUOTE = gql`
  mutation submitPurchaseQuote($id: ID!){
    submitPurchaseQuote(id: $id){
      quote{
        ...PURCHASE_QUOTE_FRAGMENT
      }
      purchase{
        id
      }
    }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { useEditPurchase, useModal } from '../../../../hooks'
import { Toggle, IntlText, Loader, SelectModal } from '../../../../components'

export function PayInCurrency({purchaseQuote}) {
  
  const modal = useModal()
  const { editPurchaseQuote, loading } = useEditPurchase({})
  const options = [{id: "USD", label: "USD"}, {id: "MXN", label: "MXN"}]
  const checked = !!(purchaseQuote?.currency && purchaseQuote?.currency !== "MXN")

  function select(selected){
    editPurchaseQuote({currency: selected.id})
    modal.hide()
  }

  return(
    <div id="pay-in-currency" className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'><FontAwesome name="money-bill" /></span>
      <div className="info">
        <div className="texts">
          <div className="desc"></div>
          <div><IntlText group="cod-options" id="pay-with-currency" /></div>
        </div>
        {checked ?
          <div className='checkout-option-value' onClick={() => modal.show()}>
            <div className='placeholder'><IntlText group="cod-options" id="select-currency" /></div>            
            <div className='value'>
              <span>{purchaseQuote?.currency}</span>
              <FontAwesome name="edit" />
            </div>
          </div>
        :
          <Toggle id="dollar" checked={checked} onChange={() => modal.show()} />
        }
        <SelectModal modal={modal} selected={options.find((_option) => _option.id === purchaseQuote?.currency)} defaultValue={<IntlText group="cod-options" id="nothing-selected" />} placeholder={<IntlText group="cod-options" id="select-currency" />} options={options} onChange={select} />
      </div>
    </div>   
  )

}

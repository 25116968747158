import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { ADDRESS_QUERY } from '../../../graphql'
import { Map, MapPin, LoaderV2 } from '../../../components'
import { EditAddressForm } from '../../../views'
import { NotFound } from '../../NotFound'

import './style.css'

export function EditAddress() {

  const { id } = useParams()
  const { loading, data, error } = useQuery(ADDRESS_QUERY, {variables: {id}, skip: !id})

  if (loading) return (
    <div id="loader" className="container" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <LoaderV2.Logo />
    </div>
  )
  if (error) return (
    <NotFound message={`Error: ${error?.message}`} />
  )

  const { address } = data

  return(
    <Inner address={address} />
  )
}

function Inner({address}){

  const [location, setLocation] = useState({lat: address?.location?.coordinates?.[1], lng: address?.location?.coordinates?.[0]})

  return(
    <div id="address" className="view">
      <div id="address-map">
        <Map class="map" center={location} onClick={setLocation}>
          <MapPin {...location}><FontAwesome name="map-marker" /></MapPin>
        </Map>
      </div>
      <div className="row wrapped">
        <EditAddressForm location={location} address={address} />
      </div>
    </div>
  )

}

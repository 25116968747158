import React from 'react'

import { useTabs } from './'

export function Tab({id, children, defaultTab}){

  const { selectedTab } = useTabs()

  if (selectedTab === id || (!selectedTab && defaultTab)) return(
    <div id={`${id}`} className="tab">
      {children}
    </div>
  )

  return null

}

import React, { useContext } from 'react'

import { IntlText } from '../'
import { formatPrice } from '../../helpers'
import { CartContext } from '../../contexts'

export default function CartTotals() {

  const { calculateTotals } = useContext(CartContext)

  let totals = calculateTotals()

  return(
    <div id="cart-totals">
      <div className="cart-total">
        <span><IntlText group="cart-totals" id="total" /></span>
        <span className="qty">({totals.qty} <IntlText group="cart-totals" id="items" />)</span>
        <span className="price">{formatPrice(totals.subtotal)}</span>
      </div>
    </div>
  )
}

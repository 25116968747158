import React from 'react'
import { useQuery } from '@apollo/client'

import { REFERRAL_TRANSACTIONS } from '../../../graphql'
import { formatPrice } from '../../../helpers'
import { Title, IntlText, IntlValue } from '../../../components'

export function ReferralTransactions(){

  const { loading, data, error } = useQuery(REFERRAL_TRANSACTIONS, {variables: {offset: 0, limit: 10}})

  if (loading || error) return null

  return(
    <div>
      <Title tag="h3" styled><IntlText group="referred-customers" id="referral-transactions"/></Title>
      {data?.referralTransactions?.nodes?.length > 0 ? 
        <div className='sliding-table'>
          <table className="table">
            <thead>
              <tr>
                <th><IntlText group="referred-customers" id="transactionId"/></th>
                <th><IntlText group="referred-customers" id="description"/></th>
                <th><IntlText group="referred-customers" id="value"/></th>
              </tr>
            </thead>
            <tbody>
              {data?.referralTransactions?.nodes?.map((item, key) =>
                <tr className="item" key={key}>
                  <td>{item.id}</td>
                  <td><IntlValue>{item.description}</IntlValue></td>
                  <td>{formatPrice(item.value)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
       : 
        <IntlText group="referred-customers" id="no-transactions-yet"/>
      }
    </div>
  )
}
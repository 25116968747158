import React, { useRef } from 'react'

import './style.css'

export function OTPInput({value = "", onChange, onFilled}){

    const values = value.split("")
    const refs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ]

    function _onChange({target}, index){
        const _value = (value || "") + target.value.replace(/[^0-9]/g,'')
        const _values = _value.split("")

        onChange(_value)

        if (index >= 6 && _values.length >= 6) {
            if (onFilled) onFilled(_value)
            return false
        }

        if (target.value !== ""){
            refs[index].current?.focus()
        }          
    }

    function tabChange(val, e){
        const { key, keyCode } = e

        if (keyCode === 9) {
            return e.preventDefault()
        }        

        if (key === "Backspace" || key === "Delete") {
            if (val-1 >= 0) {
                const _values = values
                _values.splice(val-1, 1)

                onChange(_values.join(""))
                if (val-1 !== 0) return refs[val-2].current?.focus()

                return e.preventDefault()
            }
        }      
    }    

    function paste(event){
        const _value = (event.clipboardData || window.clipboardData).getData('text')
        const _values = _value.split("")

        onChange(_value)

        if (_values.length >= 6) {
            if (onFilled) onFilled(_value)
            return false
        }        

    }

    return(
        <div className="otp-input">
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[0]} tabIndex="-1" value={values[0] || ""} className="otp-number" autoComplete="one-time-code" onChange={(e) => _onChange(e, 1)} onKeyUp={(e) => tabChange(1, e)} maxLength={1} />
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[1]} tabIndex="-1" value={values[1] || ""} className="otp-number" onChange={(e) => _onChange(e, 2)} onKeyUp={(e) => tabChange(2, e)} maxLength={1} />
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[2]} tabIndex="-1" value={values[2] || ""} className="otp-number" onChange={(e) => _onChange(e, 3)} onKeyUp={(e) => tabChange(3, e)} maxLength={1} />
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[3]} tabIndex="-1" value={values[3] || ""} className="otp-number" onChange={(e) => _onChange(e, 4)} onKeyUp={(e) => tabChange(4, e)} maxLength={1} />
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[4]} tabIndex="-1" value={values[4] || ""} className="otp-number" onChange={(e) => _onChange(e, 5)} onKeyUp={(e) => tabChange(5, e)} maxLength={1} />
            <input type="text" name="token" inputMode="numeric" pattern="[0-9]*" id="token" onPaste={paste} ref={refs[5]} tabIndex="-1" value={values[5] || ""} className="otp-number" onChange={(e) => _onChange(e, 6)} onKeyUp={(e) => tabChange(6, e)} maxLength={1} />
        </div>
    )
}
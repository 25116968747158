export function calculateItemPrice(data) {
  let pricePerItem = data.product.price

  if (data.addons) pricePerItem += calculateAddonsPrice(data.addons)

  return (pricePerItem * data.quantity)
}

export function calculateAddonsPrice(addons) {
  let addonsPricePerItem = 0

  if (!addons?.length > 0) return null
  
  addons.forEach(({selectedOptions}) => {
    selectedOptions.forEach((option) => {
      return addonsPricePerItem += (option.quantity || 1) * option.price
    })
  })

  return addonsPricePerItem
}

export function formatPrice(price, round) {
  if (price && round) return price.toFixed(2) + " $"

  return price + " $"
}

import { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useForm } from '../../hooks'
import { FBKit, AuthKit, parsePhone } from '../../helpers'
import { IntlContext, SlideInContext } from '../../contexts'
import { LOGIN_MUTATION, IS_LOGGED_IN } from '../../graphql'
import { Button, FacebookLoginButton, LabeledInput, Validation, IntlText, Link, Form, SegmentedControl, PhoneNumberInput, Title } from '../../components'
import { HelpBox } from '../../views'

import './style.css'

const validations = {
  uid: [{id: "required", validation: (val) => !!val}],
  password: [{id: "required", validation: (val) => !!val}],
}

export function LoginForm() {

  const { getTranslation } = useContext(IntlContext)
  const { closeMenu } = useContext(SlideInContext)
  const [selectedMethod, selectMethod] = useState("email")
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {update: updateStore})
  const { form, updateField, errors, validate, status, resetForm } = useForm({}, validations)
  const { uid, password } = form
 
  function _selectMethod(_method){
    resetForm()
    selectMethod(_method)
  } 

  async function submit(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      const _uid = selectedMethod === "phone" ? `${uid.countryCode}:${uid.phoneCode.replace('+', '')}:${uid.phoneNumber.replace(/\s/gm, '')}` : uid

      await login({variables: {uid: _uid, password}})
      closeMenu()
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  function updateStore(cache, { data: { login: { token, user } } }){
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: { isLoggedIn: !!user?.id},
    })

    AuthKit.login(user, token)
  }

  return(
    <div id="login-form-wrapper" className="form-wrapper">
      <div className="heading">
        <Title tag="h2"><IntlText group="login" id="title" /></Title>
        <div className="desc"><IntlText group="login" id="new-on-tomato" /> <Link to="/register"><IntlText group="login" id="desc" /></Link></div>
      </div>
      <div id="login-form">
        <div id="social-logins">
          <FacebookLoginButton onClick={() => FBKit.login(closeMenu)}><IntlText group="login-form" id="facebook-login" /></FacebookLoginButton>
          <span className="help"><IntlText group="login-form" id="or" /></span>
        </div>
        <Form.Form>
          <Form.Field centered>
            <SegmentedControl value={selectedMethod} options={[{id: "email", label: getTranslation({group: "account-recovery", id: "email"})}, {id: "phone", label: getTranslation({group: "account-recovery", id: "phone"})}]} onClick={(_option) => _selectMethod(_option.id)}/>          
          </Form.Field>
          <Form.Field>
            {selectedMethod === "phone" ? 
              <Validation errors={errors.uid}>
                <PhoneNumberInput name="uid" placeholder={getTranslation({id: "input-phone"})} value={parsePhone(uid)} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(_data) => updateField({key: "uid", value: {countryCode: _data?.countryCode, phoneNumber: _data?.phoneNumber, phoneCode: _data?.phoneCode}})} disablePreferences={true} />
              </Validation>
            : selectedMethod === "email" ?
            <Validation errors={errors.uid}>
                <LabeledInput name="uid" placeholder={getTranslation({id: "input-uid"})} value={uid} autocomplete="false" onChange={({target}) => updateField({key: target.name, value: target.value.toLowerCase().replace(/\s/g, '')})} />
              </Validation>
            : null}    
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.password}>
              <LabeledInput name="password" placeholder={getTranslation({id: "input-password"})} type="password" value={password} autocomplete="false" onChange={({target}) => updateField({key: target.name, value: target.value})} />
            </Validation>
          </Form.Field>
          <Form.Field>
            <div className="actions">
              <Link to="/recovery" button className="forget-password"><IntlText group="login-form" id="forget-password" /></Link>
              <Button theme="main" className="login" loading={loading} onClick={submit}><IntlText group="login-form" id="login-button" /></Button>
            </div>
          </Form.Field>
          {(status?.id === "failed" || error) &&
            <HelpBox />
          }        
        </Form.Form>    
      </div>      
    </div>    
  )

}

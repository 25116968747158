import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const MY_PURCHASES_QUERY = gql`
  query myPurchases($status: [PURCHASE_STATUS!], $offset: Int, $limit: Int, $type: PURCHASE_TYPE){
    myPurchases(status: $status, offset: $offset, limit: $limit, type: $type){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`

import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from '../vendor/$vendor'

const VENDOR_QUERY = gql`
  query vendor($slug: String, $id:ID){
    vendor(slug: $slug, id:$id){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`

export default VENDOR_QUERY

import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { RESET_CUSTOMER_PASSWORD_MUTATION } from '../../graphql'
import { useIntl, useForm } from '../../hooks'
import { toast } from '../../helpers'
import { Title, IntlText, LabeledInput, Button, Link, Validation } from '../../components'

import './style.css'

export function ResetPassword() {

  const validations = {
    password: [{id: "required", validation: (val) => !!val}],
    password2: [{id: "required", validation: (val) => !!val}, {id: "notEqual", validation: (val, {password}) => !!(val === password)}],
  }
  const [searchParams] = useSearchParams()
  const [resetPassword, { loading }] = useMutation(RESET_CUSTOMER_PASSWORD_MUTATION)
  const [success, toggleSuccess] = useState(false)
  const { getTranslation } = useIntl()
  const { form, updateField, validate, errors} = useForm({}, validations)
  const { password, password2 } = form

  async function submit(){
    const alert = toast.loading()
    try {
      const token = searchParams.get("token")
      let { valid } = validate()

      if (!valid) throw new Error(getTranslation({id: "form-error"}))

      await resetPassword({variables: {token, password: password}})
      alert.success(getTranslation({group: "reset-password", id: "success"}))
      toggleSuccess(true)
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <div id="reset-password" className="container">
      <div className="row wrapped">
        <div id="reset-password-form-wrapper" className="form-wrapper">
          <div className="heading">
            <Title tag="h2"><IntlText group="reset-password" id="title" /></Title>
            <div className="desc"><span><IntlText group="reset-password" id="desc" /></span></div>
            {success ?
              <div className="form-success">
                <FontAwesome name="check-circle" />
                <span>
                  <IntlText group="reset-password" id="success" />
                  <Link to="/login"><span><IntlText group="reset-password" id="success-link" /></span></Link>
                </span>
              </div>
            :
              <div id="reset-password-form">
                <Validation errors={errors.password}>
                  <LabeledInput name="password" type="password" placeholder={getTranslation({group: "reset-password", id: "input-password"})} value={password} onChange={({target}) => updateField({key: [target.name], value: target.value})}/>
                </Validation>
                <Validation errors={errors.password2}>
                  <LabeledInput name="password2" type="password" placeholder={getTranslation({group: "reset-password", id: "input-password2"})} value={password2} onChange={({target}) => updateField({key: [target.name], value: target.value})}/>
                </Validation>
                <Button theme="main" loading={loading} onClick={() => submit()}><IntlText group="reset-password" id="submit" /></Button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import ReactModal from 'react-modal'

import { CloseButton } from '../../components'

import style from '../../style/modal'

export class Modal extends React.Component{

  state = {
    open: false
  }

  constructor(){
    super()
    this.show = this.show.bind(this)
  }

  show(attributes){
    this.setState({open: true, attributes})
  }

  hide(){
    this.props?.onHide && this.props.onHide()
    this.setState({open: false, attributes: null})
  }

  render(){
    const { id, children, disableClose } = this.props

    if (!this.state.open) return false

    return(
      <ReactModal ref={(ref) => this.modal = ref} isOpen={this.state.open} style={style} onRequestClose={!disableClose ? () => this.hide() : null} appElement={document.getElementById('root')}>
        <div id={`${id}-modal`} className="modal">
          <div className="modal-info">
            {this.props.header &&
              <div className="modal-header">
                {this.props.header}
                {!disableClose && <CloseButton onClick={() => this.hide()}/>}
              </div>
            }
            <div className="modal-content">
              {children({state: this.state.attributes, hide: this.hide})}
            </div>
            {this.props.footer &&
              <div className="modal-footer">
                {this.props.footer}
              </div>
            }
          </div>
        </div>
      </ReactModal>
    )
  }
}

import gql from 'graphql-tag'

import { PURCHASE_FRAGMENT } from './'

export const CANCEL_MY_PURCHASE = gql`
  mutation cancelMyPurchase($id: ID!, $reason: PURCHASE_CANCEL_REASON!){
    cancelMyPurchase(id: $id, reason: $reason){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`

import gql from 'graphql-tag'

export const MOST_POPULAR_SEARCHES = gql`
  query mostPopularSearches($first: Int, $sortBy: SEARCHSTAT_SORT){
    mostPopularSearches(first: $first, sortBy: $sortBy) {
      edges{
        cursor
        node{
         id
         term
         totalCount   
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }      
    }
  }
`

import React from 'react'
import parse from 'html-react-parser'

import './style.css'

export function Text({children}) {
  if (!children) return null

  return(
    <div className="text">
      {parse(children)}
    </div>
  )
}

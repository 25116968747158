import { useState, useEffect } from 'react'

export default function MediaQuery({min, max, children}) {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateDimensions)

    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  if (width <= max || width > min) return children || null

  return null
}

import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import FontAwesome from 'react-fontawesome'

import { MediaQuery, Title, IntlText, Button as UIButton } from '../'
import { useDetectOutsideClick, useFitInView } from '../../hooks'

import './style.css'

function Bar({className = "", vertical, children, ...rest}){
  return(
    <div className={`filter-bar ${className} ${vertical ? "vertical" : ""}`} {...rest}>
      {children}
    </div>
  )

}

function Dropdown({className = "", value, placeholder, icon, children, reset, right, disableInClickClose, hideArrow}){

  const wrapper = useRef(null)
  const menu = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false, disableInClickClose, callback: () => document.body.classList.remove(`modal-opened`)})

  function _setIsActive(val){
    if (val){
      document.body.classList.add(`modal-opened`)
    } else {
      document.body.classList.remove(`modal-opened`)
    }
    setIsActive(val)
  }

  useFitInView({element: menu, offset: 40})

  return(
    <div ref={wrapper} className={`filter-dropdown ${className}`}>
      <div className={`filter-dropdown-toggle ${!!value ? "selected" : ""}`} onClick={() => _setIsActive(!isActive)}>
        {icon && <FontAwesome name={icon} />}
        <span>{value || placeholder}</span>
        {!hideArrow && (!!value && isActive ? <FontAwesome className='toggle' name="trash" onClick={reset}/> : <FontAwesome className='toggle' name="chevron-down" />)}
      </div>
      <MediaQuery max={1024}>
        {isActive && ReactDOM.createPortal(
          <div className="portal-modal">
            <div className="inner">
              <div className="modal-header">
                <Title tag="h3">{placeholder}</Title>
              </div>
              <div className="modal-content">
                {children}
              </div>
              <div className="modal-footer">
                <UIButton size="small" onClick={reset}><IntlText id="clear" /></UIButton>
              </div>
            </div>
          </div>
        , document.getElementById('modal-portal'))}
      </MediaQuery>
      <MediaQuery min={1023}>
        <div className={`filter-dropdown-menu ${isActive ? "opened" : ""} ${right ? "right" : ""}`} ref={menu}>
          {children}
        </div>
      </MediaQuery>
    </div>
  )

}

function Select({children, ...rest}){
  return(
    <Dropdown {...rest}>
      <ul className="filter-select">
        {children}
      </ul>
    </Dropdown>
  )
}

function SelectItem({children, active, ...rest}){
  return(
    <li {...rest}>
      <div>
        {active ? <FontAwesome style={{color: "var(--main-color)"}} name="dot-circle" /> : <FontAwesome name="circle" /> }
        <span>{children}</span>
      </div>
    </li>
  )
}

function Button({className = "", active, children, reset, onClick, style, icon}){

  return(
    <button style={style} className={`filter-button ${!!active ? "selected" : ""} ${className}`} onClick={active ? reset : onClick}>
      {icon && <FontAwesome name={icon} />}
      <span>{children}</span>
      {!!active && <FontAwesome name="trash" />}
    </button>
  )

}

function Submit({icon, children, onClick}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <button className="filter-submit" onClick={onClick}>
      {icon && showIcon()}
      {children}
    </button>
  )

}

function Input({value, icon, reset = () => console.log("Reset not defined"), ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <div className={`filter-input ${!!value ? "selected" : ""}`}>
      {icon && showIcon()}
      <input autoComplete="off" value={value || ""} {...rest}/>
      {!!value && <FontAwesome className="clear" onClick={reset} name="trash" />}
    </div>
  )

}

function ActiveParams({activeParams, removeParam, clearParams}){

  if (!(!!activeParams?.length)) return false

  return(
    <div className="filter-active-params">
      {activeParams?.length >= 2 &&
        <div key="clear" onClick={() => clearParams()}>
          <span>Clear all</span>
          <FontAwesome name="times-circle" />
        </div>
      }
      {activeParams.map((param, key) =>
        <div key={key} onClick={() => removeParam(param?.key)}>
          <span>{param?.label}</span>
          <FontAwesome name="trash" />
        </div>
      )}
    </div>
  )

}

const output = { Bar, Select, SelectItem, Button, Dropdown, Input, ActiveParams, Submit }

export default output

import React from 'react'
import { useQuery } from '@apollo/client'

import { REFERRED_CUSTOMERS } from '../../../graphql'
import { Title, IntlText, Badge } from '../../../components'

export function ReferredCustomers(){

  const { loading, data, error } = useQuery(REFERRED_CUSTOMERS, {variables: {offset: 0, limit: 10}})

  if (loading || error) return null

  return(
    <div>
      <Title tag="h3" styled><IntlText group="referred-customers" id="invited-title"/></Title>
      {data?.referredCustomers?.nodes?.length > 0 ? 
        <div className='sliding-table'>
          <table className="table">
            <thead>
              <tr>
                <th><IntlText group="referred-customers" id="name"/></th>
                <th><IntlText group="referred-customers" id="email"/></th>
                <th><IntlText group="referred-customers" id="referralSource"/></th>
                <th><IntlText group="referred-customers" id="referralPurchasesCount"/></th>
                <th><IntlText group="referred-customers" id="validReferral"/></th>
              </tr>
            </thead>
            <tbody>
              {data?.referredCustomers?.nodes?.map((item, key) =>
                <tr className="item" key={key}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.referralSource}</td>
                  <td>{item.referralPurchasesCount}</td>
                  <td><Badge theme={item.validReferral ? "valid" : "invalid"}>{item.validReferral ? "valid" : "invalid"}</Badge></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      : 
        <IntlText group="referred-customers" id="no-referred-customers-yet"/>
      }
    </div>
  )
}

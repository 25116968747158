import { useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export function useQueryBuilder(initialData, validations) {
  const navigate = useNavigate()
  const { search } = useLocation()
  const url = new URLSearchParams(search)
  const [query, setQuery] = useState(init())
  const [params, setParams] = useState(init())
  const [page, setPage] = useState(initialData?.page || 1)
  const [sort, setSort] = useState(initialData?.sort || "CREATEDAT_ASCENDING")
  const [limit, setLimit] = useState(initialData?.limit || 100)
  // eslint-disable-next-line
  const _isQuery = useMemo(() => validateQuery(), [query])  

  function init(){
    if (!initialData?.data) return {}

    let _data = {}
    Object.entries(initialData.data).forEach(([_key, _val]) => {
      if (!!_val?.value) _data[_key] = _val
    })

    return _data
  }

  function addParam(_params){
    setParams({
      ...params,
      ..._params
    })
  }

  function removeParam(key){
    delete params[key]
    url.delete(key)
    navigate({search: url.toString()})
    setParams({...params})
    setQuery({...params})
  }

  function removeParams(keys = []){
    keys.forEach((key) => {
      delete params[key]
      url.delete(key)
    })
    navigate({search: url.toString()})
    setParams({...params})
    setQuery({...params})    
  }

  function clearParams(){
    setParams({})
    setQuery({})
  }

  function getParams(){
    if (!Object.keys(params)?.length > 0) return []

    const activeParams = Object.entries(params).filter(([key, value]) => (value?.value && !value?.skip) || value?.default || value?.force).map(([key, value], _key) => ({...value, key}))

    return activeParams
  }

  function _setPage(obj){
    url.set("page", obj)
    navigate({search: url.toString()})
    setPage(obj)
  }

  function _setSort(obj){
    url.set("sort", obj)
    navigate({search: url.toString()})
    setSort(obj)
  }

  function validateQuery(){
    if (Object.keys(query).length > 0 && validations){
      return validations.every((obj) => {
        let valid = obj.validation(query[obj.id])
        if (!valid) {
          setQuery({})
          toast.error(obj.error)
        }
        return valid
      })
    } else if (Object.entries(query).length > 0){
      for (const [, obj] of Object.entries(query)) {
        if ((!!obj?.value && !obj?.skip) || obj?.force){
          return true
        }
      }
    }

    return false
  }

  function isQuery(){
    return _isQuery
  }

  function processQuery(_pageInfo){
    setLimit(_pageInfo?.limit)
  }

  function buildQuery(){
    let _query = {}

    if (page>1) _query.offset = (page-1)*limit
    
    _query.sort = sort
    for (const [key, obj] of Object.entries(query)) {
      if ((typeof obj?.value !== 'undefined' && !obj?.skip) || obj?.force){
        const { value } = obj
        _query[key] = value
      } else if (obj?.default){
        _query[key] = obj?.default
      }
    }
    return _query
  }

  function runQuery(props){
    const _params = props?.instantParams || params

    for (const [key, {value}] of Object.entries(_params)) {
      switch (typeof value) {
        case "object":
          url.delete(key)
          value.forEach((_val, _key) => {
            url.append(key, _val)
          })
          break;
        default:
          url.set(key, value)
      }
      navigate({search: url.toString()})
    }
    setQuery({..._params})
    setParams({..._params})
  }

  function updateQuery(obj){
    runQuery({instantParams: {...params, ...obj}})
  }  

  return {
    params,
    getParams,
    addParam,
    removeParam,
    removeParams,
    clearParams,
    isQuery,
    buildQuery,
    runQuery,
    updateQuery,
    processQuery,
    page,
    setPage: _setPage,
    sort,
    setSort: _setSort,
    setQuery,
  }
}

import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const VENDORS_LIST = gql`
  query vendorList{
    vendorList{
      featuredNodes{
        ...VENDOR_FRAGMENT
      }
      promotionNodes{
        ...VENDOR_FRAGMENT
      }
      newNodes{
        ...VENDOR_FRAGMENT
      }
      allOpenNodes{
        ...VENDOR_FRAGMENT
      }
      allClosedNodes{
        ...VENDOR_FRAGMENT
      }
    }
  }
  ${VENDOR_FRAGMENT}
`

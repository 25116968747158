import fetch from 'unfetch'
import { HttpLink, ApolloClient, InMemoryCache, from, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { IS_LOGGED_IN } from './graphql'
import config from './config'

let cache = new InMemoryCache()

const wsLink = new WebSocketLink({
  uri: `${config.subscriptionUrl}/graphql`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = await localStorage.getItem(config.authToken)
      return ({authToken: `Bearer token-${token}`})
    }
  }
})

// Create an http link:
const httpLink = new HttpLink({
  uri: `${config.remoteUrl}/graphql`,
  fetch
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors){
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'You must be authenticated' && path && path[0]) {
        if (path[0] === 'me'){
          localStorage.removeItem(config.userID)
          return localStorage.removeItem(config.authToken)
        }
      }      
    })

  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.authToken)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer token-${token}` : "",
    }
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(link)]),
  cache: cache,
  resolvers: {}
})

cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem(config.authToken),
    email: "",
    name: "",
    facebookID: null    
  }
})

export { wsLink }
export default client

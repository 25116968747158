import { Route, Routes, Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom'

import { PrivateRoute, Reset } from './components'
import { EditAddress, AddressModeSelector, AddressLocationDetect, NewAddress, Search, Referrals, AppDetect, NotFound, Balance, Invoice, Home, Partner, Checkout, Faq, Login, Register, Profile, Addresses, PaymentMethods, Orders, DeliveryService, ForgetPassword, ChangePassword, ResetPassword, AboutUs } from './containers'

export default function RouterRoutes() {
  return(
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/app" element={<AppDetect />}/>
      <Route path="/delivery-service" element={<DeliveryService />}/>
      <Route path="/search" element={<Search />}/>
      <Route path="/:slug" element={<Partner />}/>
      <Route path="/about-us" element={<AboutUs />}/>
      <Route path="/faq" element={<Faq />}/>
      <Route path="/address" element={<AuthenticatedRoute />}>
        <Route path="" element={<AddressModeSelector />} />
        <Route path="detect" element={<AddressLocationDetect />} />
        <Route path="new" element={<NewAddress />} />
        <Route path=":id" element={<EditAddress />} />
      </Route>
      <Route path="/account" element={<AuthenticatedRoute />}>
        <Route path="" element={<Profile />} />
        <Route path="payment-methods" element={<PaymentMethods />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="balance" element={<Balance />} />
        <Route path="addresses" element={<Addresses />} />
        <Route path="orders" element={<Orders />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/recovery">
        <Route path="" element={<ForgetPassword />} />
        <Route path=":method" element={<ForgetPassword />} />
        <Route path="email/reset" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/invoice-request" element={
        <PrivateRoute>
          <Invoice />
        </PrivateRoute>
      } />      
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/reset-password" element={<NavigateWithParams to={(params, searchParams) => `/recovery/email/reset?token=${searchParams.get("token")}`} />} />
      <Route path="/restaurants" element={<Navigate to="/delivery-service" />}/>
      <Route path="/orders" element={<Navigate to="/account/orders" />}/>
      <Route path="/profile" element={<Navigate to="/account" />}/>
      <Route path="/balance" element={<Navigate to="/account/balance" />}/>
      <Route path="/change-password" element={<Navigate to="/account/change-password" />}/>
      <Route path="/forget-password" element={<Navigate to="/recovery" />}/>
      <Route path="/ride-with-us" element={<Navigate to="/se-un-repartidor-con-tomato" />}/>
      <Route path="/restaurant/:id" element={<NavigateWithParams to={params => params?.id ? `/${params.id}` : "/delivery-service"} />} />
      <Route path="/partner/:slug" element={<NavigateWithParams to={params => params?.slug ? `/${params.slug}` : "/delivery-service"} />} />
      <Route element={NotFound}/>
    </Routes>
  )
}

function NavigateWithParams({ to, ...rest }) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  let toValue;
  if (typeof to === 'function') {
    toValue = to(params, searchParams)
  } else {
    toValue = to
  }
  return <Navigate to={toValue} {...rest} />
}


function AuthenticatedRoute(){
  return(
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  )
}
import validator from 'validator'
import { parsePhoneNumber } from 'libphonenumber-js'

export const validations = {
  required: {validation: (value) => !validator.isEmpty(value === undefined ? "" : value), message: "Required field"},
  requiredPhone: {validation: (phone) => phone && phone.phoneNumber && !validator.isEmpty(phone.phoneNumber), message: "Required field"},
  email: {validation: (value) => validator.isEmail(value), message: "Not valid email"},
  equal: {validation: (value, values) => value === values['password'], message: "Not equal to password"},
  phone: {validation: (value) => validatePhonenumber(value), message: "Not valid phone number"}
}

export function validateEmail(str) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase())
}

export function validateRFC(str) {
  const re = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;
  return re.test(String(str).toLowerCase())
}

export function validateName(str) {
  const re = /^((?!\ss(.*)a(.*)\s|\ss(.*)c(.*)\s|\ss(.*)de(.*)\s|\ss(.*)en(.*)\s).)*$/;
  return re.test(String(str).toLowerCase())
}

export function validatePhonenumber({phoneNumber,  countryCode}){
  try {
    const phone = parsePhoneNumber(phoneNumber, countryCode)
    return phone.isValid()
  } catch (error) {
    return false
  }
}

export function validate(validations, data){
  let formErrors = {}

  let formValid = Object.keys(validations).every((key, index) => {
    let validation = validations[key]
    let errors = []

    let validated = validation.every((item) => {
      let valid = item.validation(data[key], data)

      if (!valid)
        errors.push(item.message)

      return valid
    })

    if (errors.length > 0)
      formErrors[key] = errors

    return validated
  })

  return {valid: formValid, errors: formErrors}
}

import { gql } from '@apollo/client'

export const PURCHASE_CUSTOMER_FRAGMENT = gql`
  fragment PURCHASE_CUSTOMER_FRAGMENT on PurchaseCustomer {
    name
    email
    phone{
      phoneNumber
      countryCode
      phoneCode
    }
    contactPreference
    note
    language
  }
`

import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Tooltip, IntlValue } from '../../../components'

import './style.css'

export function ProductTags({right, tags, discount}){

  const _tags = {
    vegetarian: {img: <FontAwesome className="vegetarian" name="leaf" />, tooltip: {en: "vegetarian", es: "vegetarian"}, color: "green"},
    vegan: {img: <span className="vegan">V</span>, tooltip: {en: "vegan", es: "vegan"}, color: "green"},
    glutenFree: {img: <i className="fas fa-wheat glutenFree"></i>, tooltip: {en: "gluten free", es: "sin gluten"}},
    spicy: {img: <i className="fas fa-pepper-hot spicy"></i>, tooltip: {en: "spicy", es: "picante"}},
    houseSpeciality: {img: <i className="fas fa-hat-chef houseSpeciality"></i>, tooltip: {en: "house speciality", es: "especialidad de la casa"}},
    onsale: {img: <i className="fas fa-badge-percent" />, tooltip: {en: "promotion", es: "promoción"}, color: "main"},
    new: {img: <FontAwesome name="star" />, tooltip: {en: "new", es: "nuevo"}, color: "main"},
    longPreparation: {img: <FontAwesome name="clock" />, tooltip: {en: "long preparation", es: "larga preparación"}, color: "main"},
  }

  if (discount){

  }

  return(
    <div className="product-tags">
      {tags.map((tag, key) =>
        <div key={key} className="product-tag">
          {_tags[tag] ? <Tooltip right={right} className={_tags[tag].color} text={<IntlValue value={_tags[tag].tooltip} />}>{_tags[tag].img}</Tooltip> : tag}
        </div>
      )}
      {discount && 
        <div className="product-tag">
          {_tags.onsale && <Tooltip right={right} className={_tags.onsale.color} text={<IntlValue value={_tags.onsale.tooltip} />}>{_tags.onsale.img}</Tooltip>}
        </div>      
      }
    </div>
  )

}
